import { ChangeEvent, useMemo } from 'react'
import { dsv } from '../../../styles/defaults'

import { InputProps } from '.'

export interface InputTextProps extends InputProps {
  type?: 'password' | 'text' | 'email'
}

export function InputText({ fieldId, strValue, setStrValue, saveStrValue, type, style }: InputTextProps) {
  const { onChange, onBlur } = useMemo(
    () => ({
      onChange:
        setStrValue == undefined
          ? undefined
          : (event: ChangeEvent<HTMLInputElement>) => setStrValue(event.target.value),
      onBlur: saveStrValue == undefined ? undefined : () => saveStrValue(),
    }),
    [setStrValue, saveStrValue]
  )
  return (
    <input
      type={type != null ? type : 'text'}
      id={fieldId}
      value={strValue}
      disabled={onChange == undefined}
      onChange={onChange}
      onBlur={onBlur}
      // TODO: Fix styling for App Version
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style={{ height: 24, ...dsv.text, ...style }}
    />
  )
}
