import { CalcAkte } from '@dpa/common/dist'
import { useSyncAkteWithBCMutation } from './mutations/useSyncAkteWithBCMutation'
import { useCallback } from 'react'

export function useSyncAkteWithBC(akte: Partial<CalcAkte> | undefined) {
  const syncBCMutation = useSyncAkteWithBCMutation()
  const canSyncBC = akte?.id != undefined && akte?.PDB_Projektnummer != undefined
  console.log('akte', akte)
  const syncBCFunction = useCallback(() => {
    if (akte != undefined && canSyncBC) {
      syncBCMutation.mutate({
        id: akte.id!,
      })
      return true
    }
    return undefined
  }, [akte, canSyncBC, syncBCMutation])

  return { canSyncBC, syncBCFunction, syncBCMutation }
}
