import { ActivityIndicator, View } from 'react-native'
import { dsv } from '../styles/defaults'

interface LoadingScreenProps {
  active: boolean
}

export function LoadingScreen({ active = false }: LoadingScreenProps) {
  return (
    <View
      style={{
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      {active && <ActivityIndicator color={dsv.colors.GpJouleGelb} size="large" />}
    </View>
  )
}
