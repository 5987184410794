import { Akte } from '@dpa/common/dist'
import felder_BackendAuftrag from './felder_BackendAuftrag.json'
import { GenericOrder } from './GenericOrder'

export function ProjectsProjectBackendOrder(){
  return <GenericOrder
    orderName='Backend Auftrag'
    showFields={felder_BackendAuftrag as Array<keyof Akte>}
  />
}
