import { Akte, applyGateConditions } from '@dpa/common/dist'

import { Section } from '@expo/html-elements'

import { Card, CardBody, CardHeader } from '../../../../components/Card'

import { useAkteContext } from '../../../../hooks/context/useAkteContext'
import { useDatenblattForms } from '../../../../hooks/form/useDatenblattForms'

import { useUpdateAkteMutation } from '../../../../hooks/update/mutations/useUpdateAkteMutation'
import { LoadingScreen } from '../../../../components/LoadingScreen'
import { useUebergabeForms } from '../utils'


import { HistoryCollapsible } from '../components/HistoryCollapsible'

import { useMemo } from 'react'
import { OrderHeader } from './OrderHeader'
import { akteDisplayConditions } from '../akteDisplayConditions'

interface GenericOrderProps{
  orderName: string
  showFields: Array<keyof Akte>
}

export function GenericOrder({orderName, showFields}: GenericOrderProps) {

  const akte = useAkteContext()
  const isHidden = useMemo(
    ()=>{
      if (akte?.localState == undefined) {
        return () => true
      }

      const fieldsToHide = applyGateConditions({}, akte.localState, akteDisplayConditions)     
      const modifiedShowFields = showFields.filter(fieldName => fieldsToHide[fieldName] != 'hidden')

      return (_data: Partial<Akte>, key: keyof Akte): boolean => {
        return !modifiedShowFields.includes(key)
      }
    },
    [akte.localState, showFields]
  )

  const updateAkteMutation = useUpdateAkteMutation()

  const { forms: datenblattForms, identTableJsx } = useDatenblattForms(
    () => true, // isOK
    isHidden,
    true, // isFrozen
    () => false, // isHidden
  )

  const forms = useUebergabeForms(datenblattForms, identTableJsx)

  return (
    <Section style={{ flex: 1 }}>
      <Card customStyle={{ flex: 1, minHeight: 200 }}>
        {(akte.isLoading || updateAkteMutation.isLoading) && (
          <CardBody customStyle={{ minHeight: 300 }}>
            <LoadingScreen active />
          </CardBody>
        )}
        {!(akte.isLoading || updateAkteMutation.isLoading) && (
          <>
            <CardHeader>
              <OrderHeader orderName={orderName} />
            </CardHeader>
            <CardBody customStyle={{ flex: 1, overflow: 'scroll' }}>
              {forms}
              <HistoryCollapsible identifier={akte.identifier} />
            </CardBody>
          </>
        )}
      </Card>
    </Section>
  )
}
