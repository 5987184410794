import { ChangeEvent, useMemo } from 'react'
import { InputProps } from '.'
import { dsv } from '../../../styles/defaults'

export function InputDate({ fieldId, strValue, setStrValue, onBlur, style }: InputProps) {
  const onChange = useMemo(() => {
    if (setStrValue) {
      return (event: ChangeEvent<HTMLInputElement>) => setStrValue(event.target.value)
    } else {
      return undefined
    }
  }, [setStrValue])

  return (
    <input
      type="date"
      id={fieldId}
      value={strValue}
      disabled={onChange == undefined}
      onChange={onChange}
      style={{ height: 24, ...dsv.text, ...style }}
      onBlur={onBlur}
    />
  )
}
