import { SelectProps } from '.'
import { InputRadioList } from './InputRadioList'
import { InputSelect } from './InputSelect'

export function InputEnum(props: SelectProps) {
  if (
    props.options?.length == 3 &&
    props.options.includes('') &&
    props.options.includes('Ja') &&
    props.options.includes('Nein')
  ) {
    return InputRadioList({ ...props, options: ['Ja', 'Nein'] }) // JaNeinEnum should be rendered as two radioButtons
  }

  return InputSelect(props)
}
