import { useMemo } from 'react'
export type OkFields = { [key: string]: { ok: boolean; mandatory?: boolean; notNullish: boolean } }

export function okStats(okFields: OkFields) {
  const okValues = Object.values(okFields)
  const okCount = okValues.filter((v) => v.ok === true).length
  const nokCount = okValues.filter((v) => v.ok === false).length
  const okPercentage = nokCount + okCount == 0 ? 100 : (100 / (nokCount + okCount)) * okCount

  return { okCount, nokCount, okPercentage }
}

export function useOkStats(okFields: OkFields) {
  return useMemo(() => okStats(okFields), [okFields])
}
