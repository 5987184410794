import { createContext, PropsWithChildren, useMemo, useContext } from 'react'

import { UserWithoutPassWithRoles, RoleEnumSchema, mapObj } from '@dpa/common/dist'

import { trpc } from '../../../App'
import { RoleEnumType } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/RoleEnumSchema'

export type UserListCtx =
  | {
      isLoading: true
      userList?: Array<UserWithoutPassWithRoles>
      userOptions?: { [rolle in RoleEnumType]: Array<{ key: string; value: string }> }
      allUserOptions?: Array<{
        key: string
        value: string
        roles?: Array<RoleEnumType>
      }>
      userById?: { [id in number]: UserWithoutPassWithRoles }
    }
  | {
      isLoading: false
      userList: Array<UserWithoutPassWithRoles>
      userOptions: { [rolle in RoleEnumType]: Array<{ key: string; value: string }> }
      allUserOptions: Array<{
        key: string
        value: string
        roles?: Array<RoleEnumType>
      }>
      userById: { [id in number]: UserWithoutPassWithRoles }
    }

const UserListContext = createContext<UserListCtx | null>(null)

export interface UserListContextProviderProps {}

export function UserListContextProvider({ children }: PropsWithChildren<UserListContextProviderProps>) {
  const users = trpc.userList.useQuery()

  const calcUserData = useMemo(() => {
    return users.data?.reduce(
      (carry, user) => {
        carry.userById[user.id] = user

        const userOption = { key: `${user.id}`, value: user.name, roles: user.UserRoles.map(({ role }) => role) }
        carry.allUserOptions.push(userOption)

        user.UserRoles.forEach(({ role }) => {
          carry.userOptions[role].push(userOption)
        })
        return carry
      },
      {
        userOptions: mapObj(RoleEnumSchema.enum, () => [{ key: '', value: '' }]),
        allUserOptions: [{ key: '', value: '' }],
        userById: {} as { [id: number]: UserWithoutPassWithRoles },
      }
    )
  }, [users])

  const ctxValue: UserListCtx = useMemo(() => {
    if (users.isLoading || users.data === undefined || calcUserData === undefined) {
      return {
        isLoading: true,
        userList: undefined,
        userOptions: undefined,
        userById: undefined,
        allUserOptions: undefined,
      }
    } else {
      return {
        isLoading: false,
        userList: users.data,
        userOptions: calcUserData.userOptions,
        userById: calcUserData.userById,
        allUserOptions: calcUserData.allUserOptions,
      }
    }
  }, [calcUserData, users.data, users.isLoading])

  return <UserListContext.Provider value={ctxValue}> {children} </UserListContext.Provider>
}

export function useMaybeUserListContext() {
  return useContext(UserListContext)
}

export function useUserListContext() {
  const ctx = useContext(UserListContext)
  if (ctx == null) {
    throw new Error('usage of useUserListContext outside of a UserListContextProvider')
  }
  return ctx
}
