import { trpc } from '../../App'

export function useAkteSalesLines({ id }: { id: number }) {
  const akteSalesLines = trpc.akte.salesLines.useQuery({ id })
  return akteSalesLines
  //  return isLoading
  //    ? {
  //      isLoading: true,
  //      permissions: undefined,
  //    }
  //    : {
  //      isLoading: false,
  //      permissions: permissions!,
  //    }
}
