import { View } from 'react-native'
import { Collapsible } from '../../../../components/Collapsible'
import { H4 } from '../../../../components/TextComponents'
import { ChangeLog } from '../../../../components/ChangeLog'
import { AktenIdentifier } from '@dpa/common/dist'

export interface HistoryCollapsableProps {
  identifier: AktenIdentifier
}

export function HistoryCollapsible({ identifier }: HistoryCollapsableProps) {
  return (
    <View style={{ paddingTop: 10 }}>
      <Collapsible
        summary={
          <H4
            style={{
              fontSize: 20,
              marginLeft: 8,
              marginHorizontal: 10,
              marginVertical: 10,
            }}
          >
            Änderungshistorie{/* und Kommentare */}
          </H4>
        }
      >
        {/*
        <View>
          <H4>Komentieren</H4>
          <textarea name="Comment" cols="40" rows="5"></textarea>
          <Button title="Kommentar Hinzufügen"/>
        </View>
      */}
        <ChangeLog identifier={identifier} />
      </Collapsible>
    </View>
  )
}
