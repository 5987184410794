import { useMemo } from 'react'

export function useMapObj<TObj extends object, TRes>(obj: TObj | undefined, fnc: (data: TObj[keyof TObj]) => TRes) {
  return useMemo(() => {
    if (obj === undefined) {
      return undefined
    }
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, fnc(value)])) as {
      [key in keyof TObj]: TRes
    }
  }, [fnc, obj])
}
