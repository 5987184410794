import { z } from 'zod'

import { Akte, AkteSchema } from '@dpa/common/dist'
import { SchemaToFormSchema, ZodFormFields } from '../../components/form/Autoform'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { usePermissionForm } from './generic/usePermissionForm'
import { useAkteContext } from '../context/useAkteContext'
import { useObjDelta } from '../useObjDelta'
import { FieldDivergence } from '../../components/form/FieldDivergence'
import { okStats } from '../useOkStats'
import { usePermissions } from '../usePermissions'
import { NoExtraProperties, NonPartial } from '../../utils/typing'
import { OnlyValideLabels } from '../../locales/translationKeys'
import { useMapObj } from '../useMapObj'

export const Datenblatt_OmittedFields = {
  id: true,
  creationData: true,
} satisfies { [key in keyof Akte]?: true }

const Datenblatt_Schema = AkteSchema.omit(Datenblatt_OmittedFields)

export const DatenblattFields = SchemaToFormSchema(Datenblatt_Schema, {})

type _DatenblattFieldsType = NonPartial<z.infer<typeof DatenblattFields>>

type _HasLabels_DatenblattFields = NoExtraProperties<
  _DatenblattFieldsType,
  Pick<_DatenblattFieldsType, OnlyValideLabels<keyof _DatenblattFieldsType>>
>

export const datenblattFormGroupDefinitions = [
  // { key: "Status", title: "Status", form: "Status", forms: undefined },
  { key: 'Projektdaten', title: 'Projektdaten', form: 'Projektdaten', forms: undefined },
  { key: 'Kundendaten', title: 'Kundendaten', form: 'Kundendaten', forms: undefined },
  { key: 'Engineering Dokumente', title: 'Engineering Dokumente', form: 'Engineering Dokumente', forms: undefined },
  {
    key: 'Umfang der Angebotsstellung',
    title: 'Umfang der Angebotsstellung',
    form: 'Umfang der Angebotsstellung',
    forms: undefined,
  },
  {
    key: 'Anschlussinformationen',
    title: 'Anschlussinformationen',
    form: undefined,
    forms: [
      { key: 'Anschlussinformationen_a', title: undefined, form: 'Anschlussinformationen_a', forms: undefined },
      {
        key: 'Anschlussinformationen_AC-Ladestationen',
        title: 'Anschlussinformationen_AC-Ladestationen',
        form: 'Anschlussinformationen_AC-Ladestationen',
        forms: undefined,
      },
      {
        key: 'Anschlussinformationen_DC-Ladestationen',
        title: 'Anschlussinformationen_DC-Ladestationen',
        form: 'Anschlussinformationen_DC-Ladestationen',
        forms: undefined,
      },
      { key: 'Anschlussinformationen_b', title: undefined, form: 'Anschlussinformationen_b', forms: undefined },
    ],
  },
  { key: 'Dienstleistungen', title: 'Dienstleistungen', form: 'Dienstleistungen', forms: undefined },
  {
    key: 'Backend',
    title: 'Backend',
    form: undefined,
    forms: [
      { key: 'Backend', title: undefined, form: 'Backend', forms: undefined },

      {
        key: 'Backend_Sonstige',
        title: 'Sonstige Informationen',
        form: 'Backend_Sonstige',
        forms: undefined,
      },

      {
        key: 'Backend_Marketinginformationen',
        title: 'Marketinginformationen',
        form: 'Backend_Marketinginformationen',
        forms: undefined,
      },
      {
        key: 'Backend_Identifikationsmedien',
        title: 'Identifikationsmedien',
        form: 'Backend_Identifikationsmedien',
        forms: undefined,
      },
      {
        key: 'Backend_THGQuote',
        title: 'THG Quote',
        form: 'Backend_THGQuote',
        forms: undefined,
      },
    ],
  },
  { key: 'Netzwerkinformationen', title: 'Netzwerkinformationen', form: 'Netzwerkinformationen', forms: undefined },
  { key: 'Wartung', title: 'Wartung', form: 'Wartung', forms: undefined },
  {
    key: 'EnergieUndLademanagementkonzept',
    title: 'Energie und Lademanagementkonzept',
    form: undefined,
    forms: [
      {
        key: 'EnergieUndLademanagementkonzept_Lademanagement',
        title: 'Lademanagement',
        form: 'EnergieUndLademanagementkonzept_Lademanagement',
        forms: undefined,
      },
      {
        key: 'EnergieUndLademanagementkonzept_Anforderung',
        title: 'Anforderung',
        form: 'EnergieUndLademanagementkonzept_Anforderung',
        forms: undefined,
      },
      {
        key: 'EnergieUndLademanagementkonzept_BeschreibungLademanagementkonzept',
        title: 'Beschreibung Lademanagementkonzept',
        form: 'EnergieUndLademanagementkonzept_BeschreibungLademanagementkonzept',
        forms: undefined,
      },
    ],
  },
] as const

export function useDatenblattFormGroup(
  isOk?: (data: Partial<z.infer<typeof Datenblatt_Schema>>, key: keyof z.infer<typeof AkteSchema>) => boolean,
  onBlur?: () => void,
  isHidden?: (data: Partial<z.infer<typeof Datenblatt_Schema>>, key: keyof z.infer<typeof AkteSchema>) => boolean,
  isFrozen: boolean = false,
  isMandatory?: (data: Partial<z.infer<typeof AkteSchema>>, key: keyof z.infer<typeof AkteSchema>) => boolean
) {
  const akte = useAkteContext()
  const [firstServerState, setFirstServerState] = useState<Akte | undefined>(undefined)

  useEffect(() => {
    if (firstServerState === undefined && !akte.isLoading) {
      setFirstServerState(akte.serverState)
    }
  }, [akte.isLoading, akte.serverState, firstServerState])

  const divergenceFromFirstServerState = useObjDelta(akte.localState, firstServerState)

  const rights = usePermissions()
  const permissions = rights.data?.permissions || {}

  const { formFieldEntries, formState, okFields, setFormState, validation } = usePermissionForm(
    DatenblattFields,
    rights.data?.rights,
    isOk,
    onBlur,
    isHidden,
    isFrozen,
    isMandatory
  )

  useEffect(() => {
    if (!akte.isLoading) {
      setFormState(Datenblatt_Schema.parse(akte.serverState))
    }
  }, [akte.isLoading, akte.serverState, setFormState])

  const formFieldObj = useMemo(() => Object.fromEntries(formFieldEntries), [formFieldEntries])

  const editableFields = useMapObj(rights.data?.rights ?? {}, ({ write }) => write)
  const pickGroup = useCallback(
    (fields: (keyof z.infer<typeof AkteSchema>)[]) => {
      const pickedOkFields = Object.fromEntries(Object.entries(okFields).filter(([name, _]) => fields.includes(name)))
      const pickedOkStats = okStats(pickedOkFields)
      return {
        fields,
        okFields: pickedOkFields,
        okStats: pickedOkStats,
        entries: fields.reduce(
          (carry, fieldName) => {
            const field = formFieldObj[fieldName]
            if (field != undefined) {
              carry.push([
                fieldName,
                <FieldDivergence
                  key={fieldName}
                  field={field}
                  divergenceHidden={permissions[fieldName]?.write != true}
                  divergesFromInitServerState={
                    divergenceFromFirstServerState !== undefined && divergenceFromFirstServerState[fieldName]
                  }
                  divergesFromServerState={akte.localDivergences !== undefined && akte.localDivergences[fieldName]}
                />,
              ])
            }
            return carry
          },
          [] as Array<[string, JSX.Element]>
        ),
      }
    },
    [akte.localDivergences, divergenceFromFirstServerState, formFieldObj, okFields, permissions]
  )

  const InputFieldOnlyEntries = ZodFormFields(
    DatenblattFields,
    formState,
    setFormState,
    editableFields,
    undefined,
    onBlur,
    true
  )

  const pickInputFieldEntriesOnly = useCallback(
    (fields: (keyof z.infer<typeof AkteSchema>)[]) => {
      return InputFieldOnlyEntries.filter(([name, _]) => fields.includes(name))
    },
    [InputFieldOnlyEntries]
  )

  return {
    form: {
      formFieldEntries,
      formState,
      okFields,
      setFormState,
      validation,
    },
    pickGroup,
    pickInputFieldEntriesOnly,
    isLoading: rights.isLoading,
  }
}
