import { View } from 'react-native'
import { Link } from '../../utils/routing'
import { Text } from '../../components/TextComponents'

export function GeneralError() {
  return (
    <View>
      <Text>ERROR PAGE</Text>

      <Link to="/">
        <Text>Go to the home page</Text>
      </Link>
    </View>
  )
}
