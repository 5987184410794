const defaultStyleValues = {
  colors: {
    accent: '#1dcdbc',
    neutral: '#282828',
    black: '#000',
    neutral2: '#adadad',
    base: '#ffffff',
    base2: '#f0f3f6',
    base3: '#eaedf1',
    info: '#3abff8',
    success: '#36d399',
    warning: '#fbbd23',
    error: '#f87272',
    link: '#588df8',
    white: '#ffffff',
    // ^ NON GPJOULE COLORS
    connectGrau: '#333434',
    connectGrau70: '#707171',
    connectGrau50: '#9B9999',
    connectGrau30: '#C1C2C2',
    connectGrau7: '#F1F1F1',
    GpJouleGelb: '#FFE61E',
    GpJouleGelb70: '#FFED63',
    GpJouleGelb50: '#FFF28F',
    GpJouleGelb30: '#FFF7BC',
    Kontra: '#04BBEE',
    BegleitfarbeGruen: '#B7CD00',
    BegleitfarbeGelb: '#E8E100',
    BegleitfarbeOrange: '#F7A600',
    BegleitfarbeOrangeDunkel: '#F08300',
  },
  //   breakpoints: {
  //     base: 0,
  //     sm: 480,
  //     md: 768,
  //     lg: 992,
  //     xl: 1280,
  //   },
  text: {
    fontFamily: 'AvenirNext LT Pro Regular',
  },
  headingFont: {
    fontFamily: 'AvenirNext LT Pro Heavy',
  },
  heading: {
    fontFamily: 'AvenirNext LT Pro Heavy',
    color: '#333434',
  },
}

export const dsv = defaultStyleValues

/*
import { StyleSheet } from "react-native"

const increments = 10
const incrementSize = 4
let autogeneratedStyles = {}
const styleAttributes = {
  m: "margin",
  mt: "marginTop",
  mr: "marginRight",
  mb: "marginBottom",
  ml: "marginLeft",
  mx: "marginHorizontal",
  my: "marginVertical",
  p: "padding",
  pt: "paddingTop",
  pr: "paddingRight",
  pb: "paddingBottom",
  pl: "paddingLeft",
  px: "paddingHorizontal",
  py: "paddingVertical",
  h: "height",
  w: "width",
}
for (let i = 0; i <= increments; i++) {
  Object.entries(styleAttributes).forEach(([key, value]) => {
    autogeneratedStyles = { ...autogeneratedStyles, ...{ [`${key}${i}`]: { [value]: i * incrementSize } } }
  })
}



export const shadowStyles = {
  shadowMd: {
    shadowRadius: 5,
    shadowOpacity: 0.1,
  },
  shadowBase: {
    shadowRadius: 10,
    shadowOpacity: 0.1,
  },
  shadowXl: {
    shadowRadius: 20,
    shadowOpacity: 0.2,
  },
}

const spacingsStyleSheet = StyleSheet.create({ ...autogeneratedStyles, ...shadowStyles })
*/
//export const s = spacingsStyleSheet
