import { useMemo, useState } from 'react'
import { ZodRawShape, z } from 'zod'
import { Partial } from '../../../utils/typing'

import { ZodFormFields, zodValidate } from '../../../components/form/Autoform'

export function useAutoFormFields<T extends ZodRawShape>(
  schema: ReturnType<typeof z.object<T>>,
  editableFields: { [key in keyof T]?: boolean } = {},
  showValidationErrors?: true,
  onBlur?: () => void,
  inputFieldsOnlyNoLable = false,
  isMandatory?: (data: Partial<z.infer<typeof schema>>, key: keyof z.infer<typeof schema>) => boolean
) {
  const [formState, setFormState] = useState<Partial<z.infer<typeof schema>>>({})
  const validation = useMemo(
    () => zodValidate(schema, formState), // TODO: schema fields should not be optional
    [schema, formState]
  )

  const formFieldEntries = ZodFormFields(
    schema,
    formState,
    setFormState,
    editableFields,
    showValidationErrors,
    onBlur,
    inputFieldsOnlyNoLable,
    isMandatory
  )

  return { formState, setFormState, validation, formFieldEntries }
}
