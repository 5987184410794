import { useCallback, useState } from 'react'
import { Role, useAuthContext } from '../context/useAuthContext'
import { useNavigate } from 'react-router-dom'
import { trpc } from '../../../App'

interface UseLoginReturnValue {
  login: (email: string, password: string) => void
  loading: boolean
  unauthorized: boolean
}

export default function useLogin(): UseLoginReturnValue {
  const [loading, setLoading] = useState(false)
  const [unauthorized, setUnauthorized] = useState(false)
  const { setAuthCtx } = useAuthContext()
  const navigate = useNavigate()

  const trpcLogin = trpc.auth.login.useMutation({
    onSuccess: ({ id, email, roles, name, refreshToken, accessToken }) => {
      setAuthCtx({
        accessToken,
        email,
        id,
        name,
        refreshToken,
        roles: roles as unknown as Role[],
      })
      setLoading(false)
      navigate('/')
    },
    onError: ({ message, data }) => {
      setLoading(false)
      setUnauthorized(true)
    },
  })

  const login = useCallback(
    (email: string, password: string) => {
      setLoading(true)
      setUnauthorized(false)
      trpcLogin.mutate({ email, password })
    },
    [trpcLogin]
  )

  return {
    login,
    loading,
    unauthorized,
  }
}
