import { View } from 'react-native'
import { Link, Outlet } from '../../utils/routing'
import { Text } from '../../components/TextComponents'

export function ErrorLayout() {
  return (
    <View>
      <Text>ERROR LAYOUT</Text>
      <Text>Nothing to see here!</Text>

      <Link to="/">
        <Text>Go to the home page</Text>
      </Link>
      <Outlet />
    </View>
  )
}
