import type { CalcAkte } from '@dpa/common/dist/calculate/akte'
import type { DisplayConditions } from '@dpa/common/dist/process/process'

export const akteDisplayConditions: DisplayConditions<CalcAkte> = {
  LisInhaber: {
    description: "Lis inhaber nur anzeigen wenn abweichend rechnungsanschrift",
    condition: (akte) =>
      akte.PDB_LIS_Inhaber_abweichend_rechnungsanschrift != 'Ja',
    patch: { PDB_LIS_Inhaber: 'hidden' },
  },
  LisBetreiber: {
    description: "Lis beteriber nur anzeigen wenn abweichend Connect",
    condition: (akte) =>
      akte.PDB_LIS_Betreiber_abweichend_connect != 'Ja',
    patch: { PDB_LIS_Betreiber: 'hidden' },

  },
  pdbGrundstueckseigentuemer: {
    description: "Grundstückseigentümer nur anzeigen wenn abweichend LIS",
    condition: (akte) =>
      akte.PDB_Grundstueckseigentuemer_abweichend_LIS != 'Ja',
    patch: {
      PDB_Grundstueckseigentuemer_Name: 'hidden',
      PDB_Grundstueckseigentuemer_EMail: 'hidden',
      PDB_Grundstueckseigentuemer_Telefon: 'hidden',
      PDB_Grundstueckseigentuemer_Adresse: 'hidden',
    },
  },
  pdbAnschlussnutzer: {
    description: "Anschlussnutzer nur anzeigen wenn abweichend LIS",
    condition: (akte) =>
      akte.PDB_Anschlussnutzer_abweichend_LIS != 'Ja',
    patch: {
      PDB_Anschlussnutzer_Name: 'hidden',
      PDB_Anschlussnutzer_EMail: 'hidden',
      PDB_Anschlussnutzer_Telefon: 'hidden',
      PDB_Anschlussnutzer_Adresse: 'hidden',
    },
  },
  lisStandort: {
    description: "Standort nur anzeigen wenn abweichend rechnung",
    condition: (akte) =>
      akte.UE_Kundendaten_LIS_Standort_abw_rechnung != 'Ja',
    patch: {
      UE_Kundendaten_LIS_Standort_Strasse: 'hidden',
      UE_Kundendaten_LIS_Standort_Hausnummer: 'hidden',
      UE_Kundendaten_LIS_Standort_PLZ: 'hidden',
      UE_Kundendaten_LIS_Standort_Stadt: 'hidden',
    },
  },
  firmenlogo: {
    description: "Wenn Firmenlogo auf display? = nein",
    condition: (akte) =>
      akte.PDB_FirmenlogoAufDisplay != 'Ja',
    patch: {
      PDB_FirmenlogoLink: 'hidden',
    },
  },
  foerderung: {
    description: "Wenn Förderung vorhanden? = nein",
    condition: (akte) =>
      akte.Foerderung_vorhanden != 'Ja',
    patch: {
      Foerderung: 'hidden',
      FoerderungEnde: 'hidden',
      FoerderungClearing: 'hidden',
    },
  },
  wartungsvertrag: {
    description: "Wenn Service-/Wartungsvertrag benötigt? = nein",
    condition: (akte) =>
      akte.PDB_Backend_Servicevertrag != 'Ja',
    patch: {
      PDB_Backend_ServicevertragVersandt: 'hidden',
      PDB_Backend_OtrisLink: 'hidden',
    },
  },
  kartenterminal: {
    description: "Wenn Kartenterminal? = nein",
    condition: (akte) =>
      akte.PDB_Backend_Kartenterminal != 'Ja',
    patch: {
      PDB_Backend_Vorautorisierungsbetrag: 'hidden',
    },
  },
  akrtenterminal: {
    description: "Wenn Land Deutschland",
    condition: (akte) =>
      akte.BC_Rechnungsanschrift_Land == 'DE',
    patch: {
      PDB_Backend_Waehrung: 'hidden',
      PDB_Backend_Steuersatz: 'hidden',
      PDB_Backend_SteuernummerDesKunden: 'hidden',
    },
  },
  Smart1Controller: {
    description: "Wenn Smart1-Controller != Ja",
    condition: (akte) =>
      akte.PDB_EundL_Lademanagement_Smart1Controller == null ||
      akte.PDB_EundL_Lademanagement_Smart1Controller == undefined ||
      akte.PDB_EundL_Lademanagement_Smart1Controller.trim().toLowerCase() == 'nein',
    patch: {
      PDB_EundL_Lademanagement_DokumentationSmart1Vorhanden: 'hidden',
    },
  },
  TMHChargepilot: {
    description: "Wenn TMH Chargepilot != Ja",
    condition: (akte) =>
      akte.PDB_EundL_Lademanagement_TMHChargepilot != 'Ja',
    patch: {
      PDB_EundL_Lademanagement_DokumentationTMHVorhanden: 'hidden',
    },
  },
  StatusLademangement: {
    description: "Wenn Status Lademangement != nicht gewünscht",
    condition: (akte) =>
      akte.PDB_EundL_Lademanagement_Status != 'ErstellungDurchConnectGewuenscht' &&
      akte.PDB_EundL_Lademanagement_Status != 'bereitsVorhanden',
    patch: {
      PDB_EundL_Lademanagement_SeriennummerLLMController: 'hidden',
      PDB_EundL_Lademanagement_ZugangZumLastmanagement: 'hidden',
      PDB_EundL_Lademanagement_IBNProtokollLLEMSvorhanden: 'hidden',
    },
  },
  StatischesLademanagement: {
    description: "Wenn Statisches Lademanagement != Ja",
    condition: (akte) =>
      akte.PDB_EundL_Anforderung_StatischesLademanagement != 'Ja',
    patch: {
      PDB_EundL_Anforderung_DocuStatischesMgmntVorhanden: 'hidden',
    },
  },
  DynamischesLademanagement: {
    description: "Wenn Dynamisches Lademanagement != Ja",
    condition: (akte) =>
      akte.PDB_EundL_Anforderung_DynamischesLademanagement != 'Ja',
    patch: {
      PDB_EundL_Anforderung_DocuDynamischesMgmntVorhanden: 'hidden',
    },
  },
  Schieflastbegrenzung: {
    description: "Wenn Schieflastbegrenzung != Ja",
    condition: (akte) =>
      akte.PDB_EundL_Anforderung_Schieflastbegrenzung != 'Ja',
    patch: {
      PDB_EundL_Anforderung_DocuSchieflastbegrenzungVorhanden: 'hidden',
    },
  },
  PVIntegration: {
    description: "Wenn PV-Integration != Ja",
    condition: (akte) =>
      akte.PDB_EundL_Anforderung_PVIntegration != 'Ja',
    patch: {
      PDB_EundL_Anforderung_DocuPVIntegrationVorhanden: 'hidden',
    },
  },
  BatterieIntegration: {
    description: "Wenn Batteriespeicher-Integration != Ja",
    condition: (akte) =>
      akte.PDB_EundL_Anforderung_BatterieIntegration != 'Ja',
    patch: {
      PDB_EundL_Anforderung_DocuBatterieIntegrationVorhanden: 'hidden',
    },
  },
  Nutzerpriorisierung: {
    description: "Wenn Nutzerpriorisierung != Ja",
    condition: (akte) =>
      akte.PDB_EundL_Anforderung_Nutzerpriorisierung != 'Ja',
    patch: {
      PDB_EundL_Anforderung_DocuNutzerpriorisierungVorhanden: 'hidden',
    },
  },
  Ladepunktpriorisierung: {
    description: "Wenn Ladepunktpriorisierung != Ja",
    condition: (akte) =>
      akte.PDB_EundL_Anforderung_Ladepunktpriorisierung != 'Ja',
    patch: {
      PDB_EundL_Anforderung_DocuLadepunktpriorisierungVorhanden: 'hidden',
    },
  },
  ZugangZumLPMSErwuenscht: {
    description: "Wenn Zugang zum LPMS erwünscht? != Ja",
    condition: (akte) =>
      akte.PDB_Backend_ZugangZumLPMSErwuenscht != 'Ja',
    patch: {
      PDB_Backend_KundenBackend: 'hidden',
    },
  },
  SchliesszylinderUVausgetauscht: {
    description: "Wenn Wenn \"GP Joule Connect Schließsystem verbaut\" != Ja",
    condition: (akte) =>
      akte.PDB_Wartung_SchliesszylinderUVausgetauscht != 'Ja',
    patch: {
      PDB_Wartung_AnsprechpersonSchluessel: 'hidden',
    },
  },

  Kundenbackend: {
    description: "Wenn Wenn \"Kundenbackend\" != Ja",
    condition: (akte) =>
      akte.PDB_Backend_KundenBackend?.toLowerCase() != 'ja',
    patch: {
      PDB_Backend_ZugangsdatenKundenBackend: 'hidden',
    },
  },
  LadepunkteOeffentlich: {
    description: "Wenn Wenn \"Kundenbackend\" != Ja",
    condition: (akte) =>
      akte.PDB_Backend_LadepunkteOeffentlich != 'ja',
    patch: {
      PDB_Backend_EVSEID_QRCode: 'hidden',
    },
  },
  Zusatzgarantie: {
    description: "Wenn Wenn \"Wenn zusätzliche Garantie beim Hersteller\" != Ja",
    condition: (akte) =>
      akte.PDB_Wartung_Herstellergarantie != 'Ja',
    patch: {
      PDB_Wartung_Herstellergarantie_Laufzeit: 'hidden',
    },
  },
  // TODO: only display nachtrags fields if the prev on is not empty
}

