import { Div } from '@expo/html-elements'

import superSign from '../../assets/styleElements/Supersign_gelb_fadeout.png'

export function SuperSign() {
  return (
    <Div
      style={{
        zIndex: 1,
        position: 'absolute',
        right: 0,
        bottom: 0,
      }}
    >
      <img
        alt="Logo"
        src={superSign}
        style={{
          maxWidth: '50vw',
          maxHeight: '40vh',
        }}
      />
    </Div>
  )
}
