import { useMemo } from 'react'

import { InputSelect, SelectProps } from './index'
import { useUserListContext } from '../../../hooks/context/useUserListContext'
import { RoleEnumType } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/RoleEnumSchema'

export function UserDropdownSelection({
  roles,
  fieldId,
  strValue,
  setStrValue,
  onBlur,
}: { roles?: Array<RoleEnumType> } & Omit<SelectProps, 'options'>) {
  const { allUserOptions } = useUserListContext()
  const relevantUserOptions = useMemo(() => {
    if (roles === undefined || allUserOptions === undefined) {
      return allUserOptions
    }
    return allUserOptions.filter(
      (user) => user.roles === undefined || roles.every(role => user.roles?.includes(role))
    )
  }, [allUserOptions, roles])

  return (
    <InputSelect
      fieldId={fieldId}
      strValue={strValue}
      optionsWithKey={relevantUserOptions}
      setStrValue={setStrValue}
      onBlur={onBlur}
    />
  )
}
