import { createContext, PropsWithChildren, useMemo, useContext } from 'react'

import { trpc } from '../../../App'
import type { AllProcessGates } from '@dpa/server/trpc/routers/processGate'

export type ProcessGateCtx =
  | {
      isLoading: true
      processGates: undefined
    }
  | {
      isLoading: false
      processGates: AllProcessGates
    }

const ProcessGateContext = createContext<ProcessGateCtx | null>(null)

export interface ProcessGateContextProviderProps {}

export function ProcessGateContextProvider({ children }: PropsWithChildren<ProcessGateContextProviderProps>) {
  const processGates = trpc.processGate.all.useQuery()

  const ctxValue: ProcessGateCtx = useMemo(() => {
    if (processGates.isLoading || processGates.data === undefined) {
      return {
        isLoading: true,
        processGates: undefined,
      }
    } else {
      return {
        isLoading: false,
        processGates: processGates.data,
      }
    }
  }, [processGates.data, processGates.isLoading])

  return <ProcessGateContext.Provider value={ctxValue}> {children} </ProcessGateContext.Provider>
}

export function useMaybeProcessGateContext() {
  return useContext(ProcessGateContext)
}
export function useProcessGateContext() {
  const ctx = useContext(ProcessGateContext)
  if (ctx == null) {
    throw new Error('usage of ProcessGateContext outside of a ProcessGateContextProvider')
  }
  return ctx
}
