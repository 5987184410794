import { ChangeEvent, useMemo } from 'react'
import { InputProps } from '.'
import { dsv } from '../../../styles/defaults'

export function InputBoolean({ fieldId, strValue, setStrValue, onBlur, style }: InputProps) {
  const onChange = useMemo(() => {
    if (setStrValue) {
      return (event: ChangeEvent<HTMLInputElement>) => {
        if (onBlur) {
          onBlur(undefined, event.target.checked ? 'true' : '')
        }
        setStrValue(event.target.checked ? 'true' : '')
      }
    } else {
      return undefined
    }
  }, [onBlur, setStrValue])

  return (
    <input
      type="checkbox"
      id={fieldId}
      value={strValue}
      disabled={onChange == undefined}
      checked={strValue == 'true'}
      onChange={onChange}
      style={{ height: 24, ...dsv.text, ...style }}
      onBlur={onBlur}
    />
  )
}
