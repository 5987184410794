import { useCallback, useMemo } from 'react'
import { SelectProps } from '.'
import { useTranslation } from 'react-i18next'
import SelectList from '../../SelectList'

export function InputSelect({
  strValue,
  setStrValue,
  options,
  optionsWithKey,
  onBlur,
  search,
  style,
  placeholder,
  boxStyles,
  inputStyles,
  dropdownStyles,
  dropdownItemStyles,
  dropdownTextStyles,
  disabledItemStyles,
  disabledTextStyles,
}: SelectProps) {
  const { t } = useTranslation()

  const usedOptions = useMemo(() => {
    if (optionsWithKey != null) {
      return optionsWithKey
    }

    return (
      options?.map((s) => ({
        key: s,
        value: t(s),
      })) ?? []
    )
  }, [options, optionsWithKey, t])

  const defaultOption = useMemo(() => usedOptions.find((o) => o.key === strValue) ?? undefined, [usedOptions, strValue])

  const setSelected = useCallback(
    (selectedKey: string) => {
      if (setStrValue != null && defaultOption?.key !== selectedKey) {
        setStrValue(selectedKey)
        if (onBlur) {
          onBlur(undefined, selectedKey)
        }
      }
    },
    [setStrValue, defaultOption?.key, onBlur]
  )
  return (
    <SelectList
      boxStyles={{ ...style, ...boxStyles }}
      inputStyles={{ ...style, ...inputStyles }}
      dropdownStyles={{ ...dropdownStyles }}
      dropdownItemStyles={dropdownItemStyles}
      dropdownTextStyles={dropdownTextStyles}
      disabledItemStyles={disabledItemStyles}
      disabledTextStyles={disabledTextStyles}
      search={search}
      disabled={setStrValue == null}
      data={usedOptions}
      defaultOption={defaultOption}
      setSelected={setSelected}
      placeholder={placeholder}
    />
  )
}
