/* eslint max-len: 0 */ // --> OFF

import { Div } from '@expo/html-elements'
import { Text } from '../../../../components/TextComponents'

import { useAkteContext } from '../../../../hooks/context/useAkteContext'

import { useAllPermissions } from '../../../../hooks/useAllPermissions'
import { useMemo } from 'react'
import { process_akte } from '@dpa/common/dist'
import { ProgressBar } from '../../../../components/ProgressBar'
import { okStats } from '../../../../hooks/useOkStats'
import { useProcessGateContext } from '../../../../hooks/context/useProcessGateContext'

import { useAkteSalesLines } from '../../../../hooks/useAkteSalesLines'
import { SubCard } from '../../../../components/SubCard'
import { NoDataPlaceholder } from '../../../../constants'
import { dsv } from '../../../../styles/defaults'
import { Role } from '@dpa/server/trpc/routers/permissions'
import { useTranslation } from 'react-i18next'
import { useProgressBarToDisplay } from '../../../../hooks/roleBasedDisplay/useProgressBarToDisplay'
import { DimensionValue } from 'react-native'

interface UebergabeHeaderRowProps {
  Ver2Eng_label: string
  Eng2Ver_label: string
  Ver2PMO_label: string
  PMO2SuW_label: string
}
function UebergabeHeaderRow({ Ver2Eng_label, Eng2Ver_label, Ver2PMO_label, PMO2SuW_label }: UebergabeHeaderRowProps) {
  return (
    <Div style={{ display: 'flex', flexDirection: 'row' }}>
      <Div style={{ width: '15%' }} />
      <Div style={{ backgroundColor: dsv.colors.GpJouleGelb, width: '20%' }}>
        <Text style={{ padding: 10, fontSize: 18, color: dsv.colors.connectGrau }}>{Ver2Eng_label}</Text>
      </Div>
      <Div style={{ backgroundColor: dsv.colors.GpJouleGelb, width: '20%' }}>
        <Text style={{ padding: 10, fontSize: 18, color: dsv.colors.connectGrau }}>{Eng2Ver_label}</Text>
      </Div>
      <Div style={{ backgroundColor: dsv.colors.GpJouleGelb, width: '20%' }}>
        <Text style={{ padding: 10, fontSize: 18, color: dsv.colors.connectGrau }}>{Ver2PMO_label}</Text>
      </Div>
      <Div style={{ backgroundColor: dsv.colors.GpJouleGelb, width: '20%' }}>
        <Text style={{ padding: 10, fontSize: 18, color: dsv.colors.connectGrau }}>{PMO2SuW_label}</Text>
      </Div>
    </Div>
  )
}

interface UebergabeRowProps {
  label: string
  Ver2Eng: string
  Eng2Ver: string
  Ver2PMO: string
  PMO2SuW: string
}
function UebergabeRow({ label, Ver2Eng, Eng2Ver, Ver2PMO, PMO2SuW }: UebergabeRowProps) {
  return (
    <Div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 5,
        borderColor: dsv.colors.connectGrau30,
        borderStyle: 'solid',
        borderBottomWidth: 1,
        marginVertical: 5,
      }}
    >
      <Div style={{ width: '15%' }}>
        <Text style={{ color: dsv.colors.black }}>{label}</Text>
      </Div>
      <Div style={{ width: '20%' }}>
        <Text>{Ver2Eng}</Text>
      </Div>
      <Div style={{ width: '20%' }}>
        <Text>{Eng2Ver}</Text>
      </Div>
      <Div style={{ width: '20%' }}>
        <Text>{Ver2PMO}</Text>
      </Div>
      <Div style={{ width: '20%' }}>
        <Text>{PMO2SuW}</Text>
      </Div>
    </Div>
  )
}

interface UebergabeProgressProps {
  label: Role | 'Vorhanden Gesamt'
  Ver2Eng: number
  Eng2Ver: number
  Ver2PMO: number
  PMO2SuW: number
  height?: DimensionValue
}
function UebergabeProgressRow({ label, Ver2Eng, Eng2Ver, Ver2PMO, PMO2SuW, height = 12 }: UebergabeProgressProps) {
  const { t } = useTranslation()
  const toDisplay = useProgressBarToDisplay(label == 'Vorhanden Gesamt' ? undefined : label) //TODO: this looks Very wrong

  return (
    <Div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 5,
        //borderColor: dsv.colors.connectGrau30,
        //borderStyle: "solid",
        //borderBottomWidth: 1,
        marginVertical: 5,
      }}
    >
      <Div style={{ width: '15%' }}>
        <Text style={{ color: dsv.colors.black }}>{t(label)}</Text>
      </Div>
      <Div style={{ width: '20%' }}>
        {!toDisplay.includes('VertriebAnEngineering') ? undefined : (
          <ProgressBar height={height} width={'90%'} value={Ver2Eng} displayPercentage />
        )}
      </Div>
      <Div style={{ width: '20%' }}>
        {!toDisplay.includes('EngineeringAnVertrieb') ? undefined : (
          <ProgressBar height={height} width={'90%'} value={Eng2Ver} displayPercentage />
        )}
      </Div>
      <Div style={{ width: '20%' }}>
        {!toDisplay.includes('VertriebAnPMO') ? undefined : (
          <ProgressBar height={height} width={'90%'} value={Ver2PMO} displayPercentage />
        )}
      </Div>
      <Div style={{ width: '20%' }}>
        {!toDisplay.includes('PMOAnS&W') ? undefined : (
          <ProgressBar height={height} width={'90%'} value={PMO2SuW} displayPercentage />
        )}
      </Div>
    </Div>
  )
}

interface UebergabeSpacerRowProps {
  size: 'small' | 'normal' | 'large'
}

function UebergabeSpacerRow({ size }: UebergabeSpacerRowProps) {
  return (
    <Div
      // TODO: Fix styling for mobile app
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style={{ height: size == 'small' ? '0.5em' : size == 'normal' ? '1em' : '1.5em' }}
    ></Div>
  )
}

function formatAkzeptiertAm(
  requested: Date | undefined | null,
  rejected: Date | undefined | null,
  approved: Date | undefined | null
) {
  return requested == undefined
    ? rejected == undefined
      ? NoDataPlaceholder
      : `Abgelehnt am ${rejected?.toLocaleDateString()}`
    : approved?.toLocaleDateString() ?? 'Ausstehend'
}

function formatReason(doNotDisplayIfDefined: unknown | undefined | null, text: string | undefined | null) {
  return doNotDisplayIfDefined != undefined ? NoDataPlaceholder : text ?? NoDataPlaceholder
}

function formatDate(date: Date | undefined | null) {
  return date?.toLocaleDateString() ?? NoDataPlaceholder
}

export function SubCardUebergaben() {
  const akte = useAkteContext()

  const salesLines = useAkteSalesLines(akte.identifier)

  const allPermissions = useAllPermissions()

  const processGateCtx = useProcessGateContext()

  const isLoading = akte.isLoading || allPermissions.isLoading || processGateCtx.isLoading || salesLines.isLoading

  const progress = useMemo(() => {
    if (isLoading) {
      return undefined
    }
    const projectType = process_akte.processType(akte.serverState)
    const states = ['UebergabeAnProjektierung', 'UebergabeAnVertrieb', 'UebergabeAnPMO', 'UebergabeAnSW'] as const
    const requiredPerState = Object.fromEntries(
      (Object.entries(processGateCtx.processGates[projectType]) as Array<[string, { [key: string]: string }]>).map(
        //TODO: useProcessGateContext
        ([step, requiredFields]) => {
          console.log('requiredFields', requiredFields)
          return [
            step,
            Object.entries(requiredFields)
              .filter(([_fieldName, isRequired]) => String(isRequired) === 'required')
              .map(([fieldName, _isRequired]) => fieldName),
          ]
        }
      )
    )
    console.log('projectType', projectType)
    console.log('processGateCtx.processGates[projectType]', processGateCtx.processGates[projectType])
    console.log('requiredPerState', requiredPerState)

    const relevantFields = Object.fromEntries(
      Object.entries(allPermissions.permissions!).map(([rolle, permissions]) => {
        const writableFields = Object.entries(permissions.permissions)
          .filter(([_name, p]) => p?.write)
          .map(([name, _]) => name)

        return [rolle, writableFields]
      })
    )
    const p = Object.fromEntries(
      states.map((s) => [
        s,
        Object.fromEntries(
          Object.keys(relevantFields).map((rf) => {
            return [
              rf,
              okStats(
                Object.fromEntries(
                  relevantFields[rf]
                    .filter((f) => requiredPerState[s].includes(f))
                    .map((f) => [
                      f,
                      {
                        ok: akte.serverState[f as keyof typeof akte.serverState] != null,
                        notNullish: akte.serverState[f as keyof typeof akte.serverState] != null,
                      },
                    ])
                )
              ),
            ]
          })
        ),
      ])
    )
    return p
  }, [isLoading, akte, processGateCtx.processGates, allPermissions.permissions])

  const totalProgress = useMemo(() => {
    if (progress == undefined) {
      return undefined
    }
    const progressPerStep = Object.entries(progress).map(([step, progressPerGroup]) => {
      return [
        step,
        Object.values(progressPerGroup).reduce(
          (a, b) => ({
            okCount: a.okCount + b.okCount,
            nokCount: a.nokCount + b.nokCount,
          }),
          { okCount: 0, nokCount: 0 }
        ),
      ] as [
        string,
        {
          okCount: number
          nokCount: number
          okPercentage: number
        },
      ]
    })

    const progressPercentagePerStep = progressPerStep.map(([step, progress]) => [
      step,
      {
        ...progress,
        okPercentage:
          progress.nokCount + progress.okCount === 0
            ? 100
            : (100 / (progress.nokCount + progress.okCount)) * progress.okCount,
      },
    ])

    return Object.fromEntries(progressPercentagePerStep)
  }, [progress])

  return (
    <>
      <SubCard title="Übergaben">
        <Div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <UebergabeHeaderRow
            Ver2Eng_label="Übergabe von Vertrieb an Engineering"
            Eng2Ver_label="Übergabe von Engineering an Vertrieb"
            Ver2PMO_label="Übergabe von Vertrieb an PMO"
            PMO2SuW_label="Übergabe von PMO an S&W"
          />
          <UebergabeSpacerRow size="small" />
          <UebergabeRow
            label={'Beantragt am'}
            Ver2Eng={formatDate(akte.serverState?.Process_Uebergabe_Projektierung_requested)}
            Eng2Ver={formatDate(akte.serverState?.Process_Uebergabe_Vertrieb_requested)}
            Ver2PMO={formatDate(akte.serverState?.Process_Uebergabe_PMO_requested)}
            PMO2SuW={formatDate(akte.serverState?.Process_Uebergabe_SW_requested)}
          />
          <UebergabeRow
            label={'Genehmigt am'}
            Ver2Eng="N/A"
            Eng2Ver={formatDate(akte.serverState?.Process_Uebergabe_Vertrieb_preapproved)}
            Ver2PMO="N/A"
            PMO2SuW="N/A"
          />
          <UebergabeRow
            label={'Akzeptiert am'}
            Ver2Eng={formatAkzeptiertAm(
              akte.serverState?.Process_Uebergabe_Projektierung_requested,
              akte.serverState?.Process_Uebergabe_Projektierung_rejected,
              akte.serverState?.Process_Uebergabe_Projektierung_approved
            )}
            Eng2Ver={formatAkzeptiertAm(
              akte.serverState?.Process_Uebergabe_Vertrieb_requested,
              akte.serverState?.Process_Uebergabe_Vertrieb_rejected,
              akte.serverState?.Process_Uebergabe_Vertrieb_approved
            )}
            Ver2PMO={formatAkzeptiertAm(
              akte.serverState?.Process_Uebergabe_PMO_requested,
              akte.serverState?.Process_Uebergabe_PMO_rejected,
              akte.serverState?.Process_Uebergabe_PMO_approved
            )}
            PMO2SuW={formatAkzeptiertAm(
              akte.serverState?.Process_Uebergabe_SW_requested,
              akte.serverState?.Process_Uebergabe_SW_rejected,
              akte.serverState?.Process_Uebergabe_SW_approved
            )}
          />

          <UebergabeRow
            label={'Zurückgegeben am'}
            Ver2Eng={formatDate(akte.serverState?.Process_Uebergabe_Projektierung_revoked)}
            Eng2Ver={formatDate(akte.serverState?.Process_Uebergabe_Vertrieb_revoked)}
            Ver2PMO={formatDate(akte.serverState?.Process_Uebergabe_PMO_revoked)}
            PMO2SuW={formatDate(akte.serverState?.Process_Uebergabe_SW_revoked)}
          />

          <UebergabeRow
            label={'Ablehnungsgrund'}
            Ver2Eng={formatReason(
              akte.serverState?.Process_Uebergabe_Projektierung_approved,
              akte.serverState?.Process_Uebergabe_Projektierung_rejectionComment
            )}
            Eng2Ver={formatReason(
              akte.serverState?.Process_Uebergabe_Vertrieb_approved,
              akte.serverState?.Process_Uebergabe_Vertrieb_rejectionComment
            )}
            Ver2PMO={formatReason(
              akte.serverState?.Process_Uebergabe_PMO_approved,
              akte.serverState?.Process_Uebergabe_PMO_rejectionComment
            )}
            PMO2SuW={formatReason(
              akte.serverState?.Process_Uebergabe_SW_approved,
              akte.serverState?.Process_Uebergabe_SW_rejectionComment
            )}
          />

          <UebergabeRow
            label={'Rückgabegrund'}
            Ver2Eng={formatReason(
              akte.serverState?.Process_Uebergabe_Projektierung_revoked,
              akte.serverState?.Process_Uebergabe_Projektierung_revocationComment
            )}
            Eng2Ver={formatReason(
              akte.serverState?.Process_Uebergabe_Vertrieb_revoked,
              akte.serverState?.Process_Uebergabe_Vertrieb_revocationComment
            )}
            Ver2PMO={formatReason(
              akte.serverState?.Process_Uebergabe_PMO_revoked,
              akte.serverState?.Process_Uebergabe_PMO_revocationComment
            )}
            PMO2SuW={formatReason(
              akte.serverState?.Process_Uebergabe_SW_revoked,
              akte.serverState?.Process_Uebergabe_SW_revocationComment
            )}
          />

          <UebergabeSpacerRow size="large" />
          <UebergabeProgressRow
            label="Vorhanden Gesamt"
            Ver2Eng={totalProgress['UebergabeAnProjektierung']?.okPercentage}
            Eng2Ver={totalProgress['UebergabeAnVertrieb']?.okPercentage}
            Ver2PMO={totalProgress['UebergabeAnPMO']?.okPercentage}
            PMO2SuW={totalProgress['UebergabeAnSW']?.okPercentage}
            height={18}
          />

          <UebergabeSpacerRow size="normal" />
          <UebergabeProgressRow
            label="Vertrieb"
            Ver2Eng={progress == undefined ? 0 : progress['UebergabeAnProjektierung']['Vertrieb']?.okPercentage}
            Eng2Ver={progress == undefined ? 0 : progress['UebergabeAnVertrieb']['Vertrieb']?.okPercentage}
            Ver2PMO={progress == undefined ? 0 : progress['UebergabeAnPMO']['Vertrieb']?.okPercentage}
            PMO2SuW={progress == undefined ? 0 : progress['UebergabeAnSW']['Vertrieb']?.okPercentage}
          />

          <UebergabeProgressRow
            label="Innendienst"
            Ver2Eng={progress == undefined ? 0 : progress['UebergabeAnProjektierung']['Innendienst']?.okPercentage}
            Eng2Ver={progress == undefined ? 0 : progress['UebergabeAnVertrieb']['Innendienst']?.okPercentage}
            Ver2PMO={progress == undefined ? 0 : progress['UebergabeAnPMO']['Innendienst']?.okPercentage}
            PMO2SuW={progress == undefined ? 0 : progress['UebergabeAnSW']['Innendienst']?.okPercentage}
          />
          <UebergabeProgressRow
            label="Projektierung"
            Ver2Eng={progress == undefined ? 0 : progress['UebergabeAnProjektierung']['Projektierung']?.okPercentage}
            Eng2Ver={progress == undefined ? 0 : progress['UebergabeAnVertrieb']['Projektierung']?.okPercentage}
            Ver2PMO={progress == undefined ? 0 : progress['UebergabeAnPMO']['Projektierung']?.okPercentage}
            PMO2SuW={progress == undefined ? 0 : progress['UebergabeAnSW']['Projektierung']?.okPercentage}
          />
          <UebergabeProgressRow
            label="PMO"
            Ver2Eng={progress == undefined ? 0 : progress['UebergabeAnProjektierung']['PMO']?.okPercentage}
            Eng2Ver={progress == undefined ? 0 : progress['UebergabeAnVertrieb']['PMO']?.okPercentage}
            Ver2PMO={progress == undefined ? 0 : progress['UebergabeAnPMO']['PMO']?.okPercentage}
            PMO2SuW={progress == undefined ? 0 : progress['UebergabeAnSW']['PMO']?.okPercentage}
          />
          <UebergabeProgressRow
            label="Projekt Support"
            Ver2Eng={progress == undefined ? 0 : progress['UebergabeAnProjektierung']['Projekt Support']?.okPercentage}
            Eng2Ver={progress == undefined ? 0 : progress['UebergabeAnVertrieb']['Projekt Support']?.okPercentage}
            Ver2PMO={progress == undefined ? 0 : progress['UebergabeAnPMO']['Projekt Support']?.okPercentage}
            PMO2SuW={progress == undefined ? 0 : progress['UebergabeAnSW']['Projekt Support']?.okPercentage}
          />
          {/*
          <UebergabeProgressRow
            label="Finanzen"
            Ver2Eng={progress == undefined ? 0 : progress['UebergabeAnProjektierung']['Finanzen']?.okPercentage}
            Eng2Ver={progress == undefined ? 0 : progress['UebergabeAnVertrieb']['Finanzen']?.okPercentage}
            Ver2PMO={progress == undefined ? 0 : progress['UebergabeAnPMO']['Finanzen']?.okPercentage}
            PMO2SuW={progress == undefined ? 0 : progress['UebergabeAnSW']['Finanzen']?.okPercentage}
          />
          <UebergabeProgressRow
            label="Service & Wartung"
            Ver2Eng={
              progress == undefined ? 0 : progress['UebergabeAnProjektierung']['Service & Wartung']?.okPercentage
            }
            Eng2Ver={progress == undefined ? 0 : progress['UebergabeAnVertrieb']['Service & Wartung']?.okPercentage}
            Ver2PMO={progress == undefined ? 0 : progress['UebergabeAnPMO']['Service & Wartung']?.okPercentage}
            PMO2SuW={progress == undefined ? 0 : progress['UebergabeAnSW']['Service & Wartung']?.okPercentage}
          />*/}
        </Div>
      </SubCard>
    </>
  )
}
