import { trpc } from '../../../../App'

export function useSyncAkteWithBCMutation() {
  const trpcCtx = trpc.useContext()
  return trpc.akte.syncWithBC.useMutation({
    onSuccess: ({ id }) => {
      trpcCtx.akte.byId.invalidate({ id })
      trpcCtx.akte.getAll.invalidate()
      trpcCtx.akte.salesLines.invalidate()
      trpcCtx.audit.invalidate({ entryId: id, entryType: 'akte' })
    },
  })
}
