import formLabels from './de/labels.json'
import formNote from './de/notes.json'

import enumTranslation from './de/enum.json'

const translationPrefixes = {
  labels: 'form_label_',
  notes: 'form_note_',
  tableHeaders: 'table_header_',
} as const

export function addPrefix<TTyp extends keyof typeof translationPrefixes, TKey extends string>(
  typ: TTyp,
  key: TKey
): `${(typeof translationPrefixes)[TTyp]}${TKey}` {
  return `${translationPrefixes[typ]}${key}`
}

export function addKeyPrefix<
  TTyp extends keyof typeof translationPrefixes,
  TKeys extends string,
  TValues extends string,
>(typ: TTyp, obj: Record<TKeys, TValues>) {
  const entries = Object.entries(obj)
  const prefixedEntries = entries.map(([key, value]) => [addPrefix(typ, key), value])

  const prefixedObj = Object.fromEntries(prefixedEntries) as {
    [key in TKeys as ReturnType<typeof addPrefix<TTyp, key>>]: (typeof obj)[key]
  }

  return prefixedObj
}

export type CanonicalFormLabels = keyof typeof formLabels

// TODO: this might not be save, as typescript is TS is not strictly typed
//       But they are loaded from json, so it *should* be fine I guess
export const canonicalFormLabels = Object.keys(formLabels) as CanonicalFormLabels[]

export type FormLabelKeys = ReturnType<typeof addPrefix<'labels', CanonicalFormLabels>>

export function asFormLabel(candidate: string): CanonicalFormLabels | undefined {
  if ((canonicalFormLabels as string[]).includes(candidate)) {
    return candidate as CanonicalFormLabels
  }
  return undefined
}

export type OnlyValideLabels<T> = T extends CanonicalFormLabels ? T : never

export type CanonicalFormNotes = keyof typeof formNote
export const canonicalFormNotes = Object.keys(formNote) as CanonicalFormNotes[]
export type FormNotesKeys = ReturnType<typeof addPrefix<'notes', CanonicalFormNotes>>

export function BuildTranslationMap(
  formLabels: Record<CanonicalFormLabels, string>,
  formNotes: Record<CanonicalFormNotes, string>
) {
  return {
    ...addKeyPrefix('labels', formLabels),
    ...addKeyPrefix('notes', formNotes),

    ...addKeyPrefix('tableHeaders', {
      creationData: 'Erstellt am',
      PDB_Projektnummer: 'P-Nummer',
      UE_Kundendaten_Projekttitel: 'Projekttitel',
      PDB_Projekttyp: 'Projekttyp',
      PDB_Projektart: 'Projektart',
      UE_Kundendaten_Ansprechpartner_Name: 'Ansprechperson',
      UE_Zeitraum_GeplantesInstallationsdatum: ' Geplantes Installationsdatum',
      projectStatus: 'Projektstatus',
      progress: 'Fortschritt',
    }),
    ...enumTranslation,
  }
}

export type TranslationMap = ReturnType<typeof BuildTranslationMap>
