import { View } from 'react-native'

import { Section } from '@expo/html-elements'
import { Text } from '../../../components/TextComponents'

import { Card, CardBody } from '../../../components/Card'

import { CollapsibleForm } from '../../../components/form/CollapsibleForm'

import { PermissionFormHeader } from '../../../hooks/form/generic/usePermissionForm'

import { ProgressBar } from '../../../components/ProgressBar'

import { useAkteContext } from '../../../hooks/context/useAkteContext'
import { Collapsible } from '../../../components/Collapsible'
import { useDatenblattForms } from '../../../hooks/form/useDatenblattForms'
import { useMemo } from 'react'
import { datenblattFormGroupDefinitions } from '../../../hooks/form/useDatenblattFormGroup'
import { LoadingScreen } from '../../../components/LoadingScreen'
import { HistoryCollapsible } from './components/HistoryCollapsible'
import { ProjectCardHeader } from './components/ProjectCardHeader'

export function ProjectsProjectDatenblatt() {
  const akte = useAkteContext()

  const { forms: datenblattForms } = useDatenblattForms()
  const forms = useMemo(
    () =>
      datenblattFormGroupDefinitions.map(({ key, form, forms, title }) => {
        if (form !== undefined) {
          const generatedFields = datenblattForms[form]?.entries.map(([name, elem]) => (
            <div key={`${name as string}`}>{elem}</div>
          ))

          if (generatedFields.length === 0) {
            return <></>
          }
          return (
            <View key={`${key}`}>
              <CollapsibleForm
                title={
                  <Section
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <ProgressBar displayPercentage value={datenblattForms[form]?.okStats.okPercentage} />
                    <Text style={{ fontSize: 20, marginLeft: 8 }}>{title}</Text>
                  </Section>
                }
                formFieldHeader={<PermissionFormHeader okFields={datenblattForms[form]?.okFields ?? {}} />}
                formFields={generatedFields}
              />
            </View>
          )
        } else if (forms !== undefined) {
          const generatedForms = forms
            .map(({ key: nestedKey, form, title }) => {
              if (form !== undefined) {
                const entries = datenblattForms[form]?.entries
                if (nestedKey !== 'Backend_Identifikationsmedien' && entries.length === 0) {
                  return undefined
                }
                if (title == undefined) {
                  return <div key={`${key}-${nestedKey}`}>{entries.map(([_name, elem]) => elem)}</div>
                }

                return (
                  <CollapsibleForm
                    key={`${key}-${nestedKey}`}
                    defaultOpen={true}
                    title={
                      <Section
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                        }}
                      >
                        <ProgressBar displayPercentage value={datenblattForms[form]?.okStats.okPercentage} />
                        <Text style={{ fontSize: 20, marginLeft: 8 }}>{title}</Text>
                      </Section>
                    }
                    formFieldHeader={<PermissionFormHeader okFields={datenblattForms[form]?.okFields ?? {}} />}
                    formFields={entries.map(([name, elem], _i) => <div key={`${name as string}`}>{elem}</div>)
                      /*[
                        ...(nestedKey === 'Backend_Identifikationsmedien' ? [identTableJsx] : []),
                        ...entries.map(([name, elem], i) => <div key={`${name as string}`}>{elem}</div>),
                      ]*/
                      // TODO: Backend_Identifikationsmedien not as edge case
                    }
                  />
                )
              }
            })
            .filter((e) => e !== undefined)
          if (generatedForms.length === 0) {
            return <></>
          }

          let percentage = 0
          for (const f of forms) {
            percentage += datenblattForms[f.form]?.okStats.okPercentage
          }

          return (
            <View key={key}>
              <Collapsible
                summary={
                  <Section
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    {/*<ProgressBar displayPercentage value={datenblattForms[form]?.okStats.okPercentage} />*/}
                    <ProgressBar displayPercentage value={percentage / forms.length} />
                    <Text style={{ fontSize: 20, marginLeft: 8 }}>{title}</Text>
                  </Section>
                }
              >
                {generatedForms}
              </Collapsible>
            </View>
          )
        }
      }),
    [datenblattForms]
  )

  return (
    <Section style={{ flex: 1 }}>
      <Card customStyle={{ flex: 1 }}>
        {akte.isLoading && (
          <CardBody customStyle={{ minHeight: 300 }}>
            <LoadingScreen active />
          </CardBody>
        )}
        {!akte.isLoading && (
          <>
            <ProjectCardHeader />
            <CardBody customStyle={{ flex: 1, overflow: 'scroll' }}>
              {forms}

              <HistoryCollapsible identifier={akte.identifier} />
            </CardBody>
          </>
        )}
      </Card>
    </Section>
  )
}
