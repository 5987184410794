import { ChangeEvent, Fragment, useCallback, useMemo } from 'react'
import { SelectProps } from '.'
import { useTranslation } from 'react-i18next'

export function InputRadioList({ strValue, setStrValue, options, optionsWithKey, onBlur, search, style }: SelectProps) {
  const { t } = useTranslation()

  const uuid = useMemo(() => crypto.randomUUID(), [])

  const usedOptions = useMemo(() => {
    if (optionsWithKey != null) {
      return optionsWithKey
    }

    return (
      options?.map((s) => ({
        key: s,
        value: t(s),
      })) ?? []
    )
  }, [options, optionsWithKey, t])

  const onChange = useCallback(
    (changeEvent: ChangeEvent<HTMLInputElement>) => {
      if (setStrValue != null && strValue !== changeEvent.target.value) {
        setStrValue(changeEvent.target.value)
        if (onBlur) {
          onBlur(undefined, changeEvent.target.value)
        }
      }
    },
    [setStrValue, strValue, onBlur]
  )

  return (
    <>
      <fieldset style={{ borderRadius: '5px' }}>
        {' '}
        {/* TODO: Styling*/}
        {usedOptions.map((options) => (
          <Fragment key={options.key}>
            <input
              type="radio"
              id={`${uuid}_${options.key}`}
              checked={strValue == options.key}
              value={options.key}
              onChange={onChange}
            />
            <label htmlFor={`${uuid}_${options.key}`}>{options.value}</label>
          </Fragment>
        ))}
      </fieldset>
    </>
  )
}
