import {
  TD as NTD,
  TH as NTH,
  H1 as NH1,
  H2 as NH2,
  H3 as NH3,
  H4 as NH4,
  B as NB,
  A as NA,
  P as NP,
  Strong as NStrong,
} from '@expo/html-elements'
import { Text as NText, TextProps } from 'react-native'
import { dsv } from '../styles/defaults'
import { TableTextProps } from '@expo/html-elements/build/primitives/Table'
import { LinkProps } from '@expo/html-elements/build/elements/Text.types'

export function TD(props: TableTextProps) {
  return <NTD {...props} style={[dsv.text, props?.style]} />
}

export function P(props: TableTextProps) {
  return <NP {...props} style={[dsv.text, props?.style]} />
}

export function TH(props: TableTextProps) {
  return <NTH {...props} style={[dsv.text, props?.style]} />
}

export function Text(props: TextProps) {
  return <NText {...props} style={[dsv.text, props?.style]} />
}

export function H1(props: TextProps) {
  return (
    <NH1
      {...props}
      style={[
        dsv.heading,
        // TODO: Fix styling for mobile app
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props?.style,
      ]}
    />
  )
}

export function H2(props: TextProps) {
  return (
    <NH2
      {...props}
      style={[
        { color: dsv.colors.black },
        dsv.heading,
        // TODO: Fix styling for mobile app
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props?.style,
      ]}
    />
  )
}

export function H3(props: TextProps) {
  return (
    <NH3
      {...props}
      style={[
        dsv.heading,
        // TODO: Fix styling for mobile app
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props?.style,
      ]}
    />
  )
}

export function H4(props: TextProps) {
  return (
    <NH4
      {...props}
      style={[
        dsv.heading,
        // TODO: Fix styling for mobile app
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props?.style,
      ]}
    />
  )
}

export function B(props: TextProps) {
  return (
    <NB
      {...props}
      style={[
        dsv.text,
        // TODO: Fix styling for mobile app
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props?.style,
      ]}
    />
  )
}

export function A(props: LinkProps) {
  return <NA {...props} style={[dsv.text, props?.style]} />
}

export function Strong(props: TextProps) {
  return (
    <NStrong
      {...props}
      style={[
        dsv.text,
        // TODO: Fix styling for mobile app
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        props?.style,
      ]}
    />
  )
}
