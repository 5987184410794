import { DimensionValue, View } from 'react-native'
import { Text } from '../components/TextComponents'
import { Div } from '@expo/html-elements'
import { dsv } from '../styles/defaults'

interface ProgressBarProps {
  value: number // Progress value between 0 and 100
  displayPercentage?: boolean
  width?: DimensionValue
  height?: DimensionValue
}

export function ProgressBar({ value, displayPercentage = false, width = 50, height = 14 }: ProgressBarProps) {
  // Ensure the progress value is within the valid range (0 to 100)
  const normalizedValue = Math.min(100, Math.max(0, value))

  return (
    <Div
      style={{
        backgroundColor: dsv.colors.base3,
        overflow: 'hidden',
        width,
        borderRadius: 4,
        display: 'flex',
        flexDirection: 'row',
        borderColor: 'rgba(128,128,128,.5)',
        borderStyle: 'solid',
        borderWidth: 1,
      }}
    >
      <Div style={{ height: height, width: `${normalizedValue}%`, backgroundColor: dsv.colors.GpJouleGelb }}>
        {displayPercentage && (
          <Text
            style={{
              paddingLeft: 8,
              fontSize: 10,
              overflow: 'visible',
              // TODO: Fix styling for mobile app
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              whiteSpace: 'nowrap',
              textAlign: 'center',
              lineHeight: { height },
              marginVertical: 'auto',
            }}
          >
            {normalizedValue.toFixed(0)}%
          </Text>
        )}
      </Div>
    </Div>
  )
}
