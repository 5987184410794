import { View } from 'react-native'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { dsv } from '../../styles/defaults'
import { Card, CardAction, CardBody, CardHeader } from '../../components/Card'
import { Div } from '@expo/html-elements'
import { H2, H3, Text, P } from '../../components/TextComponents'
import { InputText } from '../../components/form/InputFields'
import { LoadingButton } from '../../components/form/InputFields/LoadingButton'
import useLogin from '../../hooks/auth/useLogin'

import logo from '../../../assets/logos/GPJoule_neg_rgb.png'
import { SuperSign } from '../../components/SuperSign'

export function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { loading, login, unauthorized } = useLogin()

  const valid = useMemo(() => email.length > 5 && password.length > 5, [email, password])

  const onSubmit = useCallback(() => {
    login(email, password)
  }, [email, login, password])

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (valid && (event.code === 'Enter' || event.code === 'NumpadEnter')) {
        event.preventDefault()
        onSubmit()
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  }, [onSubmit, valid])

  return (
    <>
      <style>{'#background { background-color: dsv.colors.connectGrau7; }'}</style>
      <Div
        id="background"
        style={{
          flex: 1,
          //backgroundColor: dsv.colors.connectGrau7,
        }}
      >
        <SuperSign />
        <View
          style={{
            zIndex: 2,
            flexDirection: 'row',
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <Card customStyle={{ width: 450, padding: 50 }}>
            <CardHeader>
              <View style={{ width: '100%', marginHorizontal: 'auto' }}>
                <img alt="Logo" src={logo} style={{ margin: 'auto', width: 250 }} />
                <H2 style={{ color: dsv.colors.connectGrau7, marginBottom: 0 }}>Anmelden</H2>
              </View>
            </CardHeader>
            <CardBody>
              <label htmlFor="email" style={{ marginTop: 8 }}>
                <Text style={{ color: dsv.colors.connectGrau7, fontSize: 12 }}>E-Mail Adresse</Text>
              </label>
              <InputText fieldId="email" type="email" strValue={email} setStrValue={setEmail}></InputText>
              <label htmlFor="password" style={{ marginTop: 8 }}>
                <Text style={{ color: dsv.colors.connectGrau7, fontSize: 12 }}>Passwort</Text>
              </label>
              <InputText type="password" fieldId="password" strValue={password} setStrValue={setPassword}></InputText>
              {unauthorized && (
                <P>
                  <H3 style={{ color: 'red' }}>Password oder Email sind nicht korrekt.</H3>
                </P>
              )}
            </CardBody>
            <CardAction>
              <LoadingButton disabled={!valid} isLoading={loading} onPress={onSubmit} title="Anmelden" />
            </CardAction>
          </Card>
        </View>
      </Div>
    </>
  )
}
