// import { z } from 'zod'
// import { NoExtraProperties, NonPartial } from '../utils/typing'
// import { OnlyValideLabels } from '../locales/translationKeys'

import { ProcessGateTableSchema } from '@dpa/common/dist'
import { SchemaToFormSchema } from '../components/form/Autoform'
import { mapObj } from '@dpa/common/dist'

const RequiredFieldsSchema = ProcessGateTableSchema.omit({
  processType: true,
  processUebergabeStep: true,
})
export const RequiredFieldsFormFields = mapObj(RequiredFieldsSchema.shape, () => true)

export const RequiredFieldsForm = SchemaToFormSchema(RequiredFieldsSchema, {})

// type _ProjektAnlageFormType = NonPartial<z.infer<typeof RequiredFieldsForm>>
// type _HasLabels_Uebergabe_KundendatenForm = NoExtraProperties<
//   _ProjektAnlageFormType,
//   Pick<_ProjektAnlageFormType, OnlyValideLabels<keyof _ProjektAnlageFormType>>
// >
