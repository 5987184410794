import { Pressable, View } from 'react-native'
import { Link } from '../utils/routing'
import { Icon, IconName } from '../components/Icon'
import { dsv } from '../styles/defaults'
import logo from '../../assets/logos/GPJoule_neg_rgb.png'
import { useAuthContext } from '../hooks/context/useAuthContext'
import { useTranslation } from 'react-i18next'
import { UL, LI } from '@expo/html-elements'
import { Text } from '../components/TextComponents'

import connectAnker from '../../assets/styleElements/CONNECT_Anker_negativ.png'
//import superSignO from '../../assets/Icons2/Supersign_O_-neg_P115C.png'
import superSignO from '../../assets/Icons/Supersign_O_-neg_P115C.png'

export interface LinkProps {
  text: string
  target: string | (() => void)
  icon?: IconName
  disabled?: boolean
  children?: LinkProps[]
}

interface UiControls {
  isRoot?: boolean
  expanded: boolean
}
interface NavigationDrawerProps {
  links: LinkProps[]
  expanded: boolean
  onToggleExpansion: () => void
}

interface NavigationDrawerLinkProps extends LinkProps, UiControls {}
interface NavigationDrawerHeaderProps extends UiControls {}
interface NavigationDrawerBodyProps {
  links: LinkProps[]
  expanded: boolean
}
interface NavigationDrawerBottomProps extends UiControls {
  onToggleExpansion: () => void
}

export function NavigationDrawer({ expanded, links, onToggleExpansion }: NavigationDrawerProps) {
  return (
    <View style={{ flex: 1, width: expanded ? 280 : 60 }}>
      <NavigationDrawerHeader expanded={expanded} />
      <View style={{ backgroundColor: dsv.colors.GpJouleGelb, marginTop: 20, marginBottom: 20 }}>
        {expanded && <NavigationDrawerUserDisplay />}
      </View>
      <NavigationDrawerBody links={links} expanded={expanded} />

      {/*<img
        alt="Logo"
        src={connectAnker}
        style={{
          width: '40px',
          marginLeft: '5px',
        }}
      />*/}

      <NavigationDrawerBottom expanded={expanded} onToggleExpansion={onToggleExpansion} />
    </View>
  )
}

export function NavigationDrawerUserDisplay() {
  const { authCtx } = useAuthContext()
  const { t } = useTranslation()
  return (
    <View>
      <View
        style={{
          paddingHorizontal: 24,
          margin: 5,
          padding: 10,
          justifyContent: 'center',
        }}
      >
        <Text>
          <Text style={{ fontWeight: 'bold', color: dsv.colors.connectGrau }}>Angemeldet als: </Text>
          <br />
          {authCtx?.name}
        </Text>
        <UL>
          {authCtx?.roles.map((role) => (
            <LI key={String(role)}>
              <Text style={{ color: dsv.colors.connectGrau }}> - {t(String(role))}</Text>
            </LI>
          ))}
        </UL>
      </View>
      <View style={{ width: '100%' }}></View>
    </View>
  )
}

export function NavigationDrawerHeader({ expanded }: NavigationDrawerHeaderProps) {
  return (
    <View style={{ marginTop: 20 }}>
      <View style={{ height: 72, paddingHorizontal: 24, justifyContent: 'center', alignItems: 'center' }}>
        {/* TODO: img tag funktioniert nicht auf native */}
        <img alt="Logo" style={{ width: expanded ? 160 : 40 }} src={expanded ? logo : superSignO} />
      </View>
      <View style={{ width: '100%' }}></View>
    </View>
  )
}

export function NavigationDrawerBody({ links, expanded }: NavigationDrawerBodyProps) {
  return (
    <View style={{ flex: 1 }}>
      {links.length > 1 &&
        links.map((link) => <NavigationDrawerLink key={link.text} {...link} expanded={expanded} isRoot={true} />)}
    </View>
  )
}

export function NavigationDrawerBottom({ expanded, onToggleExpansion }: NavigationDrawerBottomProps) {
  return (
    <View style={{ minHeight: 40, paddingHorizontal: 24 }}>
      {expanded && <Text>{/*NavigationDrawerBottom*/}</Text>}
      <Icon
        name={expanded ? 'menu-unfold' : 'menu-fold'}
        size={20}
        color={dsv.colors.connectGrau7}
        onPress={onToggleExpansion}
        style={expanded ? { paddingLeft: 200 } : { paddingBottom: 60 }}
      />
    </View>
  )
}

function NavigationDrawerLink({
  text,
  target,
  icon,
  children,
  isRoot = false,
  expanded,
  disabled = false,
}: NavigationDrawerLinkProps) {
  if (typeof target === 'string') {
    return (
      <View style={{ marginLeft: !isRoot && expanded ? 24 : 0 }}>
        {/* TODO: Web and Native Element require/allow different style attributes  */}
        {disabled && (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 8,
              paddingBottom: 12,
              paddingTop: 12,
              paddingLeft: 18,
              backgroundColor: dsv.colors.neutral2,
            }}
          >
            {icon && <Icon color={dsv.colors.neutral} name={icon} size={24} />}
            {expanded && <Text style={{ color: dsv.colors.neutral }}>{text}</Text>}
          </View>
        )}
        {!disabled && (
          <Link
            disabled={disabled}
            to={target}
            style={{ textDecoration: 'none', ...dsv.text, backgroundColor: disabled ? 'red' : undefined }}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                gap: 8,
                paddingBottom: 12,
                paddingTop: 12,
                paddingLeft: 18,
              }}
            >
              {icon && <Icon color={dsv.colors.connectGrau7} name={icon} size={24} />}
              {expanded && <Text style={{ color: dsv.colors.connectGrau7 }}>{text}</Text>}
            </View>
          </Link>
        )}
        {!!children && children.map((link) => <NavigationDrawerLink expanded={expanded} key={link.text} {...link} />)}
      </View>
    )
  }

  return (
    <Pressable disabled={disabled} onPress={target} style={{ marginLeft: !isRoot && expanded ? 24 : 0 }}>
      {/* TODO: Web and Native Element require/allow different style attributes  */}
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 8,
          paddingBottom: 12,
          paddingTop: 12,
          paddingLeft: 18,
        }}
      >
        {icon && <Icon color={dsv.colors.connectGrau7} name={icon} size={24} />}
        {expanded && <Text style={{ color: dsv.colors.connectGrau7 }}>{text}</Text>}
      </View>
      {!!children && children.map((link) => <NavigationDrawerLink expanded={expanded} key={link.text} {...link} />)}
    </Pressable>
  )
}
