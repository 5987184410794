import { z } from 'zod'
import { SetStateAction, useCallback, useMemo, useState } from 'react'
import { Section, TBody, Table, THead, TR } from '@expo/html-elements'
import { useTranslation } from 'react-i18next'
import { H2, TD, TH, Text } from '../../components/TextComponents'

import { trpc } from '../../../App'
import { View } from 'react-native'
import { Card, CardBody, CardHeader } from '../../components/Card'
import { Col, Row } from '../../components/Row'
import { dsv } from '../../styles/defaults'

import { useNewAutoForm } from '../../components/form/AutoformV2'
import { RequiredFieldsForm, RequiredFieldsFormFields } from '../../forms/requiredFields'
import { addPrefix } from '../../locales/translationKeys'

import { LoadingScreen } from '../../components/LoadingScreen'

import { ProcessTypeSchema } from '@dpa/common/dist'
import { InputSelect } from '../../components/form/AutoFormInputFields/InputSelect'
import { useAutoFormV2JSXGroups } from '../../hooks/form/useAutoFormV2JSXGroups'
import { datenblattFromSchema } from '../../forms/datenblattFromSchema'
import { useAuthContext } from '../../hooks/context/useAuthContext'

const projectArten = [
  'StromschienenProjekt',
  'DCLadeparks',
  'AuslieferungInklKonfiguration',
  'ACLadeparks',
  'PVProjekte',
  'DCinklIBN',
]

export function SetRequiredFields() {
  const { t } = useTranslation()

  const { authCtx } = useAuthContext()
  const isAllowed = useMemo(() => authCtx?.roles.includes('Admin'), [authCtx?.roles])

  const [processType, setProcessType] = useState<z.infer<typeof ProcessTypeSchema>>('DCLadeparks')

  const processGates = trpc.processGate.byProcessType.useQuery({ processType: processType })

  const { mutate } = trpc.processGate.update.useMutation()

  const saveUebergabeAnProjektierung = useCallback(
    (field: string, value: string) =>
      mutate({
        field,  //TODO: fix Typing
        value,
        processType,
        processUebergabeStep: 'UebergabeAnProjektierung',
      }),
    [processType, mutate]
  )
  const saveUebergabeAnVertrieb = useCallback(
    (field: string, value: string) =>
      mutate({
        field,
        value,
        processType,
        processUebergabeStep: 'UebergabeAnVertrieb',
      }),
    [processType, mutate]
  )
  const saveUebergabeAnPMO = useCallback(
    (field: string, value: string) =>
      mutate({
        field,
        value,
        processType,
        processUebergabeStep: 'UebergabeAnPMO',
      }),
    [processType, mutate]
  )
  const saveUebergabeAnSW = useCallback(
    (field: string, value: string) =>
      mutate({
        field,
        value,
        processType,
        processUebergabeStep: 'UebergabeAnSW',
      }),
    [processType, mutate]
  )

  const { inputFieldEntries: uebergabeAnProjektierung, hasData: uebergabeAnProjektierungHasData } = useNewAutoForm(
    RequiredFieldsForm,
    RequiredFieldsFormFields,
    saveUebergabeAnProjektierung,
    processGates?.data?.UebergabeAnProjektierung,
    true
  )
  console.log('uebergabeAnProjektierung', uebergabeAnProjektierung)
  const group_uebergabeAnProjektierung = useAutoFormV2JSXGroups(uebergabeAnProjektierung, datenblattFromSchema)

  const { inputFieldEntries: uebergabeAnVertrieb, hasData: uebergabeAnVertriebHasData } = useNewAutoForm(
    RequiredFieldsForm,
    RequiredFieldsFormFields,
    saveUebergabeAnVertrieb,
    processGates?.data?.UebergabeAnVertrieb,
    true
  )
  const group_uebergabeAnVertrieb = useAutoFormV2JSXGroups(uebergabeAnVertrieb, datenblattFromSchema)

  const { inputFieldEntries: uebergabeAnPMO, hasData: uebergabeAnPMOHasData } = useNewAutoForm(
    RequiredFieldsForm,
    RequiredFieldsFormFields,
    saveUebergabeAnPMO,
    processGates?.data?.UebergabeAnPMO,
    true
  )
  const group_uebergabeAnPMO = useAutoFormV2JSXGroups(uebergabeAnPMO, datenblattFromSchema)

  const { inputFieldEntries: uebergabeAnSW, hasData: uebergabeAnSWHasData } = useNewAutoForm(
    RequiredFieldsForm,
    RequiredFieldsFormFields,
    saveUebergabeAnSW,
    processGates?.data?.UebergabeAnSW,
    true
  )
  const group_uebergabeAnSW = useAutoFormV2JSXGroups(uebergabeAnSW, datenblattFromSchema)

  return (
    <Section>
      <Row>
        <Col cols={12}>
          <Card>
            <CardHeader>
              <Text style={dsv.text}>Uebergabe Felder Festlegen</Text>
            </CardHeader>
            <CardBody customStyle={{ minHeight: 300 }}>
              <InputSelect
                options={projectArten}
                strValue={processType}
                setStrValue={setProcessType as SetStateAction<string>}
                saveStrValue={setProcessType as SetStateAction<string>}
              />
              {processGates.isLoading ||
              !(
                uebergabeAnProjektierungHasData &&
                uebergabeAnVertriebHasData &&
                uebergabeAnPMOHasData &&
                uebergabeAnSWHasData
              ) ? (
                  <LoadingScreen active />
                ) : !isAllowed ? (
                  <View>
                    <Text>Fehlende Benutzer Berechtigungen, wenden sie sich an einen Administrator</Text>
                  </View>
                ) : (
                  <View>
                    {group_uebergabeAnProjektierung.map(([groupName, groupEntries], groupIdx) => (
                      <Table
                        key={groupName}
                        style={{
                        // TODO: Fix styling for mobile app
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                          textAlign: 'left',
                          marginBottom: '20px',
                        }}
                      >
                        <THead>
                          <TR>
                            <TH style={{ width: '20%' }}>
                              <H2>{groupName}</H2>
                            </TH>
                            <TH style={{ width: '20%' }}>Übergabe an Projektierung</TH>
                            <TH style={{ width: '20%' }}>Übergabe Projektierung an Vertrieb</TH>
                            <TH style={{ width: '20%' }}>Übergabe an PMO</TH>
                            <TH style={{ width: '20%' }}>Übergabe an S&W</TH>
                          </TR>
                        </THead>
                        <TBody>
                          {groupEntries.map(([fieldName, inputField], fieldIdx) => {
                            return (
                              <TR
                                key={fieldName}
                                style={{
                                  backgroundColor: fieldIdx % 2 === 0 ? dsv.colors.base : dsv.colors.base2,
                                }}
                              >
                                <TH>{t(addPrefix('labels', fieldName))}</TH>
                                <TD>{inputField}</TD>
                                <TD>{group_uebergabeAnVertrieb[groupIdx][1][fieldIdx][1]}</TD>
                                <TD>{group_uebergabeAnPMO[groupIdx][1][fieldIdx][1]}</TD>
                                <TD>{group_uebergabeAnSW[groupIdx][1][fieldIdx][1]}</TD>
                              </TR>
                            )
                          })}
                        </TBody>
                      </Table>
                    ))}
                  </View>
                )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Section>
  )
}
