import {
  AktenIdentifier,
  GenericChangeEventWithoutPassWithRelations,
  GenericFieldChangesWithRelations,
} from '@dpa/common/dist'

import { useAkteAuditLog } from './useAkteAuditLog'
import { z } from 'zod'
import { useEffect, useMemo } from 'react'

export const CompressionDataAdditionalAttributesSchema = z.object({
  versionEnd: z.number().int().optional(),
  changeDataEnd: z.coerce.date().optional(),
})
export type CompressionDataAdditionalAttributes = z.infer<typeof CompressionDataAdditionalAttributesSchema>

export type CompressedGenericChangeEventWithoutPassWithRelations = GenericChangeEventWithoutPassWithRelations &
  CompressionDataAdditionalAttributes

function CombineFieldChanges(prev: GenericFieldChangesWithRelations[], curr: GenericFieldChangesWithRelations[]) {
  const newlyChangedFields = curr.map((change) => change.field)
  const tmp = prev.filter((change) => !newlyChangedFields.includes(change.field))
  // TODO: better combine
  return tmp.concat(curr)
}

export function useCompressedAkteAuditLog(identifier: AktenIdentifier, observedFields: Array<string>) {
  const audit = useAkteAuditLog(identifier)

  const filteredAudit = useMemo(
    () =>
      (observedFields.length > 0
        ? audit?.filter(
          (a) =>
            a.GenericFieldChanges.filter((fc) => {
              return (
                observedFields
                  .map((sf: string) => {
                    return sf
                  })
                  .includes(fc.field) ?? true
              )
            }).length > 0
        ) ?? []
        : audit ?? []
      ).sort((a, b) => (a.version > b.version ? 1 : -1)),
    [audit, observedFields]
  )

  return useMemo(
    () =>
      filteredAudit == undefined
        ? undefined
        : [...filteredAudit].reverse().reduce((carry, curr) => {
          const prev = carry[carry.length - 1]
          if (prev != undefined && prev.author.id === curr.author.id) {
            // Compress if they have the same author
            prev.GenericFieldChanges = CombineFieldChanges(prev.GenericFieldChanges, curr.GenericFieldChanges)
            prev.versionEnd = curr.version
            prev.changeDataEnd = curr.changeData
          } else {
            carry.push(curr)
          }
          return carry
        }, [] as CompressedGenericChangeEventWithoutPassWithRelations[]),
    [filteredAudit]
  )
}
