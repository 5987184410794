import { DimensionValue } from 'react-native'
import { CalcAkte } from '@dpa/common/dist'

export type SortableTableData = CalcAkte & { progress: number }

// Projektnummer, Projekt Titel, Projektart,
export const headerFields = [
  'PDB_Projektnummer',
  'UE_Kundendaten_Projekttitel',
  'PDB_Projektart',
  'PDB_Projekttyp',
  'creationData',
  'UE_Kundendaten_Ansprechpartner_Name',
  // "UE_Zeitraum_GeplantesInstallationsdatum",
  'projectStatus',
  'progress',
] satisfies Array<keyof SortableTableData> //as const

type FieldWidth = {
  min: DimensionValue
  max: DimensionValue
}

export const headerFieldWidths = {
  PDB_Projektnummer: { min: 140, max: 140 },
  UE_Kundendaten_Projekttitel: { min: 310, max: 310 },
  PDB_Projektart: { min: 220, max: 220 },
  PDB_Projekttyp: { min: 120, max: 120 },
  creationData: { min: 140, max: 140 },
  UE_Kundendaten_Ansprechpartner_Name: { min: 120, max: 180 },
  projectStatus: { min: 240, max: 240 },
  progress: { min: 120, max: 180 },
} as const
