import { Dispatch, SetStateAction, useCallback, useState } from 'react'
import { ProjectStatusSchema, ProjektArtSchema } from '@dpa/common/dist'
import { InputSelect } from '../form/InputFields/InputSelect'
import { Div } from '@expo/html-elements'
import { Ionicons as Icon } from '@expo/vector-icons'
import { dsv } from '../../styles/defaults'
import { InputText } from '../form/InputFields'
import { SortableTableData, headerFieldWidths } from './dataAndTypes'

export interface FilterIndicatorProps {
  activeFilter: Record<keyof SortableTableData, string>
  columnName: keyof SortableTableData
  setActiveFilter: Dispatch<SetStateAction<Record<keyof SortableTableData, string>>>
}

export function FilterIndicator({ activeFilter, columnName, setActiveFilter }: FilterIndicatorProps) {
  const [open, setOpen] = useState(true)

  // const toggleOpen = useCallback(() => {
  //   setOpen((old) => !old)
  // }, [])

  // let filterIcon = <Icon name="funnel-outline" color={'lightgrey'} size={16} onPress={toggleOpen} />
  // if (activeFilter != null && activeFilter[columnName] != null && activeFilter[columnName].length > 0) {
  //   filterIcon = <Icon name="funnel" color={'black'} size={16} onPress={toggleOpen} />
  // }

  let input: JSX.Element | null = <></>
  let isSelect = false

  switch (columnName) {
    case 'projectStatus':
      input = (
        <InputSelect
          dropdownStyles={{ position: 'absolute', zIndex: 99, marginTop: 40 }}
          boxStyles={{ position: 'relative', zIndex: 99, width: headerFieldWidths['projectStatus'].min - 5 }}
          inputStyles={{
            color:
              activeFilter != null && activeFilter[columnName] != null && activeFilter[columnName] != ''
                ? undefined
                : dsv.colors.connectGrau70,
            width: headerFieldWidths['projectStatus'].min - 5,
          }}
          dropdownItemStyles={{ position: 'relative', zIndex: 99, width: headerFieldWidths['projectStatus'].min - 5 }}
          dropdownTextStyles={{ position: 'relative', zIndex: 99, width: headerFieldWidths['projectStatus'].min - 5 }}
          disabledItemStyles={{}}
          disabledTextStyles={{}}
          style={{
            height: 34,
            width: '100%',
            backgroundColor: dsv.colors.connectGrau7,
            borderRadius: 0,
            borderStyle: 'solid',
            borderColor: dsv.colors.connectGrau30,
          }}
          fieldId=""
          placeholder="Filter..."
          options={['', ...Object.values(ProjectStatusSchema.Enum)]}
          strValue={activeFilter != null && activeFilter[columnName] != null ? activeFilter[columnName] : ''}
          setStrValue={(v) =>
            setActiveFilter((old) => ({
              ...old,
              [columnName]: v,
            }))
          }
          onBlur={() => setOpen(false)}
        />
      )
      isSelect = true
      break
    case 'PDB_Projekttyp':
      input = (
        <InputSelect
          dropdownStyles={{ position: 'absolute', zIndex: 99, marginTop: 40 }}
          boxStyles={{ position: 'relative', zIndex: 99, width: headerFieldWidths['PDB_Projekttyp'].min - 5 }}
          inputStyles={{
            color:
              activeFilter != null && activeFilter[columnName] != null && activeFilter[columnName] != ''
                ? undefined
                : dsv.colors.connectGrau70,
            width: headerFieldWidths['PDB_Projekttyp'].min - 5,
          }}
          dropdownItemStyles={{ position: 'relative', zIndex: 99, width: headerFieldWidths['PDB_Projekttyp'].min - 5 }}
          dropdownTextStyles={{ position: 'relative', zIndex: 99, width: headerFieldWidths['PDB_Projekttyp'].min - 5 }}
          disabledItemStyles={{}}
          disabledTextStyles={{}}
          style={{
            height: 34,
            width: '100%',
            backgroundColor: dsv.colors.connectGrau7,
            borderRadius: 0,
            borderStyle: 'solid',
            borderColor: dsv.colors.connectGrau30,
          }}
          fieldId=""
          placeholder="Filter..."
          options={['', 'A', 'B', 'C']}
          strValue={activeFilter != null && activeFilter[columnName] != null ? activeFilter[columnName] : ''}
          setStrValue={(v) =>
            setActiveFilter((old) => ({
              ...old,
              [columnName]: v,
            }))
          }
        />
      )
      isSelect = true
      break
    case 'PDB_Projektart':
      input = (
        <InputSelect
          dropdownStyles={{ position: 'absolute', zIndex: 99, marginTop: 40 }}
          boxStyles={{ position: 'relative', zIndex: 99, width: headerFieldWidths['PDB_Projektart'].min - 5 }}
          inputStyles={{
            color:
              activeFilter != null && activeFilter[columnName] != null && activeFilter[columnName] != ''
                ? undefined
                : dsv.colors.connectGrau70,
            width: headerFieldWidths['PDB_Projektart'].min - 5,
          }}
          dropdownItemStyles={{ position: 'relative', zIndex: 99, width: headerFieldWidths['PDB_Projektart'].min - 5 }}
          dropdownTextStyles={{ position: 'relative', zIndex: 99, width: headerFieldWidths['PDB_Projektart'].min - 5 }}
          disabledItemStyles={{}}
          disabledTextStyles={{}}
          style={{
            height: 34,
            width: '100%',
            backgroundColor: dsv.colors.connectGrau7,
            borderRadius: 0,
            borderStyle: 'solid',
            borderColor: dsv.colors.connectGrau30,
          }}
          fieldId=""
          placeholder="Filter..."
          options={['', ...Object.keys(ProjektArtSchema.enum)]}
          strValue={activeFilter != null && activeFilter[columnName] != null ? activeFilter[columnName] : ''}
          setStrValue={(v) =>
            setActiveFilter((old) => ({
              ...old,
              [columnName]: v,
            }))
          }
        />
      )
      isSelect = true

      break
    case 'progress':
      input = null
      break
    case 'creationData':
      input = (
        <InputText
          style={{
            borderRadius: 0,
            width: '100%',
            // TODO: Fix styling for mobile app
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            color: undefined,
            backgroundColor: dsv.colors.connectGrau7,
          }}
          fieldId=""
          placeholder="Filter..."
          strValue={activeFilter != null && activeFilter[columnName] != null ? activeFilter[columnName] : ''}
          setStrValue={(v) =>
            setActiveFilter((old) => ({
              ...old,
              [columnName]: v,
            }))
          }
        />
      )
      break
    default:
      input = (
        <InputText
          style={{
            borderRadius: 0,
            width: '100%',
            // TODO: Fix styling for mobile app
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            color: undefined,
            backgroundColor: dsv.colors.connectGrau7,
          }}
          fieldId=""
          placeholder="Filter..."
          strValue={activeFilter != null && activeFilter[columnName] != null ? activeFilter[columnName] : ''}
          setStrValue={(v) =>
            setActiveFilter((old) => ({
              ...old,
              [columnName]: v,
            }))
          }
        />
      )
  }

  return (
    <>
      <Div style={{ display: 'flex', flexDirection: 'row' }}>
        {open && (
          <Div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              marginBottom: 'auto',
            }}
          >
            {input}
            {!isSelect &&
              activeFilter != null &&
              activeFilter[columnName] != null &&
              activeFilter[columnName].length > 0 && (
              <Icon
                style={{ flexWrap: 'nowrap', marginVertical: 'auto', marginLeft: -20, zIndex: 999 }}
                name="close-circle-outline"
                color={'black'}
                size={18}
                onPress={() =>
                  setActiveFilter((old) => ({
                    ...old,
                    [columnName]: '',
                  }))
                }
              />
            )}
          </Div>
        )}
      </Div>
    </>
  )
}
