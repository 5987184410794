import React from 'react'

import { Ionicons } from '@expo/vector-icons'
import { View, StyleSheet, Image, TouchableOpacity, ScrollView, Animated, TextInput } from 'react-native'

import { Text } from './TextComponents'

import { ViewStyle, TextStyle } from 'react-native'
import { Icon } from './Icon'
import { dsv } from '../styles/defaults'

export interface SelectListProps {
  /**
   * Fn to set Selected option value which will be stored in your local state
   */
  setSelected: Function

  /**
   * Placeholder text that will be displayed in the select box
   */
  placeholder?: string

  /**
   * Additional styles for select box
   */
  boxStyles?: ViewStyle

  /**
   *  	Additional styles for text of select box
   */
  inputStyles?: TextStyle

  /**
   *  	Additional styles for dropdown scrollview
   */
  dropdownStyles?: ViewStyle

  /**
   *  Additional styles for dropdown list item
   */
  dropdownItemStyles?: ViewStyle

  /**
   * Additional styles for list items text
   */
  dropdownTextStyles?: TextStyle

  /**
   * Maximum height of the dropdown wrapper to occupy
   */
  maxHeight?: number

  /**
   * Data which will be iterated as options of select list
   */
  readonly data: Array<{ key: any; value: any }>

  disabled?: boolean

  /**
   * The default option of the select list
   */
  defaultOption?: { key: any; value: any }

  /**
   * Pass any JSX to this prop like Text, Image or Icon to show instead of search icon
   */
  searchicon?: JSX.Element

  /**
   *  Pass any JSX to this prop like Text, Image or Icon to show instead of chevron icon
   */
  arrowicon?: JSX.Element

  /**
   * set to false if you dont want to use search functionality
   */
  search?: boolean

  /**
   * set to false if you dont want to use search functionality
   */
  searchPlaceholder?: string

  /**
   * Trigger an action when option is selected
   */
  onSelect?: () => void

  /**
   * set fontFamily of whole component Text
   */
  fontFamily?: string

  /**
   * set this to change the default search failure text
   */
  notFoundText?: string

  /**
   * Additional styles for disabled list item
   */
  disabledItemStyles?: ViewStyle

  /**
   * Additional styles for disabled list items text
   */
  disabledTextStyles?: TextStyle

  /**
   * What to store inside your local state (key or value)
   */
  save?: 'key' | 'value'

  /**
   * Control the dropdown with this prop
   */
  dropdownShown?: boolean

  /**
   *  Pass any JSX to this prop like Text, Image or Icon to show instead of close icon
   */
  closeicon?: JSX.Element
}

type L1Keys = { key?: any; value?: any; disabled?: boolean | undefined }

const SelectList: React.FC<SelectListProps> = ({
  setSelected,
  placeholder,
  boxStyles,
  inputStyles,
  dropdownStyles,
  dropdownItemStyles,
  dropdownTextStyles,
  maxHeight,
  data,
  defaultOption,
  searchicon = false,
  arrowicon = false,
  closeicon = false,
  search = false,
  searchPlaceholder = 'suchen',
  notFoundText = 'No data found',
  disabledItemStyles,
  disabledTextStyles,
  onSelect = () => {},
  save = 'key',
  dropdownShown = false,
  fontFamily,
  disabled,
}) => {
  const oldOption = React.useRef(null)
  const [_firstRender, _setFirstRender] = React.useState<boolean>(true)
  const [dropdown, setDropdown] = React.useState<boolean>(dropdownShown)
  const [selectedval, setSelectedVal] = React.useState<any>(placeholder)
  const [height, setHeight] = React.useState<number>(200)
  const animatedvalue = React.useRef(new Animated.Value(0)).current
  const [filtereddata, setFilteredData] = React.useState(data)

  const slidedown = () => {
    setDropdown(true)
    Animated.timing(animatedvalue, {
      toValue: height,
      duration: 500,
      useNativeDriver: false,
    }).start()
  }
  const slideup = () => {
    Animated.timing(animatedvalue, {
      toValue: 0,
      duration: 500,
      useNativeDriver: false,
    }).start(() => setDropdown(false))
  }

  React.useEffect(() => {
    if (maxHeight) setHeight(maxHeight)
  }, [maxHeight])

  React.useEffect(() => {
    setFilteredData(data)
  }, [data])

  React.useEffect(() => {
    if (_firstRender) {
      _setFirstRender(false)
      return
    }
    onSelect()
  }, [selectedval])

  React.useEffect(() => {
    if (!_firstRender && defaultOption && oldOption.current != defaultOption.key) {
      // oldOption.current != null
      oldOption.current = defaultOption.key
      setSelected(defaultOption.key)
      setSelectedVal(defaultOption.value)
    }
    if (defaultOption && _firstRender && defaultOption.key != undefined) {
      oldOption.current = defaultOption.key
      setSelected(defaultOption.key)
      setSelectedVal(defaultOption.value)
    }
  }, [defaultOption])

  React.useEffect(() => {
    if (!_firstRender) {
      if (dropdownShown) slidedown()
      else slideup()
    }
  }, [dropdownShown])

  return (
    <View style={{ position: 'relative', zIndex: 99 }}>
      {!disabled && dropdown && search ? (
        <View style={[styles.wrapper, boxStyles]}>
          <View style={{ flexDirection: 'row', alignItems: 'center', flex: 1 }}>
            {!searchicon ? <Icon name="search1" /> : searchicon}

            <TextInput
              placeholder={searchPlaceholder}
              onChangeText={(val) => {
                const result = data.filter((item: L1Keys) => {
                  val.toLowerCase()
                  const row = item.value.toLowerCase()
                  return row.search(val.toLowerCase()) > -1
                })
                setFilteredData(result)
              }}
              style={[{ padding: 0, height: 20, flex: 1, fontFamily }, inputStyles]}
            />
            <TouchableOpacity onPress={() => slideup()}>
              {!closeicon ? <Icon name="close" /> : closeicon}
            </TouchableOpacity>
          </View>
        </View>
      ) : (
        <TouchableOpacity
          style={[styles.wrapper, boxStyles]}
          disabled={disabled}
          onPress={() => {
            if (!dropdown) {
              slidedown()
            } else {
              slideup()
            }
          }}
        >
          <Text numberOfLines={1} style={[{ fontFamily }, inputStyles]}>
            {selectedval == '' ? (placeholder ? placeholder : 'Option auswählen') : selectedval}
          </Text>
          {!arrowicon ? <Ionicons name="chevron-down" /> : arrowicon}
        </TouchableOpacity>
      )}

      {dropdown ? (
        <Animated.View
          style={[{ maxHeight: animatedvalue, position: 'relative', zIndex: 99 }, styles.dropdown, dropdownStyles]}
        >
          <ScrollView
            contentContainerStyle={{ paddingVertical: 5, overflow: 'hidden', zIndex: 99, position: 'relative' }}
            nestedScrollEnabled={true}
          >
            {filtereddata.length >= 1 ? (
              filtereddata.map((item: L1Keys, index: number) => {
                const key = item.key ?? item.value ?? item
                const value = item.value ?? item
                const disabled = item.disabled ?? false
                if (disabled) {
                  return (
                    <TouchableOpacity
                      style={[styles.disabledoption, disabledItemStyles, { minHeight: 8 }]}
                      key={`${item.value}-${index}`}
                      onPress={() => {}}
                    >
                      <Text
                        style={[
                          { backgroundColor: index % 2 === 0 ? dsv.colors.base : dsv.colors.base3, fontFamily },
                          disabledTextStyles,
                        ]}
                      >
                        {value}
                      </Text>
                    </TouchableOpacity>
                  )
                } else {
                  return (
                    <TouchableOpacity
                      style={[
                        styles.option,
                        dropdownItemStyles,
                        { backgroundColor: index % 2 === 0 ? dsv.colors.base2 : dsv.colors.base3, minHeight: 8 },
                      ]}
                      key={item.value}
                      onPress={() => {
                        if (save === 'value') {
                          setSelected(value)
                        } else {
                          setSelected(key)
                        }

                        setSelectedVal(value)
                        slideup()
                        setTimeout(() => {
                          setFilteredData(data)
                        }, 800)
                      }}
                    >
                      <Text style={[{ fontFamily, overflow: 'hidden', minHeight: 18 }, dropdownTextStyles]}>
                        {value}
                      </Text>
                    </TouchableOpacity>
                  )
                }
              })
            ) : (
              <TouchableOpacity
                style={[styles.option, dropdownItemStyles]}
                onPress={() => {
                  setSelected(undefined)
                  setSelectedVal('')
                  slideup()
                  setTimeout(() => setFilteredData(data), 800)
                }}
              >
                <Text style={[{ fontFamily }, dropdownTextStyles]}>{notFoundText}</Text>
              </TouchableOpacity>
            )}
          </ScrollView>
        </Animated.View>
      ) : null}
    </View>
  )
}

export default SelectList

const styles = StyleSheet.create({
  wrapper: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: 'gray',
    paddingHorizontal: 20,
    paddingVertical: 6,
    flexDirection: 'row',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  dropdown: {
    position: 'relative',
    borderWidth: 1,
    borderRadius: 5,
    borderColor: 'gray',
    marginTop: 5,
    overflow: 'hidden',
    backgroundColor: dsv.colors.base3,
    zIndex: 99999,
  },
  option: { paddingHorizontal: 20, paddingVertical: 4, overflow: 'hidden', zIndex: 99 },
  disabledoption: {
    paddingHorizontal: 20,
    paddingVertical: 8,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'whitesmoke',
    opacity: 0.9,
  },
})
