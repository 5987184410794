import { useMemo } from 'react'

import { InputSelect, type SelectProps } from './InputSelect'
import { useUserListContext } from '../../../hooks/context/useUserListContext'
import { RoleEnumType } from '@dpa/common/dist/prismaGenerated/inputTypeSchemas/RoleEnumSchema'

export function UserDropdownSelection({
  role,
  fieldId,
  strValue,
  setStrValue,
  saveStrValue,
}: { role?: RoleEnumType } & Omit<SelectProps, 'options'>) {
  const { allUserOptions } = useUserListContext()
  const relevantUserOptions = useMemo(() => {
    if (role === undefined || allUserOptions === undefined) {
      return allUserOptions
    }
    return allUserOptions.filter(
      (user) => user.roles === undefined || -1 !== user.roles.findIndex((userRole) => userRole == role)
    )
  }, [allUserOptions, role])

  return (
    <InputSelect
      fieldId={fieldId}
      strValue={strValue}
      optionsWithKey={relevantUserOptions}
      setStrValue={setStrValue}
      saveStrValue={saveStrValue}
    />
  )
}
