import { ActivityIndicator, View, Pressable } from 'react-native'
import { dsv } from '../../../styles/defaults'
import { Text } from '../../TextComponents'

interface LoadingButtonProps {
  title: string
  isLoading?: boolean
  disabled?: boolean
  onPress: () => void
}

export function LoadingButton({ title, isLoading = false, onPress, disabled = false }: LoadingButtonProps) {
  return (
    <Pressable disabled={disabled} onPress={onPress}>
      <View
        style={{
          padding: 8,
          borderRadius: 2,
          backgroundColor: disabled ? dsv.colors.connectGrau50 : dsv.colors.GpJouleGelb,
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Text style={{ fontSize: 14, marginRight: 8, color: dsv.colors.connectGrau }}>{title}</Text>
        {isLoading && <ActivityIndicator style={{}} size={14} color={dsv.colors.connectGrau} />}
      </View>
    </Pressable>
  )
}
