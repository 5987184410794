import { ChangeEvent, useMemo } from 'react'
import { InputProps } from '.'
import { dsv } from '../../../styles/defaults'

export function InputText({
  fieldId,
  strValue,
  setStrValue,
  onBlur,
  type,
  style,
  placeholder,
}: InputProps & {
  type?: 'password' | 'text' | 'email'
  placeholder?: string
}) {
  const onChange = useMemo(() => {
    if (setStrValue) {
      return (event: ChangeEvent<HTMLInputElement>) => setStrValue(event.target.value)
    } else {
      return undefined
    }
  }, [setStrValue])

  return (
    <input
      type={type != null ? type : 'text'}
      id={fieldId}
      value={strValue}
      disabled={onChange == undefined}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      style={{
        height: '30px',
        borderRadius: '5px',
        border: 'solid 1px',
        borderColor: 'rgb(128, 128, 128)',
        ...dsv.text,
        ...style,
      }}
    />
  )
}
