import { Dispatch, SetStateAction, useCallback } from 'react'
import { Div } from '@expo/html-elements'
import { useTranslation } from 'react-i18next'
import { Ionicons as Icon } from '@expo/vector-icons'
import { Strong } from '../TextComponents'
import { addPrefix } from '../../locales/translationKeys'
import { dsv } from '../../styles/defaults'
import { SortableTableData, headerFieldWidths, headerFields } from './dataAndTypes'
import { FilterIndicator } from './FilterIndicator'

export interface ProjectListHeadProps {
  activeFilter: Record<keyof SortableTableData, string>
  setActiveFilter: Dispatch<SetStateAction<Record<keyof SortableTableData, string>>>
  activeField: keyof SortableTableData
  sortHandlers: { [field in keyof SortableTableData]: () => void }
  sortOrder: 'ascending' | 'descending'
}
export function ProjectListHead({
  activeFilter,
  setActiveFilter,
  activeField,
  sortHandlers,
  sortOrder,
}: ProjectListHeadProps) {
  const { t } = useTranslation()

  const renderIndicator = useCallback(
    (columnName: keyof SortableTableData) => {
      if (activeField === columnName) {
        if (sortOrder === 'ascending') {
          return <Icon name="caret-down" size={18} />
        } else {
          return <Icon name="caret-up" size={18} />
        }
      } else {
        return (
          <Icon
            style={{
              // TODO: only works for web
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              visibility: 'hidden',
            }}
            name="caret-down"
            size={18}
          />
        ) //TODO Anderes Icon ?
      }
    },
    [sortOrder, activeField]
  )

  return (
    <Div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        position: 'relative',
        zIndex: 99,
        marginBottom: 2,
      }}
    >
      {headerFields.map((headerField, idx) => (
        <Div
          key={headerField}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            zIndex: 99,
            gap: 4,
            minWidth: headerFieldWidths[headerField].min,
            maxWidth: headerFieldWidths[headerField].max,
          }}
        >
          <Div
            style={{
              paddingBottom: 10,
              height: 34,
              position: 'relative',
              zIndex: 99,
              paddingHorizontal: 2,
            }}
          >
            <FilterIndicator setActiveFilter={setActiveFilter} activeFilter={activeFilter} columnName={headerField} />
          </Div>
          <Div
            style={{
              width: '100%',
              cursor: 'pointer',
              paddingVertical: 10,
              backgroundColor: dsv.colors.GpJouleGelb,
            }}
          >
            <Strong
              onPress={sortHandlers[headerField]}
              style={{
                // TODO: Fix styling for mobile app
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                cursor: 'pointer',

                fontSize: 18,
                color: dsv.colors.connectGrau,
                paddingHorizontal: 5,
              }}
            >
              {t(addPrefix('tableHeaders', headerField))} {renderIndicator(headerField)}
            </Strong>
          </Div>
        </Div>
      ))}
    </Div>
  )
}
