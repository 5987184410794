import { useMemo } from 'react'
import { Role } from '../context/useAuthContext'
import { UebergabenNames } from './UebergabenNames'

export function uebergabenToDisplay(roles: undefined | Array<Role>): Array<UebergabenNames> {
  const uebergabenToDisplay: Array<UebergabenNames> = []
  if (roles == undefined) {
    return uebergabenToDisplay
  }
  if (roles.includes('Admin') || roles.includes('Vertrieb') || roles.includes('Innendienst') || roles.includes('Projektierung')) {
    uebergabenToDisplay.push('VertriebAnEngineering')
  }
  if (roles.includes('Admin') || roles.includes('Vertrieb') || roles.includes('Innendienst') || roles.includes('Projektierung')) {
    uebergabenToDisplay.push('EngineeringAnVertrieb')
  }
  if (roles.includes('Admin') || roles.includes('Vertrieb') || roles.includes('Innendienst') || roles.includes('PMO')) {
    uebergabenToDisplay.push('VertriebAnPMO')
  }
  if (roles.includes('Admin') || roles.includes('PMO') || roles.includes('Service & Wartung') || roles.includes('Projekt Support')) {
    uebergabenToDisplay.push('PMOAnS&W')
  }
  return uebergabenToDisplay
}

export function useUebergabenToDisplay(roles: undefined | Array<Role>): Array<UebergabenNames> {
  return useMemo(() => uebergabenToDisplay(roles), [roles])
}
