import { View, ScrollView } from 'react-native'
import { Card, CardBody, CardHeader } from '../../components/Card'
import { trpc } from '../../../App'
import { ProjectList } from '../../components/ProjectList'

export function ProjectsHome() {
  const allProjects = trpc.akte.getAll.useQuery({})

  return (
    <View>
      <Card customStyle={{ margin: 10, width: 1576 }}>
        <CardHeader>Alle Projekte</CardHeader>
        <CardBody>
          <ScrollView style={{ minHeight: 300, maxHeight: '100%' }}>
            <ProjectList projects={allProjects} noDataPlaceholder="Keine Projekte" />
          </ScrollView>
        </CardBody>
      </Card>
    </View>
  )
}
