import { useAkteContext } from '../../../../hooks/context/useAkteContext'
import { useTranslation } from 'react-i18next'

import { textWithPlaceHolder } from '../../../../utils/text'

import { SubCard } from '../../../../components/SubCard'
import { InfoItem } from './InfoItem'

export function SubCardDetails() {
  const akte = useAkteContext()
  const { t } = useTranslation()

  const isLoading = akte.isLoading
  if (isLoading) {
    return <></>
  }
  return (
    <SubCard width="32%" title="Details">
      <InfoItem name="Projektnummer" value={textWithPlaceHolder(akte.serverState?.PDB_Projektnummer)} />
      <InfoItem
        name="Projekttitel"
        value={textWithPlaceHolder(akte.serverState?.UE_Kundendaten_Projekttitel)}
        darkerLine={true}
      />
      <InfoItem name="Projekttyp" value={textWithPlaceHolder(akte.serverState?.PDB_Projekttyp)} />
      <InfoItem name="Projektart" value={textWithPlaceHolder(akte.serverState?.PDB_Projektart, t)} darkerLine={true} />
      <InfoItem
        name="Erstellungsdatum"
        value={textWithPlaceHolder(akte.serverState?.creationData?.toLocaleDateString(), t)}
      />
      <InfoItem name="Marge" value={textWithPlaceHolder('', t)} darkerLine={true} />
      {/* TODO: missing */}
      <InfoItem name="Gesamtumsatz" value={textWithPlaceHolder('', t)} />
      {/* TODO: missing */}
    </SubCard>
  )
}
