import { useState, useCallback, ChangeEvent } from 'react'
import { View, Button } from 'react-native'

interface RejectionDialogProps {
  uebergabeReject: (reason: string) => void
  placeholder: string
  buttonText: string
}

export function RejectionDialog({ uebergabeReject, placeholder, buttonText }: RejectionDialogProps) {
  const [rejectionText, setRejectionText] = useState('')

  const rejectionTextChangeHandler = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setRejectionText(event.target.value)
    },
    [setRejectionText]
  )

  const reject = useCallback(() => uebergabeReject(rejectionText), [uebergabeReject, rejectionText])

  const rejectable = rejectionText.length > 0

  return (
    <View style={{ marginLeft: 'auto', width: 335 }}>
      <textarea
        style={{ resize: 'vertical' }}
        rows={5}
        onChange={rejectionTextChangeHandler}
        placeholder={placeholder}
      ></textarea>
      <View style={{ margin: 5, paddingBottom: 5 }}>
        <Button disabled={!rejectable} color={'rgb(180, 33, 33)'} title={buttonText} onPress={reject} />
      </View>
    </View>
  )
}
