import { PropsWithChildren, useCallback, useState } from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { dsv } from '../styles/defaults'
import { Icon } from '../components/Icon'

export interface CollapsibleProps {
  summary?: JSX.Element
  defaultOpen?: true
  customStyle?: StyleProp<ViewStyle>
}

export function Collapsible({ summary, defaultOpen, children, customStyle }: PropsWithChildren<CollapsibleProps>) {
  const [expanded, setExpanded] = useState(!!defaultOpen)

  const handleOnClick = useCallback(() => {
    setExpanded(!expanded)
  }, [setExpanded, expanded])

  return (
    <details open={defaultOpen} style={{ marginBottom: 8, backgroundColor: dsv.colors.connectGrau7 }}>
      <summary style={{ display: 'inline' }} onClick={handleOnClick}>
        <View
          style={{
            flexDirection: 'row',
            backgroundColor: dsv.colors.base2,
            alignItems: 'center',
            cursor: 'pointer',
            padding: 4,
            borderColor: dsv.colors.GpJouleGelb,
            borderStyle: 'solid',
            borderBottomWidth: 5,
            marginHorizontal: 10,
            ...customStyle,
          }}
        >
          <Icon
            name={expanded ? 'down' : 'right'}
            size={20}
            color={dsv.colors.neutral}
            style={{ marginLeft: 8, marginRight: 12 }}
          />
          {summary}
        </View>
      </summary>
      <View
        style={{
          marginHorizontal: 20,
          paddingBottom: 20,
        }}
      >
        {children}
      </View>
    </details>
  )
}
