import { Route, Routes } from '../utils/routing'

import { PDBLayout } from '../pages/pdb/pdb.layout'
import { Mitarbeiter } from '../pages/pdb/Mitarbeiter'
import { CreateProject } from '../pages/pdb/CreateProject'
import { SetRequiredFields } from '../pages/pdb/SetRequiredFields'

import { ProjectsLayout } from '../pages/pdb/projects/Project.layout'
import { ProjectsHome } from '../pages/pdb/ProjectsHome'
import { ProjectsProjectDatenblatt } from '../pages/pdb/projects/ProjectDatenblatt'

import { ErrorLayout } from '../pages/error/Error.layout'
import { GeneralError } from '../pages/error/GeneralError'
import { Login } from '../pages/auth/Login'
import { ProjectOverview } from '../pages/pdb/projects/ProjectOverview'
import { ProjectUebergabeProjektierung } from '../pages/pdb/projects/ProjectUebergabeProjektierung'
import { ProjectUebergabeVertrieb } from '../pages/pdb/projects/ProjectUebergabeVertrieb'
import { ProjectUebergabePMO } from '../pages/pdb/projects/ProjectUebergabePMO'
import { ProjectUebergabeSW } from '../pages/pdb/projects/ProjectUebergabeSW'
import { MyProjects } from '../pages/pdb/MyProjects'
import { ProjectProducts } from '../pages/pdb/projects/ProjectProducts'
import { ProjectsProjectConfigOrder } from '../pages/pdb/projects/orders/ProjectsProjectConfigOrder'
import { ProjectsProjectBackendOrder } from '../pages/pdb/projects/orders/ProjectsProjectBackendOrder'

export function Router() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PDBLayout />}>
          <Route index element={<MyProjects />} />
          <Route path="myProjects" element={<MyProjects />} />
          <Route path="createProject" element={<CreateProject />} />
          <Route path="requiredFields" element={<SetRequiredFields />} />

          <Route path="projects">
            <Route index element={<ProjectsHome />} />
            <Route path=":projectId" element={<ProjectsLayout />}>
              <Route index element={<ProjectOverview />} />
              <Route path="state" element={<ProjectOverview />} />
              <Route path="products" element={<ProjectProducts />} />
              <Route path="uebergabeProjektierung" element={<ProjectUebergabeProjektierung />} />
              <Route path="uebergabeVertrieb" element={<ProjectUebergabeVertrieb />} />
              <Route path="uebergabePMO" element={<ProjectUebergabePMO />} />
              <Route path="uebergabeSW" element={<ProjectUebergabeSW />} />

              <Route path="orderBackend" element={<ProjectsProjectBackendOrder />} />
              <Route path="orderConfig" element={<ProjectsProjectConfigOrder />} />
              <Route path="datasheet" element={<ProjectsProjectDatenblatt />} />
            </Route>
          </Route>
          <Route path="mitarbeiter" element={<Mitarbeiter />} />
        </Route>
        <Route element={<ErrorLayout />}>
          <Route path="*" element={<GeneralError />} />
        </Route>
      </Routes>
    </>
  )
}
