import { PointerEvent } from 'react-native'
import { useNavigate } from 'react-router-dom'
import { CalcAkteSchema, ProjectStatusSchema } from '@dpa/common/dist'
import { z } from 'zod'
import { Div } from '@expo/html-elements'
import { H2 } from '../TextComponents'
import { dsv } from '../../styles/defaults'
import { LoadingScreen } from '../LoadingScreen'
import { trpc } from '../../../App'
import { SortableTableData, headerFields } from './dataAndTypes'
import { ProjectListHead } from './ProjectListHead'
import { useCallback, useMemo, useState } from 'react'
import { ProjectListRow } from './ProjectListRow'

//TODO: change from row column orientation for dynamic field width
interface ProjectListProps {
  projects: Pick<ReturnType<typeof trpc.akte.getAll.useQuery>, 'isLoading' | 'data'>
  noDataPlaceholder: string
}
export function ProjectList({ projects, noDataPlaceholder }: ProjectListProps) {
  const [activeFilter, setActiveFilter] = useState<Record<keyof SortableTableData, string>>({})

  const filteredAllProjects: Array<SortableTableData> | undefined = useMemo(() => {
    const idSet = new Set()
    return projects.data
      ?.map((p) => CalcAkteSchema.parse(p))
      .filter((p: { [x: string]: any; creationData: { toLocaleDateString: () => string }; id: unknown }) => {
        const hf = headerFields.filter((headerField) => {
          switch (headerField) {
            case 'creationData':
              return (
                activeFilter == null ||
                activeFilter[headerField] == null ||
                p.creationData
                  ?.toLocaleDateString()
                  .toLowerCase()
                  .includes(activeFilter[headerField]?.toLowerCase())
              )
            default:
              return (
                activeFilter == null ||
                activeFilter[headerField] == null ||
                String(p[headerField])
                  .toLowerCase()
                  .includes(activeFilter[headerField]?.toLowerCase())
              )
          }
        })
        if (idSet.has(p.id)) {
          return false
        }

        idSet.add(p.id)
        return hf.length === headerFields.length
      })
  }, [projects, activeFilter])

  const isFilterActive = filteredAllProjects?.length != projects.data?.length

  const statusToPercent = useCallback((status: z.infer<typeof ProjectStatusSchema> | null | undefined) => {
    switch (status) {
      case 'In_der_Erfassung':
        return 0
      case 'Erfassung_abgeschlossen':
        return 25
      case 'PueT_Uebergabe_geplant':
        return 50
      case 'PueT_Uebergabe_erfolgt':
        return 75
      default:
        return 100
    }
  }, [])

  const data = useMemo(
    () =>
      filteredAllProjects?.map((d) => {
        return {
          ...d,
          progress: statusToPercent(d['projectStatus']),
        }
      }),
    [filteredAllProjects, statusToPercent]
  )

  const navigate = useNavigate()

  const navigateToProject = useCallback(
    (akteId: number) => (_: PointerEvent) => navigate(`/projects/${akteId}`),
    [navigate]
  )

  const [activeField, setActiveField] = useState<keyof SortableTableData>('PDB_Projektnummer')
  const [sortOrder, setSortOrder] = useState<'ascending' | 'descending'>('descending')

  const handleSort = useCallback(
    (columnName: keyof SortableTableData) => {
      setSortOrder((previousSortOrder) =>
        activeField === columnName ? (previousSortOrder === 'ascending' ? 'descending' : 'ascending') : 'descending'
      )
      setActiveField(columnName)
    },
    [activeField, setSortOrder, setActiveField]
  )

  const sortedData = useMemo(() => {
    return [...(data ?? [])].sort((a, b) => {
      let sort: number

      const valA = a[activeField]
      const valB = b[activeField]

      if (valA == valB) {
        sort = 0
      } else if (valA == undefined) {
        sort = -1
      } else if (valB == undefined) {
        sort = 1
      } else {
        sort = valA > valB ? 1 : -1
      }

      return sortOrder === 'ascending' ? sort : -sort
    })
  }, [data, activeField, sortOrder])

  const sortHandlers = useMemo(() => {
    const handlerEntries = headerFields.map((headerField: keyof SortableTableData) => [
      headerField,
      () => {
        handleSort(headerField)
      },
    ])

    return Object.fromEntries(handlerEntries) as { [field in keyof SortableTableData]: () => void }
  }, [handleSort])

  return (
    <Div
      style={{
        position: 'relative',
        zIndex: 0,
      }}
    >
      <Div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 1530,
          position: 'relative',
          zIndex: 0,
        }}
      >
        <ProjectListHead
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
          activeField={activeField}
          sortHandlers={sortHandlers}
          sortOrder={sortOrder}
        />
        <Div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            gap: 1,
            position: 'relative',
            zIndex: 0,
          }}
        >
          {!projects.isLoading &&
            sortedData.length >= 0 &&
            sortedData.map((data, rowIdx) => (
              <ProjectListRow key={data.id} data={data} rowIdx={rowIdx} navigateToProject={navigateToProject} />
            ))}
        </Div>
        {!projects.isLoading && sortedData.length == 0 && (
          <Div
            style={{
              height: 155,
              width: 1530,
              backgroundColor: dsv.colors.connectGrau7,
              position: 'relative',
              zIndex: 0,
            }}
          >
            <H2
              style={{
                margin: 'auto',
                position: 'relative',
                zIndex: 0,
              }}
            >
              {noDataPlaceholder}
              {isFilterActive ? ' gefunden' : ''}
            </H2>
          </Div>
        )}
      </Div>
      {projects.isLoading && (
        <Div style={{ height: 155 }}>
          <LoadingScreen active />
        </Div>
      )}
    </Div>
  )
}
