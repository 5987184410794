import { TBody, Table, THead, TR, Div } from '@expo/html-elements'
import { H4, TD, TH } from '../../../../components/TextComponents'

import { dsv } from '../../../../styles/defaults'

import { SubCard } from '../../../../components/SubCard'
import type { SalesLine } from '@dpa/server/integations/bc/salesLine'
import { toCurrencyString } from '../../../../utils/currency'



interface ProductsCardProps{
  title: string
  salesLines: Array<SalesLine>
}

export function ProductsCard({title, salesLines}: ProductsCardProps) {
  return (
    <SubCard width="100%" title={title}>
      <Table
        style={{
          // TODO: Fix styling for mobile app
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          borderSpacing: '0px 6px',
        }}
      >
        <THead style={{ backgroundColor: dsv.colors.GpJouleGelb }}>
          <TR>
            <TH style={{ padding: 10, textAlign: 'left' }}>Artikelnummer</TH>
            <TH style={{ padding: 10, textAlign: 'left' }}>Artikelbezeichnung</TH>
            <TH style={{ padding: 10, textAlign: 'center' }}>Menge</TH>
            {/*<TH style={{ padding: 10, textAlign: 'center' }}>Stückpreis</TH>
                            <TH style={{ padding: 10, textAlign: 'center' }}>Rabatt</TH>
                            //TODO: Rechnung stylen und anpassen
                            */}
            <TH style={{ padding: 10, textAlign: 'center' }}>Zeilenbetrag</TH>
          </TR>
        </THead>
        <TBody>
          {salesLines.map((line) => (
            <TR key={line.no_}>
              <TD style={{ paddingLeft: 10 }}>{line.no_}</TD>
              <TD>{line.description}</TD>
              <TD style={{ textAlign: 'center' }}>
                {line.quantity == 0 ? (
                  ''
                ) : (
                  <>
                    {line.quantity}&nbsp;{line.unitofmeasure}
                  </>
                )}
              </TD>
              {/*<TD style={{ textAlign: 'right' }}>
                                {line.quantity == 0 ? '' : toCurrencyString(line.lineamount ?? 0)}
                              </TD>
                              <TD style={{ textAlign: 'right' }}>{line.quantity == 0 ? '' : '0%'}</TD>*/}
              <TD style={{ textAlign: 'right', paddingRight: 10 }}>
                {line.quantity == 0 ? '' : toCurrencyString(line.lineamount ?? 0)}
              </TD>
            </TR>
          ))}
          <TR>
            <TD style={{ height: 5 }}></TD>
          </TR>
        </TBody>
      </Table>
      <Div style={{ marginBottom: 2, height: 2, backgroundColor: dsv.colors.connectGrau }}></Div>
      <Div style={{ marginLeft: 'auto' }}>
        <table style={{ borderSpacing: '10px 5px' }}>
          <tr>
            <td>Zwischensumme</td>
            <td style={{ textAlign: 'right' }}>
              {toCurrencyString(
                salesLines.reduce((carry, line) => {
                  return carry + (line.lineamount ?? 0)
                }, 0 as number)
              )}
            </td>
          </tr>
          <tr>
            <td></td>
            <td style={{ textAlign: 'right' }}>{'' /* //TODO: NO DUMMY DATA */}</td>
          </tr>
          <tr>
            <td>
              <H4 style={{ marginTop: 5 }}>Rechnungsbetrag</H4>
            </td>
            <td style={{ textAlign: 'right' }}>
              <H4>
                {toCurrencyString(
                  /* //TODO: NO DUMMY DATA */
                  salesLines.reduce((carry, line) => {
                    return carry + (line.lineamount ?? 0)
                  }, 0 as number)
                )}
              </H4>
            </td>
          </tr>
        </table>
        <Div style={{ marginBottom: 2, height: 2, backgroundColor: dsv.colors.connectGrau }}></Div>
        <Div style={{ marginBottom: 2, height: 2, backgroundColor: dsv.colors.connectGrau }}></Div>
      </Div>
    </SubCard>
                 
  )
}
