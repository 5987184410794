import {
  useContext,
  createContext,
  PropsWithChildren,
  useState,
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
} from 'react'

import { trpc } from '../../../App'
import { CalcAkte, CalcAkteSchema, AktenIdentifier } from '@dpa/common/dist'
import { objDelta, useObjDelta } from '../useObjDelta'

export type ServerStateCtx<TData> =
  | {
      identifier: AktenIdentifier
      isLoading: true
      serverState?: TData
      localState?: TData
      setLocalState?: Dispatch<SetStateAction<TData>> // TODO: Maybe only allow setting of non calculated fields ?
      localDiverges?: undefined
      localDivergences?: { [key in keyof TData]?: boolean }
      isWatched?: boolean
    }
  | {
      identifier: AktenIdentifier
      isLoading: false
      serverState: TData
      localState: TData
      setLocalState: Dispatch<SetStateAction<TData>>
      localDiverges: boolean
      localDivergences: { [key in keyof TData]?: boolean }
      isWatched: boolean
      toggleWatched: () => void
    }

export type AkteCtx = ServerStateCtx<CalcAkte>

const AkteContext = createContext<AkteCtx | null>(null)

export interface AkteContextProviderProps {
  id: number
}

export function AkteContextProvider({ id, children }: PropsWithChildren<AkteContextProviderProps>) {
  const trpcCtx = trpc.useContext()
  const project = trpc.akte.byId.useQuery({ id })
  const isWatched = trpc.watchedProjects.isWatched.useQuery({ akteId: id })
  const watch = trpc.watchedProjects.watch.useMutation({
    onSuccess: () => {
      trpcCtx.watchedProjects.isWatched.invalidate({ akteId: id })
    },
  })
  const unWatch = trpc.watchedProjects.unWatch.useMutation({
    onSuccess: () => {
      trpcCtx.watchedProjects.isWatched.invalidate({ akteId: id })
    },
  })

  const [localState, setLocalState] = useState<CalcAkte | undefined>(undefined)

  if (project.error?.data?.code === 'NOT_FOUND' || (!project.isLoading && project.data === undefined)) {
    throw new Error('Akte Not found') // TODO: error Boundary
  }

  const serverState = useMemo(() => {
    if (project.data === undefined) {
      return undefined
    }
    return CalcAkteSchema.parse(project.data)
  }, [project.data])

  useEffect(() => {
    if (serverState !== undefined) {
      //const delta = objDelta(serverState, localState)
      //if (Object.values(delta).some((isDifferent) => isDifferent)) {
      if (localState == undefined) {
        setLocalState(serverState) // TODO: when to update local State with Server State ?
      }
    }
  }, [localState, serverState])

  const localDivergences = useObjDelta(serverState, localState)
  const localDiverges = useMemo(
    () => Object.values(localDivergences).reduce((p, c) => p || c, false),
    [localDivergences]
  )

  const ctxValue: AkteCtx = useMemo(() => {
    if (isWatched.isLoading || project.isLoading || serverState == undefined || localState == undefined) {
      return {
        identifier: { id },
        isLoading: true,
        localState,
        serverState,
        setLocalState: localState === undefined ? undefined : (setLocalState as Dispatch<SetStateAction<CalcAkte>>),
        isWatched: isWatched.data?.watched,
      }
    } else {
      return {
        identifier: { id },
        isLoading: false,
        serverState,
        localState: localState,
        setLocalState: setLocalState as Dispatch<SetStateAction<CalcAkte>>,
        localDiverges,
        localDivergences,
        isWatched: isWatched.data!.watched,
        toggleWatched: isWatched.data!.watched
          ? () => unWatch.mutate({ akteId: id })
          : () => watch.mutate({ akteId: id }),
      }
    }
  }, [
    id,
    localDivergences,
    localDiverges,
    localState,
    project.isLoading,
    serverState,
    isWatched.isLoading,
    isWatched.data,
    watch,
    unWatch,
  ])

  return <AkteContext.Provider value={ctxValue}>{children}</AkteContext.Provider>
}

export function useMaybeAkteContext() {
  return useContext(AkteContext)
}

export function useAkteContext() {
  const ctx = useContext(AkteContext)
  if (ctx == null) {
    throw new Error('usage of useAkteContext outside of a AkteContextProvider')
  }
  return ctx
}
