import { useMemo } from 'react'
import { FormGroupDefinition } from '../../forms/formGroupDefinition'

function pickObjEntries<TObj extends object>(obj: TObj, keys: Array<keyof TObj>) {
  return keys.map((key) => [key, obj[key]]) as [keyof TObj, TObj[keyof TObj]][] // TODO: this is a bit dirty
}

export function useAutoFormV2JSXGroups<TFormFieldNames extends string, TGroupNames extends string>(
  jsxEntries: [TFormFieldNames, JSX.Element][],
  groups: FormGroupDefinition<TGroupNames, TFormFieldNames>
) {
  return useMemo(() => {
    const jsxObj = Object.fromEntries(jsxEntries)
    const groupNames = Object.keys(groups) as TGroupNames[]
    const groupEntries = groupNames.map((groupName) => {
      return [groupName, pickObjEntries(jsxObj, groups[groupName].fields)] as [
        TGroupNames,
        [TFormFieldNames, JSX.Element][],
      ]
    })
    return groupEntries
  }, [jsxEntries, groups])
}
