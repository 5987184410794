import { useAkteContext } from '../../../../hooks/context/useAkteContext'

import { userWithPlaceHolder } from '../../../../utils/text'

import { SubCard } from '../../../../components/SubCard'
import { InfoItem } from './InfoItem'
import { useUserListContext } from '../../../../hooks/context/useUserListContext'

export function SubCardAnsprechpartner() {
  const akte = useAkteContext()

  const { isLoading: usersIsLoading, userById } = useUserListContext()

  const isLoading = akte.isLoading || usersIsLoading
  if (isLoading) {
    return <></>
  }
  return (
    <SubCard width="32%" title="Interne Ansprechpersonen">
      <InfoItem name={'Engineering'} value={userWithPlaceHolder(akte.serverState?.PDB_ProjektiererId, userById)} />
      <InfoItem
        name={'Vertriebsaußendienst'}
        value={userWithPlaceHolder(akte.serverState?.PDB_VertriebsmitarbeiterId, userById)}
        darkerLine={true}
      />
      <InfoItem
        name={'Vertriebsinnendienst'}
        value={userWithPlaceHolder(akte.serverState?.PDB_InnendienstmitarbeiterId, userById)}
        darkerLine={true}
      />
      <InfoItem
        name={'Projekt Support'}
        value={userWithPlaceHolder(akte.serverState?.PDB_Ansprechpartner_ProjektsupportId, userById)}
      />
      <InfoItem name={'TBF'} value={userWithPlaceHolder(akte.serverState?.PDB_Ansprechpartner_TBFId, userById)} />
      <InfoItem name={'PMO'} value={userWithPlaceHolder(akte.serverState?.PDB_Projektleiter_PMOId, userById)} />
    </SubCard>
  )
}
