import { ChangeEvent, useMemo, useRef } from 'react'
import { InputProps } from '.'
import { dsv } from '../../../styles/defaults'

export function InputNumber({ fieldId, strValue, setStrValue, onBlur, style }: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null)

  const onChange = useMemo(() => {
    if (setStrValue) {
      return (event: ChangeEvent<HTMLInputElement>) => {
        inputRef.current?.focus() // This is needed, because (in firefox) the up and down buttons do not focus
        setStrValue(event.target.value)
      }
    } else {
      return undefined
    }
  }, [setStrValue, inputRef])

  return (
    <input
      type="number"
      ref={inputRef}
      id={fieldId}
      value={strValue}
      disabled={onChange == undefined}
      onChange={onChange}
      style={{ height: 24, ...dsv.text, ...style }}
      onBlur={onBlur}
    />
  )
}
