import { StrictMode, useEffect, useMemo } from 'react';
import { useState } from 'react';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter } from 'react-router-dom';

import { Router } from './src/routes';

import "./src/i18n"
import ExpoStatusBar from 'expo-status-bar/build/ExpoStatusBar';
import { AuthContextProvider, useAuthContext } from './src/hooks/context/useAuthContext';
import { httpBatchLink } from '@trpc/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { TrpcAppRouter } from '@dpa/server/trpc/trpcRoutes';
import { createTRPCReact } from '@trpc/react-query';
import { useFonts } from './src/utils/text';

export const trpc = createTRPCReact<TrpcAppRouter>();

export function apiPath() {
  const url = new URL(window.location.href)
  const apiURL = `${url.protocol}//${url.host}/api/trpc`
  return apiURL
}

function AuthenticatedApp() {
  const { getAccessToken, clearAuthCtx } = useAuthContext()
  const [queryClient] = useState(() => new QueryClient())

  const client = useMemo(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          url: apiPath(),
          // You can pass any HTTP headers you wish here
          async headers() {
            return {
              authorization: `bearer ${await getAccessToken().then(t => {
                if (t == null) {
                  clearAuthCtx()
                }

                return t
              })}`,
            };
          },
        }),
      ],
    })
  , [getAccessToken]);

  return (
    <trpc.Provider client={client} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <Router />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </trpc.Provider>
  )
}

export default function App() {

  const [_, err] = useFonts()
  useEffect(() => {
    if (err != null) {
      console.error(err)
    }
  }, [err])

  return (
    <StrictMode>
      <ExpoStatusBar style="auto" />
      <BrowserRouter>
        <AuthContextProvider>
          <AuthenticatedApp />
        </AuthContextProvider>
      </BrowserRouter>
    </StrictMode>
  );
}


