import { View } from 'react-native'

import { Section, Div } from '@expo/html-elements'
import { Card, CardBody } from '../../../../components/Card'
import { H2 } from '../../../../components/TextComponents'

import { useAkteContext } from '../../../../hooks/context/useAkteContext'
import { LoadingScreen } from '../../../../components/LoadingScreen'

import { dsv } from '../../../../styles/defaults'
import { useAllPermissions } from '../../../../hooks/useAllPermissions'
import { useUserListContext } from '../../../../hooks/context/useUserListContext'
import { useProcessGateContext } from '../../../../hooks/context/useProcessGateContext'

import { useAkteSalesLines } from '../../../../hooks/useAkteSalesLines'
import { ProjectCardHeader } from '../components/ProjectCardHeader'
import { ProductsCard } from './ProductsCard'
import { useMemo } from 'react'

export function ProjectProducts() {
  const akte = useAkteContext()
  const { isLoading: usersIsLoading } = useUserListContext()

  const salesLines = useAkteSalesLines(akte.identifier)

  const allPermissions = useAllPermissions()

  const processGateCtx = useProcessGateContext()

  const salesLinesPerType = useMemo(() => ({
    PDB_Auftragsnummer: 
      salesLines.data?.filter(line => line.orderType == "PDB_Auftragsnummer") ?? [],
    PDB_Auftragsnummer_PC: 
      salesLines.data?.filter(line => line.orderType == "PDB_Auftragsnummer_PC") ?? [],
    PDB_Auftragsnummer_Nachtrag0: 
      salesLines.data?.filter(line => line.orderType == "PDB_Auftragsnummer_Nachtrag0") ?? [],
    PDB_Auftragsnummer_Nachtrag1: 
      salesLines.data?.filter(line => line.orderType == "PDB_Auftragsnummer_Nachtrag1") ?? [],
    PDB_Auftragsnummer_Nachtrag2: 
      salesLines.data?.filter(line => line.orderType == "PDB_Auftragsnummer_Nachtrag2") ?? [],
    PDB_Auftragsnummer_Nachtrag3: 
      salesLines.data?.filter(line => line.orderType == "PDB_Auftragsnummer_Nachtrag3") ?? [],
    PDB_Auftragsnummer_Nachtrag4: 
      salesLines.data?.filter(line => line.orderType == "PDB_Auftragsnummer_Nachtrag4") ?? [],
    PDB_Auftragsnummer_laufende_Kosten: 
      salesLines.data?.filter(line => line.orderType == "PDB_Auftragsnummer_laufende_Kosten") ?? [],
  }),[salesLines.data])

  const isLoading =
    akte.isLoading || allPermissions.isLoading || processGateCtx.isLoading || salesLines.isLoading || usersIsLoading

  return (
    <Section style={{ flex: 1 }}>
      <Card customStyle={{ flex: 1 }}>
        {isLoading && (
          <CardBody customStyle={{ minHeight: 300 }}>
            <LoadingScreen active />
          </CardBody>
        )}
        {!isLoading && (
          <>
            <ProjectCardHeader />
            <CardBody customStyle={{ flex: 1, overflow: 'scroll' }}>
              <View>
                <View
                  style={{
                    paddingTop: 10,
                    width: '100%',
                    maxWidth: 1480,
                    margin: 'auto',
                  }}
                >
                  {akte.serverState.PDB_Projektnummer == undefined ? (
                    <Div style={{ height: 100, backgroundColor: dsv.colors.connectGrau7 }}>
                      <H2
                        style={{
                          margin: 'auto',
                          color: dsv.colors.black,
                        }}
                      >
                        Produkte aus BC, bitte Projektnummer und Auftragsnummer hinterlegen
                      </H2>
                    </Div>
                  ) : (
                    <>
                      {salesLinesPerType.PDB_Auftragsnummer.length > 0 && 
                        <View style={{marginTop:10}}>
                          <ProductsCard
                            title={
                              `Produkte aus ${akte.serverState.PDB_Auftragsnummer} - Einmalkosten`
                            }
                            salesLines={salesLinesPerType.PDB_Auftragsnummer} 
                          />
                        </View>
                      }
                      {salesLinesPerType.PDB_Auftragsnummer_PC.length > 0 && 
                        <View style={{marginTop:10}}>
                          <ProductsCard
                            title={
                              `Produkte aus ${akte.serverState.PDB_Auftragsnummer_PC} - Plan & Charge`
                            }
                            salesLines={salesLinesPerType.PDB_Auftragsnummer_PC} 
                          />
                        </View>
                      }
                      {salesLinesPerType.PDB_Auftragsnummer_Nachtrag0.length > 0 && 
                        <View style={{marginTop:10}}>
                          <ProductsCard
                            title={
                              `Produkte aus ${akte.serverState.PDB_Auftragsnummer_Nachtrag0} - Nachtrag I`
                            }
                            salesLines={salesLinesPerType.PDB_Auftragsnummer_Nachtrag0} 
                          />
                        </View>
                      }
                      {salesLinesPerType.PDB_Auftragsnummer_Nachtrag1.length > 0 && 
                        <View style={{marginTop:10}}>
                          <ProductsCard
                            title={
                              `Produkte aus ${akte.serverState.PDB_Auftragsnummer_Nachtrag1} - Nachtrag II`
                            }
                            salesLines={salesLinesPerType.PDB_Auftragsnummer_Nachtrag1} 
                          />
                        </View>
                      }
                      {salesLinesPerType.PDB_Auftragsnummer_Nachtrag2.length > 0 && 
                        <View style={{marginTop:10}}>
                          <ProductsCard
                            title={
                              `Produkte aus ${akte.serverState.PDB_Auftragsnummer_Nachtrag2} - Nachtrag III`
                            }
                            salesLines={salesLinesPerType.PDB_Auftragsnummer_Nachtrag2} 
                          />
                        </View>
                      }
                      {salesLinesPerType.PDB_Auftragsnummer_Nachtrag3.length > 0 && 
                        <View style={{marginTop:10}}>
                          <ProductsCard
                            title={
                              `Produkte aus ${akte.serverState.PDB_Auftragsnummer_Nachtrag3} - Nachtrag IV`
                            }
                            salesLines={salesLinesPerType.PDB_Auftragsnummer_Nachtrag3} 
                          />
                        </View>
                      }
                      {salesLinesPerType.PDB_Auftragsnummer_Nachtrag4.length > 0 && 
                        <View style={{marginTop:10}}>
                          <ProductsCard
                            title={
                              `Produkte aus ${akte.serverState.PDB_Auftragsnummer_Nachtrag4} - Nachtrag V`
                            }
                            salesLines={salesLinesPerType.PDB_Auftragsnummer_Nachtrag4} 
                          />
                        </View>
                      }
                      {salesLinesPerType.PDB_Auftragsnummer_laufende_Kosten.length > 0 && 
                        <View style={{marginTop:10}}>
                          <ProductsCard
                            title={
                              `Produkte aus ${akte.serverState.PDB_Auftragsnummer_laufende_Kosten} - laufende Kosten`
                            }
                            salesLines={salesLinesPerType.PDB_Auftragsnummer_laufende_Kosten} 
                          />
                        </View>
                      }
                    </>
                  )}
                </View>
              </View>
            </CardBody>
          </>
        )}
      </Card>
    </Section>
  )
}
