import { useCallback, useMemo, useState } from 'react'
import { Akte, CalcAkte } from '@dpa/common/dist'

import { Div, Section } from '@expo/html-elements'
import { Card, CardBody, CardHeader } from '../../../components/Card'

import { useAkteContext } from '../../../hooks/context/useAkteContext'
import { useDatenblattForms } from '../../../hooks/form/useDatenblattForms'
import { LoadingScreen } from '../../../components/LoadingScreen'
import { useUebergabeForms } from './utils'
import { UebergabeHeader } from './components/UebergabeHeader'

import MultipleSelectList, { MultipleSelectListProps } from '../../../components/MultipleSelectList'
import { HistoryCollapsible } from './components/HistoryCollapsible'
import { dsv } from '../../../styles/defaults'
import { useCurrentProcessGate } from './hooks/useCurrentProcessGate'

export function ProjectUebergabeVertrieb() {
  const akte = useAkteContext()

  const processGate = useCurrentProcessGate(akte, 'UebergabeAnVertrieb')

  const [rolesToDisplayFields, setRolesToDisplayFields] = useState<Array<string>>([])

  //const rights = usePermissions()
  //const { authCtx } = useAuthContext()


  const roleOptions: MultipleSelectListProps["data"] = [] as const /*useMemo(
    () =>
      Object.keys(RoleEnumSchema.Values)
        .filter((role) => !authCtx?.roles?.includes(role))
        .map((role) => ({ value: t(role), key: role })),
    [authCtx?.roles, t]
  )*/

  const isComplete = useMemo(()=>{
    if(processGate == undefined){
      return false
    }
    return Object.entries(processGate)
      .every(
        ([fieldName, needed]) => 
          needed!="required" || 
          (
            akte?.serverState != undefined && 
            (akte.serverState as Record<string,unknown>)[fieldName]!=undefined
          )
      )
  },[akte.serverState, processGate])
  
  const isMandatory = useCallback(
    (data: Partial<Akte>, key: keyof Akte): boolean => {
      if (processGate == undefined) return true

      const tmp = processGate[key]

      return tmp == 'required'
    },
    [processGate]
  )

  const isHidden = useCallback(
    (data: Partial<Akte>, key: keyof CalcAkte): boolean => {
      if (processGate == undefined) return true
      const tmp = processGate[key]
      return (
        tmp == 'hidden' /*||
        !rights.data?.rights[key].write.some(
          (role) => authCtx?.roles?.includes(role) || rolesToDisplayFields?.includes(role)
        )*/
      )
    },
    [processGate],//[authCtx?.roles, processGate, rights.data?.rights, rolesToDisplayFields]
  )

  // TODO: processGate, isMandatory and isHidden are very expensive the way they are implemented right now

  const isFrozen =
    akte.serverState?.Process_Uebergabe_Vertrieb_requested != undefined ||
    akte.serverState?.Process_Uebergabe_Vertrieb_approved != undefined

  const { forms: datenblattForms, identTableJsx } = useDatenblattForms(
    (data, key) => {
      return isMandatory(data, key) ? data[key] != undefined : true
    },
    isHidden,
    isFrozen,
    isMandatory
  )

  const forms = useUebergabeForms(datenblattForms, identTableJsx)

  return (
    <Section style={{ flex: 1 }}>
      <Card customStyle={{ flex: 1 }}>
        {akte.isLoading && (
          <CardBody customStyle={{ minHeight: 300 }}>
            <LoadingScreen active />
          </CardBody>
        )}
        {!akte.isLoading && (
          <>
            <CardHeader>
              <UebergabeHeader uebergabeStep={'Vertrieb'} uebergabeBlocked={!isComplete} />
            </CardHeader>
            <CardBody customStyle={{ flex: 1, overflow: 'scroll' }}>
              {roleOptions.length > 0 && (
                <Div style={{ backgroundColor: dsv.colors.connectGrau7, paddingHorizontal: 10, paddingTop: 10 }}>
                  <MultipleSelectList
                    label={'Zusätzlich Felder dieser Rollen zeigen'}
                    data={roleOptions}
                    setSelected={setRolesToDisplayFields}
                    currentSelections={rolesToDisplayFields}
                    placeholder="Zusätzlich Felder dieser Rollen zeigen"
                  />
                </Div>
              )}
              {forms}

              <HistoryCollapsible identifier={akte.identifier} />
            </CardBody>
          </>
        )}
      </Card>
    </Section>
  )
}
