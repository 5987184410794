import { View } from 'react-native'
import { Collapsible } from '../Collapsible'
import { Text } from '../TextComponents'

export interface CollapsibleFormProps {
  title: string | JSX.Element
  formFieldHeader?: JSX.Element
  formFields: JSX.Element[]
  defaultOpen?: true
}

export function CollapsibleForm({ title, formFieldHeader, formFields, defaultOpen }: CollapsibleFormProps) {
  return (
    <Collapsible
      defaultOpen={defaultOpen}
      summary={<View>{typeof title === 'string' ? <Text style={{ fontSize: 20 }}>{title}</Text> : title}</View>}
    >
      {formFieldHeader}
      {formFields}
    </Collapsible>
  )
}
