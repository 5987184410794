import { View } from 'react-native'
import { Outlet } from '../../../utils/routing'

import { Tabs } from '../../../components/Tabs'
import { AkteContextProvider } from '../../../hooks/context/useAkteContext'
import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { ProcessGateContextProvider } from '../../../hooks/context/useProcessGateContext'
import { useAuthContext } from '../../../hooks/context/useAuthContext'
import { useUebergabenToDisplay } from '../../../hooks/roleBasedDisplay/useUebergabenToDisplay'

export function ProjectsLayout() {
  const { projectId: projectIdStr } = useParams()
  // TODO: useParams might return undefined ?
  const projectId = useMemo(() => Number.parseInt(projectIdStr!), [projectIdStr])
  const auth = useAuthContext()

  const toDisplay = useUebergabenToDisplay(auth.authCtx?.roles)

  const links = useMemo(() => {
    const navLinks = []

    navLinks.push({
      title: 'Status & Übersicht',
      to: './state',
    })
    navLinks.push({
      title: 'Produkte',
      to: './products',
    })

    if (toDisplay.includes('VertriebAnEngineering')) {
      navLinks.push({
        title: 'Übergabe von Vertrieb an Engineering',
        to: './uebergabeProjektierung',
      })
    }
    if (toDisplay.includes('EngineeringAnVertrieb')) {
      navLinks.push({
        title: 'Übergabe von Engineering an Vertrieb',
        to: './uebergabeVertrieb',
      })
    }
    if (toDisplay.includes('VertriebAnPMO')) {
      navLinks.push({
        title: 'Übergabe von Vertrieb an PMO',
        to: './uebergabePMO',
      })
    }
    if (toDisplay.includes('PMOAnS&W')) {
      navLinks.push({
        title: 'Übergabe von PMO an S&W',
        to: './uebergabeSW',
      })
    }
    navLinks.push({
      title: 'Datenblatt',
      to: './datasheet',
    })

    if(
      auth.authCtx?.roles.includes("Admin") || 
      auth.authCtx?.roles.includes("Service & Wartung") || 
      auth.authCtx?.roles.includes("Projekt Support")
    ){
      navLinks.push(
        {
          title: 'Backend Auftrag',
          to: './orderBackend',
        },
        {
          title: 'Konfig. Auftrag',
          to: './orderConfig',
        }
      )
    }
    

    return navLinks
  }, [auth.authCtx?.roles, toDisplay])

  return (
    <ProcessGateContextProvider>
      <AkteContextProvider id={projectId}>
        <View style={{ flex: 1 }}>
          <View style={{ flexDirection: 'row' }}>
            <Tabs links={links} defaultLink={links[0]} />
          </View>
          <Outlet />
        </View>
      </AkteContextProvider>
    </ProcessGateContextProvider>
  )
}
