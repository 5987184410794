import { useMemo } from 'react'
import { View } from 'react-native'

import { useDatenblattForms } from '../../../hooks/form/useDatenblattForms'
import { datenblattFormGroupDefinitions } from '../../../hooks/form/useDatenblattFormGroup'

import { H2, H3 } from '../../../components/TextComponents'

import { Collapsible } from '../../../components/Collapsible'

export function useUebergabeForms(  // TODO: Make into a Component
  datenblattForms: ReturnType<typeof useDatenblattForms>['forms'],
  identTableJsx: ReturnType<typeof useDatenblattForms>['identTableJsx']
) {
  return useMemo(
    () =>
      datenblattFormGroupDefinitions.map(({ key, form, forms, title }) => {
        if (form !== undefined) {
          const generatedFields = datenblattForms[form]?.entries.map(([name, elem]) => (
            <div key={name as string}>{elem}</div>
          ))

          if (generatedFields.length === 0) {
            return <></>
          }
          return (
            <View key={key}>
              <Collapsible
                defaultOpen={true}
                customStyle={{ backgroundColor: undefined }}
                summary={
                  <H2>
                    {title}{' '}
                    {/*(
                    {datenblattForms[form].okStats.okCount + datenblattForms[form].okStats.nokCount === 0
                      ? `nicht relevant`
                      : /*`${datenblattForms[form].okStats.okCount} von ${
                        datenblattForms[form].okStats.okCount + datenblattForms[form].okStats.nokCount
                      }`}
                    )*/}
                  </H2>
                }
              >
                {generatedFields}
              </Collapsible>
            </View>
          )
        } else if (forms !== undefined) {
          const generatedForms = forms
            .map(({ key, form, title }) => {
              if (form !== undefined) {
                const entries = datenblattForms[form]?.entries
                if (key !== 'Backend_Identifikationsmedien' && entries.length === 0) {
                  return undefined
                }
                if (title == undefined) {
                  return <div key={key}>{entries.map(([_name, elem]) => elem)}</div>
                }

                return (
                  <View key={key}>
                    <H3>
                      {title}{' '}
                      {/*(
                      {datenblattForms[form].okStats.okCount + datenblattForms[form].okStats.nokCount === 0
                        ? `nicht relevant`
                        : `${datenblattForms[form].okStats.okCount} von ${
                          datenblattForms[form].okStats.okCount + datenblattForms[form].okStats.nokCount
                        }`}
                      )*/}
                    </H3>
                    {/*[
                      ...(key === 'Backend_Identifikationsmedien' ? [identTableJsx] : []),
                      ...entries.map(([name, elem]) => <div key={name}>{elem}</div>),
                    ]*/
                      entries.map(([name, elem]) => <div key={name}>{elem}</div>)
                    }
                  </View>
                )
              }
            })
            .filter((e) => e !== undefined)
          if (generatedForms.length === 0) {
            return <></>
          }
          let _okCount = 0
          let _nokCount = 0
          for (const f of forms) {
            _okCount += datenblattForms[f.form]?.okStats.okCount ?? 0
            _nokCount += datenblattForms[f.form]?.okStats.nokCount ?? 0
          }

          return (
            <View key={key}>
              <Collapsible
                defaultOpen={true}
                customStyle={{ backgroundColor: undefined }}
                summary={
                  <H2>
                    {title}{' '}
                    {/*({okCount + nokCount === 0 ? `nicht relevant` : `${okCount} von ${okCount + nokCount}`})*/}
                  </H2>
                }
              >
                {generatedForms}
              </Collapsible>
            </View>
          )
        }
      }),
    [datenblattForms, identTableJsx]
  )
}
