import { View } from 'react-native'
import { trpc } from '../../../App'
import { Card, CardBody, CardHeader } from '../../components/Card'

import { ProjectList } from '../../components/ProjectList'

export function MyProjects() {
  const watchedProjects = trpc.watchedProjects.getAll.useQuery()
  const assignedProjects = trpc.akte.assigned.useQuery()

  return (
    <View>
      <Card customStyle={{ margin: 10, width: 1576 }}>
        <CardHeader>Meine Favoriten</CardHeader>
        <CardBody>
          <ProjectList projects={watchedProjects} noDataPlaceholder="Keine favorisierten Projekte" />
        </CardBody>
      </Card>
      <Card customStyle={{ margin: 10, width: 1576 }}>
        <CardHeader>Beteiligte Projekte</CardHeader>
        <CardBody>
          <ProjectList projects={assignedProjects} noDataPlaceholder="Keine beteiligten Projekte" />
        </CardBody>
      </Card>
    </View>
  )
}
