import { StyleSheet } from 'react-native'

import { Table, TBody, TR, THead, Div } from '@expo/html-elements'
import { ChangeLogEntry } from './ChangeLogEntry'
import { dsv } from '../styles/defaults'
import { useCompressedAkteAuditLog } from '../hooks/audit/useCompressedAkteAuditLog'
import { TH, TD, H4, H3 } from './TextComponents'
import MultipleSelectList from './MultipleSelectList'
import { AktenIdentifier, CalcAkteSchema } from '@dpa/common/dist'
import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import { addPrefix } from '../locales/translationKeys'

const tableStyle = StyleSheet.create({
  td: {
    verticalAlign: 'top',
    textAlign: 'left',
    paddingHorizontal: 10,
    paddingTop: 8,
    paddingBottom: 8,
  },
  th: {
    verticalAlign: 'top',
    textAlign: 'left',
    paddingHorizontal: 10,
    paddingTop: 8,
    paddingBottom: 8,
  },
  cellBorder: {
    borderBottomWidth: 1,
    borderBottomColor: dsv.colors.connectGrau7,
    borderBottomStyle: 'solid',
    borderSpacing: 10,
  },
})

interface ChangeLogPros {
  identifier: AktenIdentifier
}

export function ChangeLog({ identifier }: ChangeLogPros) {
  const { t } = useTranslation()
  const fields = useMemo(
    () => Object.keys(CalcAkteSchema.shape).map((f) => ({ value: t(addPrefix('labels', f)), key: String(f) })),
    [t]
  )

  const [selectedFields, setSelectedFields] = useState<Array<string>>([])
  const audit = useCompressedAkteAuditLog(identifier, selectedFields)

  return (
    <>
      <Div style={{ paddingTop: 20 }}>
        <MultipleSelectList
          data={fields}
          setSelected={setSelectedFields}
          currentSelections={selectedFields}
          placeholder="Ein Feld zum filtern auswählen"
          searchPlaceholder=" Feld suchen ..."
        />
      </Div>
      <Table
        style={{
          backgroundColor: dsv.colors.connectGrau7,
          borderCollapse: 'collapse',
          marginVertical: 12,
          width: '100%',
        }}
      >
        <THead style={{ backgroundColor: dsv.colors.GpJouleGelb }}>
          <TR style={tableStyle.cellBorder}>
            <TH style={[tableStyle.th]}>
              <H4>Änderungszeitraum</H4>
            </TH>
            <TH style={[tableStyle.th]}>
              <H4>Benutzer</H4>
            </TH>
            <TH style={[tableStyle.th]}>
              <H4>Änderungen</H4>
            </TH>
          </TR>
        </THead>
        <TBody>
          {audit != null && audit.length > 0 ? (
            audit?.map((logEntry) => <ChangeLogEntry key={logEntry.version} entry={logEntry} />)
          ) : (
            <TR>
              <TD colSpan={4}>Keine Einträge gefunden</TD>
            </TR>
          )}
        </TBody>
      </Table>
    </>
  )
}
