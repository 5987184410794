import { Platform } from 'react-native'
import {
  Routes as BrowserRoutes,
  BrowserRouter,
  Link as BrowserLink,
  Route as BrowserRoute,
  Outlet as BrowserOutlet,
} from 'react-router-dom'
import {
  Routes as NativeRoutes,
  NativeRouter,
  Link as NativeLink,
  Route as NativeRoute,
  Outlet as NativeOutlet,
} from 'react-router-native'

export const Routes = Platform.select({
  native: () => NativeRoutes,
  default: () => BrowserRoutes,
})()

export const Router = Platform.select({
  native: () => NativeRouter,
  default: () => BrowserRouter,
})()

// TODO: They do not have the same signature, therefor Platform.select wont work ...
export const Link = Platform.OS == 'web' ? BrowserLink : NativeLink

export const Route = Platform.select({
  native: () => NativeRoute,
  default: () => BrowserRoute,
})()

export const Outlet = Platform.select({
  native: () => NativeOutlet,
  default: () => BrowserOutlet,
})()
