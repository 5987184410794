import { ChangeEvent, useMemo } from 'react'
import { dsv } from '../../../styles/defaults'

import type { InputProps } from '.'

export function InputBoolean({ fieldId, strValue, setStrValue, saveStrValue, style }: InputProps) {
  const { onChange } = useMemo(
    () => ({
      onChange:
        setStrValue == undefined || saveStrValue == undefined
          ? undefined
          : (event: ChangeEvent<HTMLInputElement>) => {
            saveStrValue(event.target.checked ? 'true' : '')
          },
    }),
    [setStrValue, saveStrValue]
  )

  return (
    <input
      type="checkbox"
      id={fieldId}
      value={strValue}
      disabled={onChange == undefined}
      checked={strValue == 'true'}
      onChange={onChange}
      // TODO: Fix styling for App Version
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style={{ height: 24, ...dsv.text, ...style }}
    />
  )
}
