import { View } from 'react-native'
import { ChildrenProp } from '../types'
import React from 'react'

interface RowProps {
  gap?: number
  // add Col[] to type
  children: ReturnType<typeof Col>
}

export function Row({ children, gap }: RowProps) {
  const gapOffset = gap ? { marginHorizontal: gap * -1 } : undefined

  return (
    <View style={{ flexWrap: 'wrap', ...gapOffset, flexDirection: 'row' }}>
      {renderChildren({ children }, { gap })}
    </View>
  )
}

interface ColProps extends ChildrenProp {
  cols: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  gap?: number
}

export function Col({ cols, children, gap }: ColProps) {
  return (
    <View
      style={{
        width: `${(cols / 12) * 100}%`,
        padding: gap,
      }}
    >
      {children}
    </View>
  )
}

function renderChildren({ children }: ChildrenProp, props: object) {
  if (typeof children == 'string') {
    return children
  }
  return React.Children.map(children, (child) => {
    return React.cloneElement(child, { ...props })
  })
}
