import { View } from 'react-native'
import { Section } from '@expo/html-elements'
import { Card, CardBody, CardHeader } from '../../components/Card'
import { Text, H4 } from '../../components/TextComponents'
import { Col, Row } from '../../components/Row'
import { dsv } from '../../styles/defaults'
import { useUserListContext } from '../../hooks/context/useUserListContext'
import { LoadingScreen } from '../../components/LoadingScreen'
import { linkWithPlaceholder } from '../../utils/text'
import { useTranslation } from 'react-i18next'

export function Mitarbeiter() {
  const { isLoading, userList } = useUserListContext()
  const { t } = useTranslation()

  return (
    <Section>
      <Row>
        <Col cols={12}>
          <Card>
            <CardHeader>
              <Text style={dsv.text}>Mitarbeiter</Text>
            </CardHeader>
            <CardBody>
              <Section>
                <View
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    marginVertical: 10,
                    paddingHorizontal: 8,
                    backgroundColor: dsv.colors.connectGrau,
                  }}
                >
                  <H4 style={{ fontSize: 18, marginVertical: 0, width: 200, color: dsv.colors.connectGrau7 }}>
                    Rollen
                  </H4>
                  <H4 style={{ fontSize: 18, marginVertical: 0, width: 250, color: dsv.colors.connectGrau7 }}>Email</H4>
                  <H4 style={{ fontSize: 18, marginVertical: 0, color: dsv.colors.connectGrau7 }}>Name</H4>
                </View>
              </Section>
              {isLoading && <LoadingScreen active />}
              {!isLoading && (
                <>
                  {(userList || []).map((user) => (
                    <Section key={user.id}>
                      <View
                        style={{
                          flex: 1,
                          flexDirection: 'row',
                          marginVertical: 1,
                          paddingVertical: 10,
                          paddingHorizontal: 8,
                          backgroundColor: dsv.colors.base2,
                        }}
                      >
                        <Text style={{ width: 200 }}>{user.UserRoles.map(({ role }) => t(role)).join(', ')}</Text>
                        <Text style={{ width: 250 }}>{linkWithPlaceholder(user.email, 'mailTo')}</Text>
                        <Text style={{ overflow: 'hidden' }}>{user.name}</Text>
                      </View>
                    </Section>
                  ))}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Section>
  )
}
