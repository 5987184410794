import { useFonts as useExpoFonts } from 'expo-font'
import { User, UserWithoutPassWithRoles } from '@dpa/common/dist'
import { A } from '../components/TextComponents'
import { dsv } from '../styles/defaults'
import { TFunction } from 'i18next'
import { Icon } from '../components/Icon'
import { NoDataPlaceholder } from '../constants'

export function useFonts() {
  return useExpoFonts({
    'AvenirNext LT Pro Regular': require('../../assets/fonts/AvenirNextLTProRegular.otf'),
    'AvenirNext LT Pro Heavy': require('../../assets/fonts/AvenirNextLTProHeavy.otf'),
  })
}

export function textWithPlaceHolder(text: string | undefined | null, t?: TFunction<'translation', undefined>) {
  if (t != null) {
    return text != null && text.length > 0 ? t(text) : NoDataPlaceholder
  }
  return text != null && text.length > 0 ? text : NoDataPlaceholder
}

export function linkWithPlaceholder(link: string | undefined | null, flavor?: 'default' | 'mailTo' | 'phone') {
  if (link == null) {
    return NoDataPlaceholder
  }
  switch (flavor) {
    case 'mailTo':
      return (
        <>
          <Icon name="mail" />
          &nbsp;
          <A style={{ color: dsv.colors.link }} href={`mailto:${link} `}>
            {link}
          </A>
        </>
      )
    case 'phone':
      return (
        <>
          <Icon name="phone" />
          &nbsp;
          <A style={{ color: dsv.colors.link }} href={`tel:${link} `}>
            {link}
          </A>
        </>
      )
    default:
      return (
        <A style={{ color: dsv.colors.link }} href={`${link} `}>
          {link}
        </A>
      )
  }
}

export function userWithPlaceHolder(
  user: User | undefined | null | number,
  userById?: { [x: number]: UserWithoutPassWithRoles }
) {
  if (typeof user === 'number' && userById != null) {
    const u = userById[user]
    if (u != null) {
      return (
        <>
          {u.name} ({linkWithPlaceholder(u.email, 'mailTo')})
        </>
      )
    }
  }

  if (typeof user === 'number') {
    return user
  }

  return user?.name != null ? user.name : NoDataPlaceholder
}
