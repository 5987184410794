
import { useEffect, useState } from "react"

import type { ProcessGates } from "@dpa/server/trpc/routers/processGate"

import { AkteCtx } from "../../../../hooks/context/useAkteContext"
import { applyGateConditions, CalcAkte, process_akte, ProcessGate } from "@dpa/common/dist"
import { useProcessGateContext } from "../../../../hooks/context/useProcessGateContext"
import { objDeltaEntries } from "../../../../hooks/useObjDelta"
import { akteDisplayConditions } from "../akteDisplayConditions"

export function useCurrentProcessGate(akte: AkteCtx, currentGate: keyof ProcessGates) {

  const [processGate, setProcessGate] = useState<ProcessGate<CalcAkte> | undefined>(undefined)
  const processGateCtx = useProcessGateContext()

  useEffect(() => {
    if (akte?.localState == undefined) return undefined
    const projectType = process_akte.processType(akte.localState)
    if (projectType == undefined) return undefined
    if (processGateCtx.isLoading) return undefined

    const gate = processGateCtx.processGates[projectType][currentGate] //TODO: useProcessGateContext
    console.log("gate", gate)
    const modifiedGate = applyGateConditions(gate, akte.localState, akteDisplayConditions)
    if (objDeltaEntries(modifiedGate, processGate).some(([_, changed]) => changed)) {
      setProcessGate(modifiedGate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [akte.localState, currentGate, processGateCtx.isLoading, processGateCtx.processGates])
  // [akte.localState]) 
  return processGate
}
