import { View } from 'react-native'

import { H3 } from '../../../../components/TextComponents'

import { dsv } from '../../../../styles/defaults'
import { useAkteContext } from '../../../../hooks/context/useAkteContext'

interface OrderHeaderProps {
  orderName: string
}

export function OrderHeader({ orderName }: OrderHeaderProps) {
  const akte = useAkteContext()
  

  return (
    <View style={{width: '100%'}}>
      <View
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <H3 style={{ color: dsv.colors.connectGrau7, marginVertical: 0, paddingVertical: 0 }}>
          {orderName}: {akte.serverState?.UE_Kundendaten_Projekttitel}
        </H3>
      </View>
    </View>
  )
}
