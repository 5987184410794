import { Text } from '../../../components/TextComponents'

import { Section } from '@expo/html-elements'
import { ZodRawShape, z } from 'zod'
import { useMapObj } from '../../useMapObj'
import { useAutoFormFields } from './useAutoForm'
import { useMemo } from 'react'
import { dsv } from '../../../styles/defaults'
import { Icon } from '../../../components/Icon'
import { OkFields, useOkStats } from '../../useOkStats'
import { usePermissions } from '../../usePermissions'
import { FormPermissionEntry, FormPermissionsDefinition, FormPermissions } from './useFormPermissions'

interface PermissionFormFieldProps {
  field: JSX.Element
  permissions?: FormPermissionEntry
  ok?: boolean
  mandatory?: boolean
  notNullish?: boolean
}

function PermissionFormField({ field, ok, mandatory, notNullish }: PermissionFormFieldProps) {
  return (
    <Section
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'flex-end',
        columnGap: 20,
      }}
    >
      <Section style={{ width: 40 }}>
        {ok !== undefined && (
          <Text style={dsv.text}>
            {notNullish ? (
              ok ? (
                <Icon color={dsv.colors.success} name={'check'} size={32} />
              ) : (
                <Icon color={dsv.colors.success} name={'exclamationcircle'} size={32} />
              )
            ) : mandatory ? (
              <></> //<Icon color={dsv.colors.error} name={'question'} size={32} />
            ) : (
              <></> //<Icon color={dsv.colors.warning} name={'question'} size={32} />
            )}
          </Text>
        )}
      </Section>
      <Section style={{ flex: 1 }}>{field}</Section>
    </Section>
  )
}
export interface PermissionFormHeaderProps {
  okFields: OkFields
}

export function PermissionFormHeader({ okFields }: PermissionFormHeaderProps) {
  const { nokCount, okCount } = useOkStats(okFields)

  return (
    <Section
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'flex-end',
        columnGap: 20,
      }}
    >
      <Section style={{ width: 40, alignItems: 'center' }}>
        <Text style={dsv.text}>
          {okCount + nokCount == 0 ? '100' : ((100 / (okCount + nokCount)) * okCount).toFixed()}%
        </Text>
      </Section>
      <Section style={{ width: 160 }}>
        <Text style={dsv.text}>Erledigt: {okCount}</Text>
        <Text style={dsv.text}>Offen: {nokCount}</Text>
      </Section>
      <Section style={{ flex: 1 }}></Section>
    </Section>
  )
}

export function usePermissionForm<T extends ZodRawShape>(
  schema: ReturnType<typeof z.object<T>>,
  fieldPermissions?: FormPermissionsDefinition<T>,
  isOk?: (data: Partial<z.infer<typeof schema>>, key: keyof T) => boolean,
  onBlur?: () => void,
  isHidden?: (data: Partial<z.infer<typeof schema>>, key: keyof T) => boolean,
  isFrozen: boolean = false,
  isMandatory?: (data: Partial<z.infer<typeof schema>>, key: keyof z.infer<typeof schema>) => boolean
) {
  const rights = usePermissions()
  const roleRights = rights.data?.permissions ?? {}
  return usePermissionFormRaw(schema, fieldPermissions ?? {}, roleRights, isOk, onBlur, isHidden, isFrozen, isMandatory)
}

export function usePermissionFormRaw<T extends ZodRawShape>(
  schema: ReturnType<typeof z.object<T>>,
  fieldPermissions: FormPermissionsDefinition<T>,
  roleRights: FormPermissions<T>,
  isOk?: (data: Partial<z.infer<typeof schema>>, key: keyof T) => boolean,
  onBlur?: () => void,
  isHidden?: (data: Partial<z.infer<typeof schema>>, key: keyof T) => boolean,
  isFrozen: boolean = false,
  isMandatory?: (data: Partial<z.infer<typeof schema>>, key: keyof z.infer<typeof schema>) => boolean
) {
  const editableFields = useMapObj(roleRights, ({ write }) => !isFrozen && write)

  const form = useAutoFormFields(schema, editableFields, undefined, onBlur, false, isMandatory)

  const okFields = useMemo(() => {
    const relevantFields = Object.entries(roleRights) //TODO: angucken
      .filter(([_name, { write }]) => write)
      .map(([name, _]) => name)
    const res = Object.fromEntries(
      isOk !== undefined
        ? relevantFields.map(
          (relevantField) =>
              [
                relevantField,
                {
                  ok: isOk(form.formState, relevantField),
                  mandatory: isMandatory && isMandatory(form.formState, relevantField),
                  notNullish: form.formState[relevantField] !== undefined && form.formState[relevantField] !== null,
                },
              ] as [string, { ok: boolean; mandatory: boolean; notNullish: boolean }]
        )
        : []
    )
    return res
  }, [form.formState, isOk, isMandatory, roleRights])

  const formFieldEntries = form.formFieldEntries
    .filter(
      ([name, _]) =>
        (roleRights[name]?.read || roleRights[name]?.write) &&
        (isHidden == undefined || !isHidden(form.formState, name))
    )
    .map(([name, field]) => [
      name,
      <PermissionFormField
        key={name as string}
        field={field}
        ok={okFields[name as string]?.ok}
        mandatory={okFields[name as string]?.mandatory}
        notNullish={okFields[name as string]?.notNullish}
      />,
    ]) as [keyof T, JSX.Element][]

  return {
    ...form,
    formFieldEntries,
    okFields,
  }
}
