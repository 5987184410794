import { useMemo } from 'react'
import { Role } from '../context/useAuthContext'
import { UebergabenNames } from './UebergabenNames'


export function progressBarToDisplay(role: undefined | Role): Array<UebergabenNames> {
  const uebergabenToDisplay: Array<UebergabenNames> = []

  if (role == undefined || role == 'Vertrieb' || role == 'Innendienst') {
    uebergabenToDisplay.push('VertriebAnEngineering')
  }
  if (role == undefined || role == 'Projektierung') {
    uebergabenToDisplay.push('EngineeringAnVertrieb')
  }
  if (role == undefined || role == 'Vertrieb' || role == 'Innendienst') {
    uebergabenToDisplay.push('VertriebAnPMO')
  }
  if (role == undefined || role == 'Projekt Support' || role == 'PMO') {
    uebergabenToDisplay.push('PMOAnS&W')
  }
  return uebergabenToDisplay
}

export function useProgressBarToDisplay(role: undefined | Role): Array<UebergabenNames> {
  return useMemo(() => progressBarToDisplay(role), [role])
}
