import { View, Button } from 'react-native'

import { H3, Text } from '../../../../components/TextComponents'

import { RejectionDialog } from './RejectionDialog'

import { useCallback, useState, useMemo } from 'react'
import { dsv } from '../../../../styles/defaults'
import { useAkteContext } from '../../../../hooks/context/useAkteContext'
import { useUpdateAkteMutation } from '../../../../hooks/update/mutations/useUpdateAkteMutation'
import { useAuthContext } from '../../../../hooks/context/useAuthContext'
import { GpjButton } from './GpjButton'

interface UebergabeHeaderProps {
  uebergabeStep: 'Projektierung' | 'Vertrieb' | 'PMO' | 'S&W'
  uebergabeBlocked: boolean
}

export function UebergabeHeader({ uebergabeStep, uebergabeBlocked }: UebergabeHeaderProps) {
  const akte = useAkteContext()
  const updateAkteMutation = useUpdateAkteMutation()

  const { hasRole } = useAuthContext()

  const [rejectionDialogeOpen, setRejectionDialogeOpen] = useState(false)
  const [revocationDialogeOpen, setRevocationDialogeOpen] = useState(false)

  const openRejectionDialoge = useCallback(() => {
    setRevocationDialogeOpen(false)
    setRejectionDialogeOpen(true)
  }, [setRejectionDialogeOpen])

  const openRevocationDialoge = useCallback(() => {
    setRejectionDialogeOpen(false)
    setRevocationDialogeOpen(true)
  }, [setRevocationDialogeOpen])

  const closeDialogs = useCallback(() => {
    setRejectionDialogeOpen(false)
    setRevocationDialogeOpen(false)
  }, [setRevocationDialogeOpen])

  const uebergabeRequestAndCloseDialoge = useMemo(() => {
    switch (uebergabeStep) {
      case 'Projektierung':
        return () => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_Projektierung_requested: new Date(),
          })
        }
      case 'Vertrieb':
        return () => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_Vertrieb_requested: new Date(),
          })
        }
      case 'PMO':
        return () => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_PMO_requested: new Date(),
          })
        }
      case 'S&W':
        return () => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_SW_requested: new Date(),
          })
        }
    }
  }, [uebergabeStep, closeDialogs, updateAkteMutation, akte.identifier])

  const anfrageRejectAndCloseDialoge = useMemo(() => {
    if (uebergabeStep == 'Vertrieb') {
      return () => {
        closeDialogs()
        updateAkteMutation.mutate({
          ...akte.identifier,
          Process_Uebergabe_Vertrieb_requested: null,
          Process_Uebergabe_Vertrieb_preapproved: null,
        })
      }
    }
  }, [uebergabeStep, closeDialogs, updateAkteMutation, akte.identifier])

  const anfrageApproveAndCloseDialoge = useMemo(() => {
    if (uebergabeStep == 'Vertrieb') {
      return () => {
        closeDialogs()
        updateAkteMutation.mutate({
          ...akte.identifier,
          Process_Uebergabe_Vertrieb_preapproved: new Date(),
        })
      }
    }
  }, [uebergabeStep, closeDialogs, updateAkteMutation, akte.identifier])

  const uebergabeRejectAndCloseDialoge = useMemo(() => {
    switch (uebergabeStep) {
      case 'Projektierung':
        return (rejectionText: string) => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_Projektierung_requested: null,
            Process_Uebergabe_Projektierung_rejected: new Date(),
            Process_Uebergabe_Projektierung_rejectionComment: rejectionText,
            Process_Uebergabe_Projektierung_revoked: null,
            Process_Uebergabe_Projektierung_revocationComment: null,
          })
        }
      case 'Vertrieb':
        return (rejectionText: string) => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_Vertrieb_requested: null,
            Process_Uebergabe_Vertrieb_rejected: new Date(),
            Process_Uebergabe_Vertrieb_preapproved: null,
            Process_Uebergabe_Vertrieb_rejectionComment: rejectionText,
            Process_Uebergabe_Vertrieb_revoked: null,
            Process_Uebergabe_Vertrieb_revocationComment: null,
          })
        }
      case 'PMO':
        return (rejectionText: string) => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_PMO_requested: null,
            Process_Uebergabe_PMO_rejected: new Date(),
            Process_Uebergabe_PMO_rejectionComment: rejectionText,
            Process_Uebergabe_PMO_revoked: null,
            Process_Uebergabe_PMO_revocationComment: null,
          })
        }
      case 'S&W':
        return (rejectionText: string) => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_SW_requested: null,
            Process_Uebergabe_SW_rejected: new Date(),
            Process_Uebergabe_SW_rejectionComment: rejectionText,
            Process_Uebergabe_SW_revoked: null,
            Process_Uebergabe_SW_revocationComment: null,
          })
        }
    }
  }, [uebergabeStep, closeDialogs, updateAkteMutation, akte.identifier])

  const uebergabeApproveAndCloseDialoge = useMemo(() => {
    switch (uebergabeStep) {
      case 'Projektierung':
        return () => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_Projektierung_approved: new Date(),
          })
        }
      case 'Vertrieb':
        return () => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_Vertrieb_approved: new Date(),
          })
        }
      case 'PMO':
        return () => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_PMO_approved: new Date(),
          })
        }
      case 'S&W':
        return () => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_SW_approved: new Date(),
          })
        }
    }
  }, [uebergabeStep, closeDialogs, updateAkteMutation, akte.identifier])

  const uebergabeRevokeAndCloseDialoge = useMemo(() => {
    switch (uebergabeStep) {
      case 'Projektierung':
        return (revocationText: string) => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_Projektierung_requested: null,
            Process_Uebergabe_Projektierung_approved: null,
            Process_Uebergabe_Projektierung_rejected: null,
            Process_Uebergabe_Projektierung_rejectionComment: null,
            Process_Uebergabe_Projektierung_revoked: new Date(),
            Process_Uebergabe_Projektierung_revocationComment: revocationText,
          })
        }
      case 'Vertrieb':
        return (revocationText: string) => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_Vertrieb_requested: null,
            Process_Uebergabe_Vertrieb_approved: null,
            Process_Uebergabe_Vertrieb_rejected: null,
            Process_Uebergabe_Vertrieb_rejectionComment: null,
            Process_Uebergabe_Vertrieb_preapproved: null,
            Process_Uebergabe_Vertrieb_revoked: new Date(),
            Process_Uebergabe_Vertrieb_revocationComment: revocationText,
          })
        }
      case 'PMO':
        return (revocationText: string) => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_PMO_requested: null,
            Process_Uebergabe_PMO_approved: null,
            Process_Uebergabe_PMO_rejected: null,
            Process_Uebergabe_PMO_rejectionComment: null,
            Process_Uebergabe_PMO_revoked: new Date(),
            Process_Uebergabe_PMO_revocationComment: revocationText,
          })
        }
      case 'S&W':
        return (revocationText: string) => {
          closeDialogs()
          updateAkteMutation.mutate({
            ...akte.identifier,
            Process_Uebergabe_SW_requested: null,
            Process_Uebergabe_SW_approved: null,
            Process_Uebergabe_SW_rejected: null,
            Process_Uebergabe_SW_rejectionComment: null,
            Process_Uebergabe_SW_revoked: new Date(),
            Process_Uebergabe_SW_revocationComment: revocationText,
          })
        }
    }
  }, [uebergabeStep, closeDialogs, updateAkteMutation, akte.identifier])

  const submittable = useMemo(() => {
    switch (uebergabeStep) {
      case 'Projektierung':
        return (
          !uebergabeBlocked && 
          akte.serverState?.projectStatus === 'In_der_Erfassung' && (hasRole('Vertrieb') || hasRole('Admin'))
        )
      case 'Vertrieb':
        return (
          !uebergabeBlocked && 
          akte.serverState?.Process_Uebergabe_Projektierung_approved != null &&
          (hasRole('Projektierung') || hasRole('Admin'))
        )
      case 'PMO':
        return (
          !uebergabeBlocked && 
          akte.serverState?.Process_Uebergabe_Vertrieb_approved != null && (hasRole('Vertrieb') || hasRole('Admin'))
        )
      case 'S&W':
        return (
          !uebergabeBlocked && 
          akte.serverState?.Process_Uebergabe_PMO_approved != null && (hasRole('PMO') || hasRole('Admin'))
        )
    }
  }, [
    akte.serverState?.projectStatus,
    akte.serverState?.Process_Uebergabe_Vertrieb_approved,
    akte.serverState?.Process_Uebergabe_Projektierung_approved,
    akte.serverState?.Process_Uebergabe_PMO_approved,
    hasRole,
    uebergabeStep,
  ])
  const preAcceptable = useMemo(() => {
    if (uebergabeStep == 'Vertrieb') {
      return (
        akte.serverState?.Process_Uebergabe_Vertrieb_requested != null &&
        ((hasRole('Projektierung') && hasRole('Teamleitung')) || hasRole('Admin'))
      )
    }
  }, [akte.serverState?.Process_Uebergabe_Vertrieb_requested, hasRole, uebergabeStep])

  const acceptable = useMemo(() => {
    switch (uebergabeStep) {
      case 'Projektierung':
        return (
          akte.serverState?.Process_Uebergabe_Projektierung_requested != null &&
          ((hasRole('Projektierung') && hasRole('Teamleitung')) || hasRole('Admin'))
        )
      case 'Vertrieb':
        return (
          akte.serverState?.Process_Uebergabe_Vertrieb_preapproved != null && (hasRole('Vertrieb') || hasRole('Admin'))
        )
      case 'PMO':
        return (
          akte.serverState?.Process_Uebergabe_PMO_requested != null &&
          ((hasRole('PMO') && hasRole('Teamleitung')) || hasRole('Admin'))
        )
      case 'S&W':
        return (
          akte.serverState?.Process_Uebergabe_SW_requested != null && (hasRole('Service & Wartung') || hasRole('Admin'))
        )
    }
  }, [
    akte.serverState?.Process_Uebergabe_Projektierung_requested,
    akte.serverState?.Process_Uebergabe_Vertrieb_preapproved,
    akte.serverState?.Process_Uebergabe_PMO_requested,
    akte.serverState?.Process_Uebergabe_SW_requested,
    hasRole,
    uebergabeStep,
  ])

  const revocable = useMemo(() => {
    switch (uebergabeStep) {
      case 'Projektierung':
        return (
          acceptable &&
          akte.serverState?.Process_Uebergabe_Vertrieb_approved == null &&
          akte.serverState?.Process_Uebergabe_Vertrieb_requested == null
        )
      case 'Vertrieb':
        return (
          acceptable &&
          akte.serverState?.Process_Uebergabe_PMO_approved == null &&
          akte.serverState?.Process_Uebergabe_PMO_requested == null
        )
      case 'PMO':
        return (
          acceptable &&
          akte.serverState?.Process_Uebergabe_SW_approved == null &&
          akte.serverState?.Process_Uebergabe_SW_requested == null
        )
      case 'S&W':
        return acceptable
    }
  }, [])

  const uiState:
    | { buttons: 'show_request' | 'show_approve' | 'show_preaprove' }
    | { buttons: 'show_reject'; approvedAt: Date }
    | undefined = useMemo(() => {
      if (uebergabeStep == 'Projektierung') {
        if (akte.serverState?.Process_Uebergabe_Projektierung_requested == undefined) {
          return { buttons: 'show_request' }
        } else if (akte.serverState?.Process_Uebergabe_Projektierung_approved == undefined) {
          return { buttons: 'show_approve' }
        } else {
          return { buttons: 'show_reject', approvedAt: akte.serverState?.Process_Uebergabe_Projektierung_approved }
        }
      } else if (uebergabeStep == 'Vertrieb') {
        if (akte.serverState?.Process_Uebergabe_Vertrieb_requested == undefined) {
          return { buttons: 'show_request' }
        } else if (akte.serverState?.Process_Uebergabe_Vertrieb_preapproved == undefined) {
          return { buttons: 'show_preaprove' }
        } else if (akte.serverState?.Process_Uebergabe_Vertrieb_approved == undefined) {
          return { buttons: 'show_approve' }
        } else {
          return { buttons: 'show_reject', approvedAt: akte.serverState?.Process_Uebergabe_Vertrieb_approved }
        }
      } else if (uebergabeStep == 'PMO') {
        if (akte.serverState?.Process_Uebergabe_PMO_requested == undefined) {
          return { buttons: 'show_request' }
        } else if (akte.serverState?.Process_Uebergabe_PMO_approved == undefined) {
          return { buttons: 'show_approve' }
        } else {
          return { buttons: 'show_reject', approvedAt: akte.serverState?.Process_Uebergabe_PMO_approved }
        }
      } else if (uebergabeStep == 'S&W') {
        if (akte.serverState?.Process_Uebergabe_SW_requested == undefined) {
          return { buttons: 'show_request' }
        } else if (akte.serverState?.Process_Uebergabe_SW_approved == undefined) {
          return { buttons: 'show_approve' }
        } else {
          return { buttons: 'show_reject', approvedAt: akte.serverState?.Process_Uebergabe_SW_approved }
        }
      }
    }, [
      uebergabeStep,
      akte.serverState?.Process_Uebergabe_Projektierung_approved,
      akte.serverState?.Process_Uebergabe_Projektierung_requested,
      akte.serverState?.Process_Uebergabe_Vertrieb_requested,
      akte.serverState?.Process_Uebergabe_Vertrieb_preapproved,
      akte.serverState?.Process_Uebergabe_Vertrieb_approved,
      akte.serverState?.Process_Uebergabe_PMO_approved,
      akte.serverState?.Process_Uebergabe_PMO_requested,
      akte.serverState?.Process_Uebergabe_SW_approved,
      akte.serverState?.Process_Uebergabe_SW_requested,
    ])

  if (uiState == undefined) {
    return <></>
  }
  return (
    <View
      style={{
        width: '100%',
      }}
    >
      <View
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <H3 style={{ color: dsv.colors.connectGrau7, marginVertical: 0, paddingVertical: 0 }}>
          Übergabe {uebergabeStep}: {akte.serverState?.UE_Kundendaten_Projekttitel}
        </H3>
        <View style={{ margin: 5 }}>
          {uiState?.buttons == 'show_request' ? (
            <GpjButton text="Übergabe beantragen" onPress={uebergabeRequestAndCloseDialoge} isDisabled={!submittable} />
          ) : uiState?.buttons == 'show_preaprove' ? (
            <View style={{ marginRight: 5 }}>
              <GpjButton
                text="Anfrage akzeptieren"
                onPress={anfrageApproveAndCloseDialoge}
                enabledBackground={dsv.colors.success}
                isDisabled={!preAcceptable}
              />
              <GpjButton
                text="Anfrage ablehnen"
                onPress={anfrageRejectAndCloseDialoge}
                enabledBackground={dsv.colors.error}
                isDisabled={!preAcceptable}
              />
            </View>
          ) : uiState?.buttons == 'show_approve' ? (
            <View style={{ marginRight: 5 }}>
              <GpjButton
                text="Übergabe akzeptieren"
                onPress={uebergabeApproveAndCloseDialoge}
                enabledBackground={dsv.colors.success}
                isDisabled={!acceptable}
              />
              <GpjButton
                text="Übergabe ablehnen"
                onPress={openRejectionDialoge}
                enabledBackground={dsv.colors.error}
                isDisabled={!acceptable}
              />
            </View>
          ) : uiState?.buttons == 'show_reject' ? (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Text style={dsv.text}>Übergeben am {uiState.approvedAt.toLocaleDateString()}</Text>
              <View style={{ margin: 5, paddingBottom: 5 }}>
                <GpjButton
                  text="Übergabe zurückziehen"
                  onPress={openRevocationDialoge}
                  enabledBackground={dsv.colors.error}
                  isDisabled={!revocable}
                />
              </View>
            </View>
          ) : (
            <></>
          )}
        </View>
      </View>
      {rejectionDialogeOpen && (
        <RejectionDialog
          placeholder="Ablehnungsgrund"
          buttonText="Ablehnen"
          uebergabeReject={uebergabeRejectAndCloseDialoge}
        />
      )}
      {revocationDialogeOpen && (
        <RejectionDialog
          placeholder="Rückgabegrund"
          buttonText="Zurückgeben"
          uebergabeReject={uebergabeRevokeAndCloseDialoge}
        />
      )}
    </View>
  )
}
