import { ChangeEvent, useMemo, useRef } from 'react'
import { InputProps } from '.'
import { dsv } from '../../../styles/defaults'

export function InputNumber({ fieldId, strValue, setStrValue, saveStrValue, style }: InputProps) {
  const inputRef = useRef<HTMLInputElement>(null)

  const { onChange, onBlur } = useMemo(
    () => ({
      onChange:
        setStrValue == undefined
          ? undefined
          : (event: ChangeEvent<HTMLInputElement>) => {
            inputRef.current?.focus() // This is needed, because (in firefox) the up and down buttons do not focus
            setStrValue(event.target.value)
          },
      onBlur:
        saveStrValue == undefined
          ? undefined
          : () => {
            saveStrValue()
          },
    }),
    [setStrValue, saveStrValue]
  )

  return (
    <input
      type="number"
      ref={inputRef}
      id={fieldId}
      value={strValue}
      disabled={onChange == undefined}
      onChange={onChange}
      // TODO: Fix styling for App Version
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style={{ height: 24, ...dsv.text, ...style }}
      onBlur={onBlur}
    />
  )
}
