import { Div } from '@expo/html-elements'
import { H2 } from './TextComponents'

import { dsv } from '../styles/defaults'

import { PropsWithChildren } from 'react'

export interface SubCardProps {
  title: string
  width?: string | number
  centerHeading?: boolean
}

export function SubCard({ title, width, children, centerHeading = true }: PropsWithChildren<SubCardProps>) {
  return (
    <Div
      // TODO: Fix styling for mobile app
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      style={{
        backgroundColor: dsv.colors.connectGrau7,
        // shadowRadius: 2,
        // shadowOpacity: 0.1,
        //borderRadius: 4,
        padding: 10,
        width: width,
        display: 'flex',
      }}
    >
      <H2
        style={{
          textAlign: centerHeading ? 'center' : undefined,
          borderColor: dsv.colors.GpJouleGelb,
          borderStyle: 'solid',
          borderBottomWidth: 5,
        }}
      >
        {title}
      </H2>
      {children}
    </Div>
  )
}
