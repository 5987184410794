import { useMemo } from 'react'
import { trpc } from '../../App'

export function useAllPermissions() {
  const permissions_Vertrieb = trpc.akte.permissions.useQuery({ role: 'Vertrieb' })
  const permissions_Innendienst = trpc.akte.permissions.useQuery({ role: 'Innendienst' })
  const permissions_Projektierung = trpc.akte.permissions.useQuery({ role: 'Projektierung' })
  const permissions_PMO = trpc.akte.permissions.useQuery({ role: 'PMO' })
  const permissions_PE = trpc.akte.permissions.useQuery({ role: 'PE' })
  const permissions_ProjektSupport = trpc.akte.permissions.useQuery({ role: 'Projekt Support' })
  const permissions_Finanzen = trpc.akte.permissions.useQuery({ role: 'Finanzen' })
  const permissions_ServiceWartung = trpc.akte.permissions.useQuery({ role: 'Service & Wartung' })
  const permissions_Admin = trpc.akte.permissions.useQuery({ role: 'Admin' })

  const isLoading =
    permissions_Vertrieb.isLoading ||
    permissions_Innendienst.isLoading ||
    permissions_Projektierung.isLoading ||
    permissions_PMO.isLoading ||
    permissions_ProjektSupport.isLoading ||
    permissions_Finanzen.isLoading ||
    permissions_ServiceWartung.isLoading

  const permissions = useMemo(
    () =>
      isLoading
        ? undefined
        : {
          Vertrieb: permissions_Vertrieb.data!,
          Innendienst: permissions_Innendienst.data!,
          Projektierung: permissions_Projektierung.data!,
          PMO: permissions_PMO.data!,
          'Projekt Support': permissions_ProjektSupport.data!,
          Finanzen: permissions_Finanzen.data!,
          'Service & Wartung': permissions_ServiceWartung.data!,
          PE: permissions_PE.data!,
          Admin: permissions_Admin.data!,
        },
    [
      isLoading,
      permissions_Vertrieb.data,
      permissions_Innendienst.data,
      permissions_Projektierung.data,
      permissions_PMO.data,
      permissions_ProjektSupport.data,
      permissions_Finanzen.data,
      permissions_ServiceWartung.data,
      permissions_PE.data,
      permissions_Admin.data,
    ]
  )

  return isLoading
    ? {
      isLoading: true,
      permissions: undefined,
    }
    : {
      isLoading: false,
      permissions: permissions!,
    }
}
