import { PointerEvent } from 'react-native'
import { useCallback } from 'react'
import { Div } from '@expo/html-elements'
import { useTranslation } from 'react-i18next'
import { ProgressBar } from '../ProgressBar'
import { Text } from '../TextComponents'
import { dsv } from '../../styles/defaults'
import { NoDataPlaceholder } from '../../constants'
import { TFunction } from 'i18next'
import { SortableTableData, headerFieldWidths, headerFields } from './dataAndTypes'

/**
 * converts data to a string for representation in the frontend
 */
function beautifyData(
  t: TFunction<'translation', undefined>,
  data: number | string | Date | undefined | null | boolean
): string {
  switch (typeof data) {
    case 'number':
      return String(data)
    case 'string':
      return t(data)
    case 'object':
      {
        if (data instanceof Date) {
          return data.toLocaleDateString()
        }
      }
      break
  }
  return NoDataPlaceholder
}

export interface ProjectListRowProps {
  data: SortableTableData
  rowIdx: number
  navigateToProject: (akteId: number) => (_: PointerEvent) => void
}

export function ProjectListRow({ data, rowIdx, navigateToProject }: ProjectListRowProps) {
  const { t } = useTranslation()
  const dataLine = useCallback(
    (headerField: keyof SortableTableData, data: SortableTableData) => {
      switch (headerField) {
        case 'progress':
          return <ProgressBar value={data[headerField]} width={'90%'} displayPercentage />
        case 'projectStatus':
          return t(data[headerField])
        default:
          return beautifyData(t, data[headerField])
      }
    },
    [t]
  )

  return (
    <Div
      onPointerDown={navigateToProject(data.id)}
      style={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: rowIdx % 2 === 0 ? dsv.colors.connectGrau7 : dsv.colors.connectGrau7,
        cursor: 'pointer',
        alignItems: 'center',
      }}
    >
      {headerFields.map((headerField, collIdx) => {
        return (
          <Div
            key={headerField}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              minWidth: headerFieldWidths[headerField].min,
              maxWidth: headerFieldWidths[headerField].max,
              paddingVertical: 5,
              paddingHorizontal: 5,
            }}
          >
            <Text style={{ fontSize: 16 }}>{dataLine(headerField, data)}</Text>
          </Div>
        )
      })}
    </Div>
  )
}
