import { Akte } from '@dpa/common/dist'
import felder_KonfigurationsAuftrag from './felder_KonfigurationsAuftrag.json'
import { GenericOrder } from './GenericOrder'

export function ProjectsProjectConfigOrder(){
  return <GenericOrder
    orderName='Konfigurations Auftrag'
    showFields={felder_KonfigurationsAuftrag as Array<keyof Akte>}
  />
}
