import { View } from 'react-native'

import { Div } from '@expo/html-elements'
import { CardJsxHeader } from '../../../../components/Card'
import { H2, Text, H1 } from '../../../../components/TextComponents'

import { useAkteContext } from '../../../../hooks/context/useAkteContext'
import { Icon } from '../../../../components/Icon'
import { useTranslation } from 'react-i18next'

import { dsv } from '../../../../styles/defaults'
import { useAllPermissions } from '../../../../hooks/useAllPermissions'
import { Ionicons } from '@expo/vector-icons'
import { useProcessGateContext } from '../../../../hooks/context/useProcessGateContext'

import { useAkteSalesLines } from '../../../../hooks/useAkteSalesLines'
import { useSyncAkteWithBC } from '../../../../hooks/sync/useSyncAkteWithBC'
import { GpjButton } from './GpjButton'

export function ProjectCardHeader() {
  const akte = useAkteContext()

  const { t } = useTranslation()
  const { canSyncBC, syncBCFunction, syncBCMutation } = useSyncAkteWithBC(akte.serverState)
  
  const salesLines = useAkteSalesLines(akte.identifier)

  const allPermissions = useAllPermissions()

  const processGateCtx = useProcessGateContext()

  const isLoading = akte.isLoading || allPermissions.isLoading || processGateCtx.isLoading || salesLines.isLoading
  if (isLoading) {
    return <></>
  }
  return (
    <CardJsxHeader>
      <Div
        style={{
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <H1 style={{ color: dsv.colors.connectGrau, marginBottom: 5, marginTop: 0 }}>
          <View
            style={{
              width: '100%',
              backgroundColor: dsv.colors.GpJouleGelb,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingLeft: 20,
            }}
          >
            <Text
              style={{
                fontSize: 16,
              }}
            >
              {t(akte.serverState?.projectStatus)}
            </Text>
            <H2 style={{ fontSize: 28 }}>{`${t(akte.serverState?.PDB_Projektart)} - ${
              akte.serverState.UE_Kundendaten_Projekttitel
            }`}</H2>

            <View style={{ marginRight: 5 }}>
              <GpjButton
                text={syncBCMutation.isError ? "BC-Aktualisierung fehlgeschlagen" : "BC-Daten aktualisieren"}
                onPress={ syncBCFunction as () => undefined}
                isDisabled={!canSyncBC}
                isLoading={syncBCMutation.isLoading}
                enabledBackground={syncBCMutation.isError ? dsv.colors.BegleitfarbeOrange : undefined}
              />
              <GpjButton
                text={akte.isWatched ? 'Aus Favoriten entfernen' : 'Zu Favoriten hinzufügen'}
                icon={akte.isWatched ? <Ionicons name="eye-off-sharp" size={18} /> : <Icon name="eyeo" size={18} />}
                onPress={akte.toggleWatched}
                enabledBackground={dsv.colors.GpJouleGelb}
              />
            </View>
          </View>
        </H1>
      </Div>
    </CardJsxHeader>
  )
}
