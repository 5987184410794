import { Section } from '@expo/html-elements'
import { Icon } from '../Icon'
import { dsv } from '../../styles/defaults'
import { Text } from '../TextComponents'

export interface FieldDivergenceProps {
  field: JSX.Element
  divergesFromInitServerState?: boolean
  divergesFromServerState?: boolean
  divergenceHidden?: boolean
}

export function FieldDivergence({
  field,
  divergesFromInitServerState,
  divergesFromServerState,
  divergenceHidden,
}: FieldDivergenceProps) {
  return (
    <Section
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        alignItems: 'flex-end',
        columnGap: 20,
      }}
    >
      <Section style={{ flex: 1 }}>{field}</Section>
      <Section style={{ width: 40 }}>
        {!divergenceHidden && (
          <Text>
            {divergesFromServerState ? (
              <Icon color={dsv.colors.warning} name="cloudupload" size={32} />
            ) : divergesFromInitServerState ? (
              <Icon color={dsv.colors.success} name="cloudupload" size={32} />
            ) : (
              <Icon color={dsv.colors.neutral2} name="cloud" size={32} />
            )}
          </Text>
        )}
      </Section>
    </Section>
  )
}
